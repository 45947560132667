// import { BehaviorSubject } from "rxjs";
// import axios from "axios";
// import { environment } from "../../envirnoment/envirnoment.prod";
// import { map, catchError, tap, retry } from "rxjs/operators";

import createAxiosInstance from "../api.instance";
import { useDispatch } from "react-redux";
import { endApiRequest, startApiRequest } from "../../Redux/Action/loader.action";


const PlatformService = () => {
    const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage
    const dispatch = useDispatch();
    const BASIC_AUTH = 'Basic a3JpcGFiZXQ6a3JpcGFiZXRAMTIzNDU=';
    const axiosInstanceBasic = createAxiosInstance(BASIC_AUTH, "basic");
    const axiosInstance = createAxiosInstance(bearerToken);
    const createSettings = async (requestData) => {
        dispatch(startApiRequest());
        return await axiosInstance
            .post(`/platform-setting`, requestData, { headers: {} })
            .then((response) => {
                dispatch(endApiRequest());
                // console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                dispatch(endApiRequest());
                console.error("failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };
    

    // lottery/get-admin-ticket?page=1&limit=10

    const getSettingList = async ({ page,searchBy,limit }) => {
        dispatch(startApiRequest());
        return await axiosInstance
            .get(`/platform-setting?page=${page}&limit=${limit}&searchBy=${searchBy}`, { headers: {} })
            .then((response) => {
                dispatch(endApiRequest());
                // console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                dispatch(endApiRequest());
                console.error("Registration failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };
    const updateSetting = async (id, requestData) => {
        return await axiosInstance
            .put(`/platform-setting/${id}`, requestData, { headers: {} })
            .then((response) => {
                console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("Registration failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };


    const deleteSetting = async (id) => {
        return await axiosInstance
            .delete(`/platform-setting/${id}`)
            .then((response) => {
                console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("Registration failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };
    const getSettingById = async (id) => {
        return await axiosInstance
            .get(`/platform-setting/${id}`, { headers: {} })
            .then((response) => {
                // console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };

   
   
    // lottery/get-number-frequency/670918aae8e47d4f6f3af188

  
    return {
       
        createSettings,
        getSettingList,
        getSettingById,
        updateSetting,
        deleteSetting
    };
};

export default PlatformService;
