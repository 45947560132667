import React, { useEffect, useRef, useState } from 'react'
import TableWithPagination from '../../common/table'
import Header from '../../layouts/Header'
import Sidebar from '../../layouts/Sidebar'
import { Card, Badge } from 'react-bootstrap'
import UserService from '../../service/api/user.service '
import AuthService from '../../service/api/auth.service'
import { useLocation, useNavigate } from 'react-router-dom'
import useSkinSwitcher from '../../common/skin'
import Dropdown from "react-bootstrap/Dropdown";
import capitalizeFirstLetter from '../../helper/Capitalise'
import './card.css'
const ViewBankDetails = () => {

    const userService = UserService();
    const authService = AuthService();
    const navigate = useNavigate();
    const { skin, setSkin } = useSkinSwitcher();
    const [transactionList, setTransactionList] = useState([]);
    const location = useLocation();
    const { id } = location.state;
    const [pagination, setPagination] = useState({
        page: 1,
        searchBy: '',
        limit: 10
    });


  
    const [columns, setColumns] = useState(["accountHolderName","bankIfsc", "accountNumber","bankName", "branchName","createdAt"]);

    const [accountBalance, setAccountBalance] = useState(null);

    const getUserWallet = async () => {

        try {
            const res = await userService.getAdminUserBankDetails({
                page: pagination?.page,
                limit: pagination?.limit,
                searchBy: pagination?.searchBy
            }, id);
            console.log(res, "======hello from bank view========")
            if (res?.code === 200) {
                setTransactionList(res?.data?.docs);
                setPagination((prevPagination) => ({
                    ...prevPagination,
                    pages: res.data?.pages,
                    totalDocs: res.data?.total,
                    limit: res.data?.limit
                }));
                // setAccountBalance(res?.data?.balance)
                console.log(res, "======userAdmin bank details====");
            }
            else {
                console.log(res, "failed to data get")
            }
        } catch (error) {
            console.error(error);
        }
    }

    // useEffect(() => {

    //     authService.getLoginData()
    // }, [])


    useEffect(() => {
        getUserWallet();

    }, [pagination.searchBy, pagination.page]);



    // Define HTML for status badges
    const getStatusBadge = (status) => {
        if (status === 1) {
            return <Badge bg={'success'} className="badge badge-success">Active</Badge>;
        } else if (status === 0) {
            return <Badge bg={'pink'} className="badge badge-danger">Inactive</Badge>;
        }
    };

    // Define HTML for action column
    const getActionDropdown = (id) => {
        console.log("id", id);
        return (
            <Dropdown>
                <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
                    <i className="ri-more-2-fill"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleView(id)}>View</Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>
        );
    };


    const handleView = (id) => {
        const state = { id: id };
        navigate(`/user-management/view`, { state });

    };

    const formatDateTime = (createdAt) => {
        const date = new Date(createdAt);

        const day = String(date.getDate()).padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        const dateString = `${day}-${month}-${year}`;

        const timeString = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });

        // return { date: dateString, time: timeString };
        return `${dateString}/${timeString}`
    };
    // const handleStatusUpdate = async (id) => {
    //   // const state = {id: id}
    //   // navigate(`/role-management/edit`, {state})
    //   const role = staffList.filter((item) => item.id === id);
    //   const res = await userService.updateUser(role[0].id, {...role[0], 
    //     status: role[0].status == 1 ? 0 : 1,
    //   });
    //   if (res?.code == 200) {
    //     toast.success(res?.message);
    //     getList();
    //   } else {
    //     toast.error(res?.message);
    //   }
    // };

    // accountHolderName
    // : 
    // "jdf"
    // accountNumber
    // : 
    // "123456798"
    // bankIfsc accountNumber
    // : 
    // "SBIN0123456"
    // bankName bankIfsc accountNumber
    // : 
    // "RBI"
    // branchName bankName bankIfsc accountNumber
    // : 
    // "Akshardham"
    // createdAt
    // : 
    // "2024-05-31T10:48:55.632Z"
    // id
    // : 
    // "6659ab17dc12262a2c055b2f"
    // isDefault
    // : 
    // false
    // isDelete
    // : 
    // 1
    // status
    // : 
    // 1
    // userId
    // : 
    // "664463e714f8062872777507"

    // Map the columns to include HTML for status badges
    const columnsWithHTML = columns.map((column) => {
        if (column.toLowerCase() === "accountnumber") {
            return {
                header: "Account Number",
                accessor: column,
                Cell: ({ value }) => {return value || 'N/A';} // Render status badge based on the value
            };
        } else if (column.toLowerCase() === "createdat") {
            return {
                header: "Added Date",
                accessor: column,
                Cell: ({ value }) => {
                    console.log("datavalue", value, column);
                    return formatDateTime(value) || "N/A";
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "accountholdername") {
            return {
                header: "Account Holder Name",
                accessor: column,
                Cell: ({ value }) => {
                    // console.log("currenyvalue", value, column);
                    return value || "N/A";
                }, // Render action dropdown based on the value
            };
        }
        else if (column.toLowerCase() === "bankifsc") {
            return {
                header: "Bank IFSC",
                accessor: column,
                Cell: ({ value }) => {

                    return value || "N/A";
                }, // Render action dropdown based on the value
            };
        }
        else if (column.toLowerCase() === "bankname") {
            return {
                header: "Bank Name",
                accessor: column,
                Cell: ({ value }) => {

                    return value || "N/A";
                }, // Render action dropdown based on the value
            };
        }
        else if (column.toLowerCase() === "branchname") {
            return {
                header: "Branch Name",
                accessor: column,
                Cell: ({ value }) => {

                    return value || "N/A";
                }, // Render action dropdown based on the value
            };
        }
        else {
            return column;
        }
    });


    const useDebounce = (func, delay) => {
        const debounceRef = useRef(null);

        return (...args) => {
            if (debounceRef.current) {
                clearTimeout(debounceRef.current);
            }
            debounceRef.current = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const handleSearchChange = (event) => {
        const value = event.target?.value?.trim();
        // if (value.length > 3 || value.length === 0) {
        // }
        setPagination((prev) => ({ ...prev, searchBy: value }));
    };

    const debouncedHandleSearchChange = useDebounce(handleSearchChange, 300);

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <Card>

                    <div className=" align-items-center">
                        <Card.Header>

                            <h1>User Bank Details</h1>
                            {/* <div className="d-md-flex py-3 align-items-center justify-content-between">
                                <div className="form-search me-auto">
                                    <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Search"
                                        onChange={debouncedHandleSearchChange}
                                    />
                                    <i className="ri-search-line"></i>
                                </div> */}
                                {/* <!-- Balance Card -->
                                <div className="balance-card  card text-center ms-md-3 mt-3 mt-md-0">
                                    <div className="card-body">
                                        <h5 className="card-title">Balance</h5>
                                        <p className="card-text" id="balance-amount">$0.00</p>
                                    </div>
                                </div> */}

                            {/* </div> */}
                        </Card.Header>
                        <TableWithPagination
                            tableType={'transaction'}
                            data={[...transactionList]}
                            itemsPerPage={pagination?.limit}
                            columns={columnsWithHTML}
                            currentPage={pagination?.page}
                            totalPages1={pagination?.totalPages}
                            totalData={pagination?.totalDocs}
                            pagination={pagination}
                            moduleId={3}
                            handleSearchChange={debouncedHandleSearchChange}
                            setPagination={setPagination}
                        />
                    </div>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default ViewBankDetails