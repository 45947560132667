import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { darkModeStyles, darkModeStyles2, lightModeStyles, lightModeStyles2 } from "../../common/reactSelectStyles";
import { useSelector } from "react-redux";
import GameSettingService from "../../service/api/game-setting.service";

const ViewGameSetting = () => {
    const location = useLocation();
    const theme = useSelector((state) => state.ui.skin);
    const { skin, setSkin } = useSkinSwitcher();
    const gameService = GameSettingService();
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [errors, setErrors] = useState({});
    const [gameListOptions, setGameListOptions] = useState(null);
    const { id } = location.state;
    const [formData, setFormData] = useState({
    });

    useEffect(() => {
        getGameSettingDetails();
    }, []);



    const getGameSettingDetails = async () => {

        try {
            const res = await gameService.getGameSettingById(id);
            if (res?.code === 200) {
                console.log(res, "hello view game")
                setFormData(res?.data);
                setGameListOptions({ label: res?.data.gameId.name, value: res?.data?.gameId?.id });
            }
        } catch (error) {
            console.error(error);
        }
    };

   
    

    
    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <div className=" align-items-center mb-4">
                    <Card>
                        <Card.Header>
                            <h4 className="card-title w-100">View Game Setting</h4>
                        </Card.Header>
                        <Card.Body>
                            <Form className="row">
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="merchant">
                                        <Form.Label>Game</Form.Label>
                                        <ReactSelect
                                            styles={theme === 'dark' ? darkModeStyles2 : lightModeStyles2}
                                            name="gameId"
                                            value={gameListOptions}
                                            // isMulti
                                            isDisabled={true}
                                           
                                        />
                                       
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="houseEdge">
                                        <Form.Label>House Edge</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter House Edge"
                                            name="houseEdge"
                                            disabled
                                            value={formData.houseEdge}
                                           
                                        />
                                        
                                    </Form.Group>
                                </Col>
                                <div className="d-flex justify-content-center mt-5">
                                    <Button variant="primary" type="button" className="mx-4" onClick={() => navigate("/game-setting")}>
                                        Back
                                    </Button>
                                    {/* <Button variant="danger" type="button" onClick={() => navigate("/game-setting")}>
                                        Cancel
                                    </Button> */}
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ViewGameSetting;
