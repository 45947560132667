import React, { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../service/api/auth.service";
import { toast } from "react-toastify";

export default function ResetPassword() {
  const authService = AuthService();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({ password: '', confirmPassword: '' });

  const validatePassword = (value) => {
    if (!value) return 'Password is required.';
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/; // At least 8 characters, one letter, and one number
    // Other password validation rules can be added here
    if (!passwordRegex.test(value)) {
      console.log("formData.password",value)
     return "Password must be at least 8 characters long and contain at least one letter and one number including special character.";
    }
    return '';
  };

  const validateConfirmPassword = (value) => {
    if (!value) return 'Confirm password is required.';
    if (value !== password) return 'Passwords do not match.';
    return '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') {
      setPassword(value);
      setErrors({ ...errors, password: validatePassword(value) });
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
      setErrors({ ...errors, confirmPassword: validateConfirmPassword(value) });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate password and confirm password fields
    const passwordError = validatePassword(password);
    const confirmPasswordError = validateConfirmPassword(confirmPassword);

    // If there are validation errors, set the errors and return
    if (passwordError || confirmPasswordError) {
      setErrors({ password: passwordError, confirmPassword: confirmPasswordError });
      return;
    }
    const response = await authService.resetPassword( {password: password, token: token});
    console.log("Form submitted:", response);

    if (response.code == 200) {
      toast.success(response.message);
      
      navigate("/signin");
    } else {
      console.log("response", response);
      toast.error(response.message);
    }
    // If all validations pass, continue with form submission
    console.log('Form submitted:', { password, confirmPassword });
    // Perform your form submission logic here
  };

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <Link to="/" className="header-logo mb-4">KripaBets</Link>
          <Card.Title>Reset Password</Card.Title>
          {/* <Card.Text>Welcome back! Please signin to continue.</Card.Text> */}
        </Card.Header>
        <Card.Body>
          <Form  onSubmit={handleSubmit}>
            {/* <div className="mb-4">
              <Form.Label >Email address</Form.Label>
              <Form.Control type="text" placeholder="Enter your email address" value="me@themepixels.com" />
            </div> */}
           <div className="mb-4">
              <Form.Label>Password*</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Enter your password"
                value={password}
                onChange={handleChange}
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </div>
            <div className="mb-4">
              <Form.Label>Confirm Password*</Form.Label>
              <Form.Control
                type="password"
                name="confirmPassword"
                placeholder="Enter confirm password"
                value={confirmPassword}
                onChange={handleChange}
                isInvalid={!!errors.confirmPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword}
              </Form.Control.Feedback>
            </div>
            <Button type="submit" variant="primary" className="btn-sign text-capitalize">Reset Password</Button>

            {/* <div className="divider"><span>or sign in with</span></div> */}

            {/* <Row className="gx-2">
              <Col><Button variant="" className="btn-facebook"><i className="ri-facebook-fill"></i> Facebook</Button></Col>
              <Col><Button variant="" className="btn-google"><i className="ri-google-fill"></i> Google</Button></Col>
            </Row> */}
          </Form>
        </Card.Body>
        {/* <Card.Footer>
          Don't have an account? <Link to="/pages/signup">Create an Account</Link>
        </Card.Footer> */}
      </Card>
    </div>
  )
}