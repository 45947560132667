import numeral from "numeral";
import React from "react";
import { formatCurrency } from "../../helper/CurrencyFormate";
import { useNavigate } from "react-router-dom";

const FinancialDetails = ({ userData }) => {
    const navigate = useNavigate();

    const groupedData = userData?.userDetail?.balances.reduce((acc, item) => {
        const { currencyCodes, name, currencySymbols } = item.currencyId;

        if (!acc[currencyCodes]) {
            acc[currencyCodes] = {
                currency: {
                    name,
                    code: currencyCodes,
                    symbol: currencySymbols,
                },
                balances: {
                    deposit: 0,
                    bonus: 0,
                    winnings: 0
                }
            };
        }

        // Map the `type` field to the corresponding balance type
        if (item.type === 0) {
            acc[currencyCodes].balances.deposit = item.balance;
        } else if (item.type === 2) {
            acc[currencyCodes].balances.bonus = item.balance;
        } else if (item.type === 1) {
            acc[currencyCodes].balances.winnings = item.balance;
        }

        return acc;
    }, {});

    return (
        <>
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="sub-service-title">
                        <h3><span>Financial Details</span></h3>
                    </div>
                </div>
            </div>

            {/* General Wallet Balances */}
            <div>
                <table className="wallet-table">
                    <thead>
                        <tr>
                            <th>Currency</th>
                            <th>Total Deposit</th>
                            <th>Total Withdrawn</th>
                            <th>Total Win</th>
                            <th>Total Loss</th>
                            <th>Total Bonus</th>
                            <th>Total Bonus Used</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(userData.totalsByCurrency || {}).length > 0 ? (
                            Object.values(userData.totalsByCurrency).map((item, ind) => (
                                <tr key={ind}>
                                    <td>{item.currencyName} ({item.currencySymbols})</td>
                                    <td>
                                        <a
                                            className="cursor-pointer"
                                            onClick={() => {
                                                const state = { id: userData?.userDetail?.id, transactionType: 'credit' };
                                                navigate(`/pay-in/transaction`, { state });
                                            }}
                                        >
                                            {formatCurrency(item.totalDeposit, item.currencyCodes)}
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            className="cursor-pointer"
                                            onClick={() => {
                                                const state = { id: userData?.userDetail?.id, transactionType: 'debit' };
                                                navigate(`/pay-out/transaction`, { state });
                                            }}
                                        >
                                            {formatCurrency(item.totalWithdrawn, item.currencyCodes)}
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            className="cursor-pointer"
                                            onClick={() => {
                                                const state = { id: userData?.userDetail?.id, status: '1' };
                                                navigate(`/bet-history`, { state });
                                            }}
                                        >
                                            {formatCurrency(item.totalWin, item.currencyCodes)}
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            className="cursor-pointer"
                                            onClick={() => {
                                                const state = { id: userData?.userDetail?.id, status: '0' };
                                                navigate(`/bet-history`, { state });
                                            }}
                                        >
                                            {formatCurrency(Math.max((item.totalLose-item.totalWin),0), item.currencyCodes)}
                                        </a>
                                    </td>
                                    <td>{formatCurrency(item.totalBonus, item.currencyCodes)}</td>
                                    <td>{formatCurrency(item.totalBonusUsed, item.currencyCodes)}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7" className="text-center">No Records Found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {/* Currency-Specific Balances */}
            <div className="row mt-4">
                <span className="sub-heading-title">Currency-Specific Balances</span>
            </div>
            <div>
                <table className="wallet-table">
                    <thead>
                        <tr>
                            <th>Currency</th>
                            <th>Deposit</th>
                            <th>Winnings</th>
                            <th>Bonus</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(groupedData || {}).length > 0 ? (
                            Object.values(groupedData).map((item, ind) => (
                                <tr key={ind}>
                                    <td>{item.currency.name} ({item.currency.symbol})</td>
                                    <td>{formatCurrency(item.balances.deposit, item.currency.code)}</td>
                                    <td>{formatCurrency(item.balances.winnings, item.currency.code)}</td>
                                    <td>{formatCurrency(item.balances.bonus, item.currency.code)}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" className="text-center">No Records Found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default FinancialDetails;
