import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import RoleService from "../../service/api/role.service";
import gameService from "../../service/api/merchant.service";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import GameService from "../../service/api/game.service";

const ViewGameDetails = () => {
    const { skin, setSkin } = useSkinSwitcher();
    const gameService = GameService();
    const location = useLocation();
    const { id } = location.state;
    const [imagePreview, setImagePreview] = useState(null);
    const [bannerImage,setBannerImage] = useState(null);

    const navigate = useNavigate();

    const [formData, setFormData] = useState({});

    useEffect(() => {
        getGameDetails();
    }, []);



    const getGameDetails = async () => {

        try {
            const res = await gameService.getGameById(id);
            if (res?.code === 200) {
                console.log(res, "hello view game")
                setFormData(res?.data);
                setImagePreview(`${process.env.REACT_APP_IMAGE_URL}/${res?.data?.bannerImage}`);
                setBannerImage(res?.data?.bannerImage)
            }
        } catch (error) {
            console.error(error);
        }
    };




    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <div className=" align-items-center mb-4">
                    <Card>
                        <Card.Header>
                            <h4 className="card-title w-100">View Game</h4>
                        </Card.Header>
                        <Card.Body>
                            <div className="d-flex justify-content-center">
                                <Col md={6}>
                                    <img src={bannerImage?imagePreview:'../images/card-image-placeholder.png'} style={{ maxWidth: '100%' }} />
                                </Col>
                            </div>
                            <Form className="row" >

                                <Col md={4} className="mt-3">
                                    <Form.Group >
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter name"
                                            name="name"
                                            value={formData.name || ""}


                                            disabled={true}
                                        />

                                    </Form.Group>
                                </Col>

                                <Col md={4} className="mt-3">
                                    <Form.Group >
                                        <Form.Label>Game Id</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter phone"
                                            name="phone"
                                            value={formData.gameUnique || ""}

                                            disabled={true}
                                        />

                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group >
                                        <Form.Label>Under Maintenance</Form.Label>
                                        <Form.Control
                                            type="email"
                                            // placeholder="Enter email"
                                            name="email"
                                            value={formData.value && formData.value ? 'Yes' : 'No'}

                                            disabled={true}
                                        />

                                    </Form.Group>
                                </Col>
                                {/* <Col md={4} className="mt-3">
                                    <Form.Group >
                                        <Form.Label>Merchant</Form.Label>
                                        <Form.Control
                                            
                                            as="select"
                                            name="merchantName"
                                            value={formData.merchant ? formData.merchant.name : ""}
                                            disabled={true}
                                        >
                                            <option>{formData.merchant ? formData.merchant.name : ""}</option>
                                        </Form.Control>

                                    </Form.Group>
                                </Col> */}
                                <Col md={4} className="mt-3">
                                    <Form.Group >
                                        <Form.Label>Category</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="merchantName"
                                            value={formData.category}
                                            disabled={true}
                                        >
                                            <option>{formData.category ? formData?.category?.name : ""}</option>
                                        </Form.Control>

                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="gameDescription">
                                        <Form.Label>Game Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="gameDescription"
                                            placeholder="Enter game description"
                                            value={formData.gameDescription}
                                            rows={5} // Adjust the number of rows to control the height of the textarea
                                            disabled={true}
                                        />
                                        {/* <Form.Control.Feedback type="invalid">
                    {errors.gameDescription !== "" && showError && "Game description is required"}
                  </Form.Control.Feedback> */}
                                    </Form.Group>
                                </Col>

                                <div className="d-flex justify-content-center mt-5">

                                    <Button variant="primary" type="button" onClick={() => navigate("/game-management")}>
                                        Back
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ViewGameDetails;
