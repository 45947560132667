import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const NumbersChart = ({ data, skin }) => {

  console.log(data, "dataaaaaaaaaaaaa")
  const [numberData, setNumberData] = useState({ categories: [], counts: [] });
  const [jackpotData, setJackpotData] = useState({ categories: [], counts: [] });
  const axisColor = skin == "dark" ? 'rgba(255, 255, 255, 0.6)' : 'rgb(224, 224, 224)';
  const labelColor = skin == "dark" ? 'rgba(255, 255, 255, 0.6)' : '#41505f';
  useEffect(() => {
    // Filter and sort data for numbers and jackpot numbers
    const numbers = data.filter(item => item.type === 'numbers');
    const jackpotNumbers = data.filter(item => item.type === 'jackpotNumber');

    // Sort by number for display purposes
    numbers.sort((a, b) => a.number - b.number);
    jackpotNumbers.sort((a, b) => a.number - b.number);

    // Prepare data for ApexCharts
    setNumberData({
      categories: numbers.map(item => item.number),
      counts: numbers.map(item => item.count),
    });

    setJackpotData({
      categories: jackpotNumbers.map(item => item.number),
      counts: jackpotNumbers.map(item => item.count),
    });
  }, [data]);

  const numberOptions = {
    chart: {
      type: 'bar',
      height: 350,
      width: '100%',
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: true,
      },

    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: { height: 300 },
          xaxis: { labels: { rotate: -45 } }, // For readability on smaller screens
        },
      },
    ],
    plotOptions: {
      bar: {
        columnWidth: '50%', // Shrinks bar width to avoid overlap
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: 'Numbers Count',
      style: {
        color: labelColor,
      }
    },
    xaxis: {
      categories: numberData.categories,
      title: {
        text: 'Numbers',
        style: {
          color: labelColor,
        }
      },
      axisBorder: {
        show: true,
        color: axisColor,  // Conditional color for dark/light theme
      },
      axisTicks: {
        show: true,
        color: axisColor,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Count',
        style: {
          color: labelColor,
        },

      },
      axisBorder: {
        show: true,
        color: axisColor,  // Conditional color for dark/light theme
      },
      axisTicks: {
        show: true,
        color: axisColor,  // Conditional color for dark/light theme
      },
      labels: {
        // formatter: (val) => val.toFixed(0),
        style: {
          colors: labelColor,
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
        },
      },
    },
    grid: {
      borderColor: axisColor,
      xaxis: {
        lines: { show: false },
      },
      yaxis: {
        lines: { show: false },
      },
      padding: {
        left: 13,
        right: 10,
        top: 0,
        bottom: 0
      }
    },
    tooltip: {
      theme: skin === 'dark' ? 'dark' : 'light',
      y: {
        formatter: (val) => val.toFixed(0),
      },
    },
  };

 
 
  const jackpotOptions = {
    chart: {
      type: 'bar',
      height: 350,
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: 'Jackpot Numbers Count',
      style: {
        color: labelColor,
      }
    },
    xaxis: {
      categories: jackpotData.categories,
      title: {
        text: 'Numbers',
        style: {
          color: labelColor,
        }
      },
      axisBorder: {
        show: true,
        color: axisColor,  // Conditional color for dark/light theme
      },
      axisTicks: {
        show: true,
        color: axisColor,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Count',
        style: {
          color: labelColor,
        },

      },
      axisBorder: {
        show: true,
        color: axisColor,  // Conditional color for dark/light theme
      },
      axisTicks: {
        show: true,
        color: axisColor,  // Conditional color for dark/light theme
      },
      labels: {
        // formatter: (val) => val.toFixed(0),
        style: {
          colors: labelColor,
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
        },
      },
    },
    grid: {
      borderColor: axisColor,
      xaxis: {
        lines: { show: false },
      },
      yaxis: {
        lines: { show: false },
      },
      padding: {
        left: 13,
        right: 10,
        top: 0,
        bottom: 0
      }
    },
    tooltip: {
      theme: skin === 'dark' ? 'dark' : 'light',
      y: {
        formatter: (val) => val.toFixed(0),
      },
    },
  };


  return (
    <div>
      {/* <h2>Frequently Chosen Numbers Overview</h2> */}
      <div className="chart-container">
        <Chart
          options={numberOptions}
          series={[{ name: 'Count', data: numberData.counts }]}
          type="bar"
          height={350}
        />
      </div>
      <div className="chart-container">
        <Chart
          options={jackpotOptions}
          series={[{ name: 'Count', data: jackpotData.counts }]}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};

export default NumbersChart;
