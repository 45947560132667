import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
// import UserService from "../../service/api/user.service";
import { useNavigate, useLocation } from "react-router-dom";
import PersonalDetails from "./personalDetails";
import FinancialDetails from "./financialDetails";
import GameDetails from "./gameDetails";
import UserService from "../../service/api/user.service ";
import './view.css'
import { BorderSpinner } from "../../common/Spinner";
const ViewNewUser = () => {
  const { skin, setSkin } = useSkinSwitcher();
  const userService = UserService();
  const location = useLocation();
  const { id } = location.state; // Assuming ID is passed via location state
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});

 
  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      const res = await userService.getUserById(id);
      if (res?.code === 200) {
        setUserData(res.data);
      }
      else {
        throw new Error(res.message)
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <div className=" align-items-center mb-4">
          <Card>
            <Card.Header>
              <div className="d-flex justify-content-between  text-end">
              <h4 className="card-title ">View User</h4>

                <Button
                  variant="primary"
                  type="button"
                  className="ms-2"
                  onClick={() => navigate("/user-management")}
                >
                  Back
                </Button>
                
              </div>
            </Card.Header>
            <Card.Body>

             
              {isLoading?<div className="d-flex mt-5 justify-content-center align-content-center">
                 <BorderSpinner />
            </div>:(<div className="row viewUser">

                {/* <div className="col-sm-12 col-md-3 col-lg-2">
                      <div className="img-box">
                        <img width={150} src={userData?.personalDetails?.displayPicture || '../images/userProfile1-removebg-preview.png'} alt="" />
                      </div>
                    </div> */}
                <div className="col-sm-12 col-md-9 col-lg-12">

                  <PersonalDetails userData={userData} />
                  <FinancialDetails userData={userData} />
                  <GameDetails userData={userData} />

                </div>
              </div>)}
              
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewNewUser;
