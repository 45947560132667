import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import RoleService from "../../service/api/role.service";
import GameSettingService from "../../service/api/game-setting.service";
import BannerService from "../../service/api/banner.service";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import MerchantService from "../../service/api/merchant.service";
import ReactSelect from "react-select";
import { darkModeStyles2, lightModeStyles2 } from "../../common/reactSelectStyles";
import { useSelector } from "react-redux";
const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;
const EditNewGame = () => {
  console.log("imageBaseUrl=======================666666666666666666666666", imageBaseUrl);
  const theme = useSelector((state) => state.ui.skin);
  const { skin, setSkin } = useSkinSwitcher();
  const gameSettingService = GameSettingService();
  const merchantService = MerchantService();
  const bannerService = BannerService();
  const location = useLocation();
  const { id } = location.state;

  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [roleOption, setRoleOption] = useState([]);
  const [errors, setErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState({
    // role: "",
    merchant: [],
    name: "",
    // phone: "",
    // email: "",
    // password: "",
  });
  const [merchantList, setGameList] = useState([]);
  const [merchantOption, setMerchantOption] = useState(null);

  const getMerchantList = async () => {
    const res = await gameSettingService.getGameListDropdown();
    if (res?.code === 200) {
      setGameList(res.data);
      let data = res.data.map((item) => ({ value: item.id, label: item.name }));
      setMerchantOption(data);
    }
    console.log(merchantList, " and ", merchantOption)
  }
  useEffect(() => {
    // getRoleList();
    getMerchantList();
  }, []);

  useEffect(() => {
    getStaffDetail();
  }, [merchantOption]);


  const [selectedResult, setSelectedResult] = useState([])
  const [underMaintainanceOptions, setunderMaintainanceOptions] = useState([{value: true, label: "True"}, {value: false, label: "False"}])
  const [underMaintainance, setunderMaintainance] = useState([{value: false, label: "False"}])
  const getStaffDetail = async () => {
    const res = await bannerService.getBannerById(id);
    if (res?.code === 200) {
      const result = merchantOption?.filter(option => res?.data?.gameId == option.value)
      setFormData({...res?.data, merchant: result});
      console.log(result,"Hello======================",res.data)
      setSelectedResult(result);
      
      setImagePreview(`${imageBaseUrl}/${res?.data?.bannerImages[0]}`);
      // const maintainanceObj = {
      //   true:{ value: true, label: "True" },
      //   false:{value: false, label: "False"}
      // }
      const value = underMaintainanceOptions.filter((item) => item.value === res?.data?.underMaintenance);
      console.log(value,"hellllllllllllllllllllllll",res?.data?.underMaintenance)
      setunderMaintainance(value)
      // let data = res.data.map((item) => ({ value: item.id, label: item.name }));
      // setRoleOption(data);
    }
  };


  console.log("formData", formData)

  const handleSelectChange = (item) => {
    console.log(item, 'form item');
    const valuesArray = item ? item : [];
    // setSelectedValues(valuesArray);
    setFormData((preValue) => ({
      ...preValue,
      gameId: valuesArray
    }));
    setShowError(false);
    setSelectedResult(item);
    // Now you can send selectedValues to the API or use it elsewhere
    console.log(valuesArray, 'array of string values');
  };
  const handleMaintainanceSelectChange = (items) => {
    console.log(items, 'form item');
    // const valuesArray = items ? items?.map(item => item.value) : [];
    // setSelectedValues(valuesArray);
    setFormData((preValue) => ({
      ...preValue,
      underMaintenance: items.value
    }));
    setShowError(false);
    setunderMaintainance(items);
    
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("{ ...errors, [name]: errorMessage }", name, value);

    let errorMessage = "";

    // Perform validation based on the field name
    switch (name) {
      case "name":
        if (!value.trim()) {
          errorMessage = "Name is required.";
        } else if (!/^[a-zA-Z0-9\s]*$/.test(value)) {
          errorMessage = "Name is required";
        } else {
          errorMessage = "";
        }
        break;
      case "merchant":
        if (!value.length) {
          errorMessage = "Merchant is required.";
        } else {
          errorMessage = "";
        }
        break;
      // Add validation for other fields as needed
      default:
        break;
    }

    setFormData({ ...formData, [name]: value });
    // showError(false)
    setErrors({ ...errors, [name]: errorMessage });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add your form submission logic here
    // Perform validations
    console.log("formData", formData);
    const validationErrors = {};

    // if (formData.name.trim() == "") {
    //   validationErrors.name = "Name is required";
    // } else if (!/^[a-zA-Z0-9\s]*$/.test(formData.name)) {
    //   validationErrors.name = "Name should be alphanumeric";
    // } else {
    //   validationErrors.name = "";
    // }
    if (formData.gameId?.length === 0) {
      validationErrors.gameId = "Game is required";
    } else {
      validationErrors.gameId = "";
    }
    // Add more password validation logic as needed
    console.log("formData==validationErrors", formData);
    console.log("validationErrors", Object.values(validationErrors));

    if (Object.values(validationErrors).every((item) => item === "")) {
      // No errors, proceed with form submission
      console.log("resss===");
      setShowError(false);
      console.log("formData", formData);
       // Create a new FormData object
       const formDataToSend = new FormData();
        
       // Append form fields to FormData
       formDataToSend.append("name", formData.name);
       formDataToSend.append("gameId", formData?.gameId.value || selectedResult[0]?.value);
      //  formDataToSend.append("underMaintenance", JSON.stringify(formData.underMaintenance));
      //  formDataToSend.append("gameDescription", formData.gameDescription);
      //  if (formData.merchant.length !== 0) {
      //   formDataToSend.append("merchant", JSON.stringify(formData.merchant));
      //  }
       // Append file if it exists
       if (formData.image) {
           formDataToSend.append("bannerImages", formData.image);
       }
      const res = await bannerService.updateBannerStatus(id, formDataToSend);
      console.log("resss===", res);
      if (res?.code == 200) {
        toast.success(res?.message);
        navigate("/banner");
      } else {
        toast.error(res?.message);
      }
    } else {
      // Set validation errors
      setShowError(true);
      setErrors(validationErrors);
    }
  };

  const handleRoleChange = (selectedOption) => {
    let validationErrors = "";
    if (selectedOption.value == "") {
      validationErrors = "Role is required";
    } else {
      validationErrors = "";
    }
    setShowError(true);
    setErrors({ ...errors, role: validationErrors });
    setFormData({ ...formData, role: selectedOption.value });
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#192030", // Background color
      color: "#A8A2A2", // Text color
      borderColor: state.isFocused ? "#1F283D" : "#1F283D", // Border color
      boxShadow: state.isFocused ? "0 0 0 1px #fff" : "#1E273A", // Box shadow
      "&:hover": {
        borderColor: "#1E273A", // Border color on hover
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#192030"
        : state.isFocused
        ? "#1F283D"
        : "#141926", // Option background color
      color: "#A8A2A2", // Option text color
      "&:hover": {
        backgroundColor: "#1E273A", // Option background color on hover
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#333", // Menu background color
      color: "#A8A2A2", // Menu text color
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#A8A2A2", // Single value text color
    }),
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      formData.image = file;
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Set the preview image URL
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <div className=" align-items-center mb-4">
          <Card>
            <Card.Header>
              <h4 className="card-title w-100">Edit Game</h4>
            </Card.Header>
            <Card.Body>
              <div className="d-flex justify-content-center"> 

              <Col md={6}>
              <img src={imagePreview || '../images/banner-placeholder.png'} style={{ maxWidth: '100%' }}  />
              </Col>
              </div>
              <Form className="row" onSubmit={handleSubmit}>
              <Col md={4} className="mt-3">
                <Form.Group controlId="images">
                    <Form.Label>Banner Images</Form.Label>
                    <Form.Control
                        type="file"
                        placeholder="Upload images"
                        name="images"
                        onChange={handleImageChange}
                        // multiple // Allow multiple file selection
                        accept="image/jpeg,image/png,image/gif" // Restrict file types
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.images && showError && "Please upload at least one image and a maximum of 5 images."}
                    </Form.Control.Feedback>
                </Form.Group>
              </Col>

                <Col md={4} className="mt-3">
                  <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      value={formData.name}
                      disabled={true}
                      isInvalid={showError && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-3">
                  <Form.Group controlId="name">
                    <Form.Label>Link to</Form.Label>
                    {/* <Form.Control
                      type="text"
                      placeholder="Enter merchant"
                      name="merchant"
                      value={formData.merchant}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.merchant}
                    /> */}
                    {/* <Form.Control.Feedback type="invalid">
                      {errors.merchant}
                    </Form.Control.Feedback> */}
                    <ReactSelect
                      styles={
                        theme === "dark" ? darkModeStyles2 : lightModeStyles2
                      }
                      value={selectedResult}
                      name="merchant"
                      options={merchantOption}
                      onChange={handleSelectChange}
                    />
                    <Form.Text className="text-danger small">
                      {errors.gameId !== "" &&
                        showError &&
                        "Game is required"}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <div className="d-flex justify-content-center mt-5">
                  <Button variant="primary" type="submit" className="mx-4">
                    Submit
                  </Button>
                  <Button
                    variant="danger"
                    type="button"
                    onClick={() => navigate("/banner")}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditNewGame;
