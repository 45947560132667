import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import GameSettingService from "../../service/api/game-setting.service";
import CurrencyService from "../../service/api/currency.service";

const AddCurrency = () => {
    const currencyService = CurrencyService();
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        name: '',
        currencyCodes: '',
        currencySymbols: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const validateForm = () => {
        const validationErrors = {};

        // Validate name (minimum 3 characters, only letters and spaces)
        if (!formData.name.trim()) {
            validationErrors.name = "Currency name is required";
        } else if (!/^[A-Za-z\s]{3,}$/.test(formData.name)) {
            validationErrors.name = "Name must be at least 3 characters long and contain only letters and spaces";
        }

        // Validate currency code (exactly 3 uppercase letters)
        if (!formData.currencyCodes.trim()) {
            validationErrors.currencyCodes = "Currency code is required";
        } else if (!/^[A-Z]{3}$/.test(formData.currencyCodes)) {
            validationErrors.currencyCodes = "Currency code must be exactly 3 uppercase letters (e.g., USD)";
        }

        // Validate currency symbol (single character)
        if (!formData.currencySymbols.trim()) {
            validationErrors.currencySymbols = "Currency symbol is required";
        } else if (formData.currencySymbols.length !== 1) {
            validationErrors.currencySymbols = "Currency symbol must be a single character (e.g., $, €, ¥)";
        }

        return validationErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length === 0) {
            setShowError(false);
            try {
                const res = await currencyService.createCurrency(formData);
                if (res?.code === 200) {
                    toast.success(res.message);
                    navigate('/currency');
                } else {
                    toast.error(res.message);
                }
            } catch (error) {
                console.error(error);
                // toast.error("Error while adding currency.");
            }
        } else {
            setShowError(true);
            setErrors(validationErrors);
        }
    };

    return (
        <React.Fragment>
            <Header />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <div className="mb-4">
                    <Card>
                        <Card.Header>
                            <h4 className="card-title w-100">Add New Currency</h4>
                        </Card.Header>
                        <Card.Body>
                            <Form className="row" onSubmit={handleSubmit}>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter currency name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            isInvalid={showError && !!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="currencyCodes">
                                        <Form.Label>Currency Code</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter currency code"
                                            name="currencyCodes"
                                            value={formData.currencyCodes}
                                            onChange={handleChange}
                                            isInvalid={showError && !!errors.currencyCodes}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.currencyCodes}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="currencySymbols">
                                        <Form.Label>Currency Symbol</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter currency symbol"
                                            name="currencySymbols"
                                            value={formData.currencySymbols}
                                            onChange={handleChange}
                                            isInvalid={showError && !!errors.currencySymbols}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.currencySymbols}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <div className="d-flex justify-content-center mt-5">
                                    <Button variant="primary" type="submit" className="mx-4">
                                        Submit
                                    </Button>
                                    <Button variant="danger" type="button" onClick={() => navigate("/currency")}>
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddCurrency;
