import React, { useState, useEffect, useRef, useMemo } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import StaticPagesService from "../../service/api/static.pages.service";
import { decode } from 'html-entities';
import { BorderSpinner } from "../../common/Spinner";
// Custom Toolbar component
const CustomToolbar = () => (
    <div id="toolbar">
        <span className="ql-formats">
            <select className="ql-header" defaultValue="3" onChange={(e) => e.persist()}>
                <option value="1">Heading 1</option>
                <option value="2">Heading 2</option>
                <option value="3">Normal</option>
            </select>
        </span>
        <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-strike" />
        </span>
        <span className="ql-formats">
            <button className="ql-list" value="ordered" />
            <button className="ql-list" value="bullet" />
            <button className="ql-indent" value="-1" />
            <button className="ql-indent" value="+1" />
        </span>
        <span className="ql-formats">
            <button className="ql-link" />
            <button className="ql-image" />
            <button className="ql-video" />
        </span>
        <span className="ql-formats">
            <button className="ql-align" value="" />
            <button className="ql-align" value="center" />
            <button className="ql-align" value="right" />
            <button className="ql-align" value="justify" />
        </span>
        <span className="ql-formats">
            <button className="ql-code-block" />
        </span>
        <span className="ql-formats">
            <button id="undo" className="ql-undo">
                <i className="fa fa-undo" />
            </button>
            <button id="redo" className="ql-redo">
                <i className="fa fa-redo" />
            </button>
        </span>
    </div>
);

// Custom undo/redo handler
const handleUndoRedo = (quillRef, action) => {
    console.log(action)
    if (quillRef.current) {
        const quill = quillRef.current.getEditor();
        if (action === "undo") {
            quill.history.undo();
        } else if (action === "redo") {
            quill.history.redo();
        }
    }
};



const uploadImage = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
};
const CommonPageEditor = ({ apiEndpoint, pageTitle }) => {
    const [content, setContent] = useState("");
    const [loading, setLoading] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(true);
    const staticPageService = StaticPagesService();
    const quillRef = useRef(null);
    const handleImageUpload = async () => {
        const quill = quillRef.current.getEditor();
        const range = quill.getSelection();
        if (range) {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.click();
            input.onchange = async () => {
                const file = input.files[0];
                const MAX_SIZE_MB = 0.2; // for 1 mb
                const maxSizeInBytes = MAX_SIZE_MB * 1024 * 1024; // Convert MB to bytes
                if (!file) {
                    toast.error('No file selected.');
                    return;
                }

                if (!isValidImage(file)) {
                    toast.error('Invalid file type. Please select an image file (JPEG, PNG, GIF).');
                    return;
                }


                if (file.size > maxSizeInBytes) {
                    toast.error('Image size is too large. Please select an image under 200KB.');
                    return;
                }
                try {
                    const base64Image = await uploadImage(file);
                    const range = quill.getSelection();

                    if (range) {
                        quill.insertEmbed(range.index, 'image', base64Image);
                    } else {
                        quill.insertEmbed(0, 'image', base64Image); // Default to start if no selection
                    }

                    toast.success('Image uploaded successfully!');
                } catch (error) {
                    console.error('Error uploading image: ', error);
                    toast.error('Failed to upload the image. Please try again.');
                }

            };
        } else {
            console.warn("Cannot insert image: No valid selection range");
        }
    };

    function isValidImage(file) {
        const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
        return validImageTypes.includes(file.type);
    }



    const stableApiEndpoint = useMemo(() => apiEndpoint, []);

    useEffect(() => {
        if (stableApiEndpoint) {
            // setLoading(true);
            staticPageService.getStaticPage(stableApiEndpoint)
                .then((response) => {
                    const decodedContent = decode(response.data.description)
                    // console.log("Stable API endpoint", decodedContent)
                    setContent(`${decodedContent}`);

                })
                .catch((error) => {
                    toast.error("Failed to load content");
                    // setLoading(false);
                }).finally(() => {

                    setPageLoaded(false);

                })
        }
    }, [stableApiEndpoint]);



    // Handle content change
    const handleContentChange = (value) => {
        setContent(value);
    };

    // Save content to API
    const saveContent = () => {
        setLoading(true);
        console.log(content, "Content===============")
        staticPageService.updateStaticPage({ description: content }, stableApiEndpoint)
            .then(() => {
                toast.success(`${pageTitle} saved successfully!`);
                setLoading(false);
            })
            .catch(() => {
                toast.error("Failed to save content");
                setLoading(false);
            });
    };

    // Define editor modules
    const modules = useMemo(() => ({
        toolbar: {
            container: "#toolbar",
            handlers: {
                undo: () => handleUndoRedo(quillRef, "undo"),
                redo: () => handleUndoRedo(quillRef, "redo"),

                image: handleImageUpload
            },
        },
        history: {
            delay: 1000,
            maxStack: 50,
            userOnly: true,
        },
    }), []);

    const formats = [
        "font",
        "size",
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        // "bullet",
        "indent",
        "link",
        "image",
        "video",
        "align",
        "code-block",
    ];

    return (
        <React.Fragment>
            <Card>
                <Card.Header>
                    <h3>{pageTitle}</h3>
                </Card.Header>
                <Card.Body>
                   
                    {pageLoaded ? <>
                        <div className="d-flex mt-5 justify-content-center align-content-center">

                            <BorderSpinner />
                        </div>
                    </> : (<>
                        <CustomToolbar />
                        <ReactQuill
                        placeholder={"Write something or insert a image"}
                        ref={quillRef}
                        preserveWhitespace={true}
                        value={content}
                        onChange={handleContentChange}
                        theme="snow"
                        // readOnly={true}
                        modules={modules}
                        formats={formats}
                    />
                    </>)}
                </Card.Body>
                <Card.Footer>
                    <Button onClick={saveContent} disabled={loading}>
                        {loading ? "Saving..." : "Save"}
                    </Button>
                </Card.Footer>
            </Card>
        </React.Fragment>
    );
};

export default CommonPageEditor;
