import React, { useEffect, useRef, useState, useCallback } from "react";
import Header from "../../../layouts/Header";
import TableWithPagination from "../../../common/table";
import useSkinSwitcher from "../../../common/skin";
import StaffService from "../../../service/api/staff.service";
import TransactionService from "../../../service/api/transaction.service";
import AuthService from "../../../service/api/auth.service";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import PageBreadcrumb from "../../../common/PageBreadcrum";
import formatDateTime from '../../../helper/DateFormate'
// import "./staff.css";
// import { DateRange, DateRangePicker } from "react-date-range";
import { addDays, format } from "date-fns";

import capitalizeFirstLetter from "../../../helper/Capitalise";
// import Footer from "../../layouts/Footer";
import _ from "lodash";
// import { Link } from "react-router-dom";

import {
    Button,
    Card,
    Row,
    Col,
    Nav,
    ListGroup,
    Table,
    Badge,
} from "react-bootstrap";
import Sidebar from "../../../layouts/Sidebar";
import { toast } from "react-toastify";
import UserService from "../../../service/api/user.service ";
import GameBetHistoryService from "../../../service/api/game-bet-history.service";
import { useDispatch, useSelector } from "react-redux";
import TableFilter from "../../../common/filter";
import { startApiRequest } from "../../../Redux/Action/loader.action";
import { useLocation, useSearchParams } from 'react-router-dom';
import CricketSportService from "../../../service/api/sport.service";
import { formatCurrency } from "../../../helper/CurrencyFormate";

export default function CricketSportBetList() {
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.state.loader);
    const theme = useSelector((state) => state.ui.skin);
    const staffService = StaffService();
    const userService = UserService();
    const sportService = CricketSportService();
    const transactionService = TransactionService();
    const betHistoryServie = GameBetHistoryService();
    const authService = AuthService();
    const navigate = useNavigate();
    const { skin, setSkin } = useSkinSwitcher();
    const [betHistoryList, setBetHistoryList] = useState([]);
    const [pagination, setPagination] = useState({
        searchBy: "",
        status: "",
        user: "",
        transactionType: "",
        page: 1,
        limit: 10,
        match_id: "",
    });
    const [isHidden, setIsHidden] = useState(false);
    const [userList, setUserList] = useState([]);
    const [showPicker, setShowPicker] = useState(false);
    const [state, setState] = useState([
        {
            startDate: null,
            // endDate: new Date(),
            endDate: null,
            key: "selection",
        },
    ]);
    const clearRange = () => {
        setState([{ startDate: null, endDate: null, key: "selection" }]);
        setShowPicker(false);
    };
    const [columns, setColumns] = useState([
        "match_id",
        "userId",
        "selection_id",
        "select_name",
        "marketName",
        "is_back",
        "createdAt",
        "openingBalance",
        "betAmount",
        "payoutMultiplier",
        "payoutAmount",
        "closingBalance",
        // "currencyId",
        "isWin",
    ]);

    const getList = useCallback(async () => {
        const roundId = searchParams.get('round-id');
        console.log("roundId==>" + pagination.page)
        // dispatch(startApiRequest());
        const res = await sportService.CricketSportBetList({
            page: pagination?.page,
            limit: pagination?.limit,
            searchBy: pagination?.searchBy?.trim(),
            status: pagination?.status ? pagination?.status : "",
            user: pagination?.user ? pagination?.user : '',
            fromDate: pagination?.fromDate ? pagination?.fromDate : '',
            toDate: pagination?.toDate ? pagination?.toDate : '',
            roundId: roundId ? roundId : '',
        });
        if (res?.code === 200) {
            // console.log("===>", res?.data?.docs);
            setBetHistoryList(res?.data?.docs);
            setPagination((prevPagination) => ({
                ...prevPagination,
                pages: res.data.pages,
                totalDocs: res.data.totalDocs,
                limit: res.data.limit,
            }));
        } else {
            console.error(res?.message);
            // toast.error(res?.message);
        }
    }, [
        pagination.page,
        pagination?.toDate,
        pagination?.status,
        pagination.user,
        pagination.searchBy,

        state,
        pagination.transactionType,
    ]);
    useEffect(() => {
        getList();
        // authService.getLoginData();

        // setPagination((preValue) => ({
        //     ...preValue,
        //     page: 1,
        // }));
    }, [
        pagination.page,
        pagination.toDate,
        pagination.status,
        pagination.user,
        pagination.searchBy,

        // getList,
        state,
        pagination.transactionType,
    ]);
    // Define HTML for status badges
    const getStatusBadge = (status) => {
        if (status==1) {
            return (
                <Badge bg={"success"} className="badge badge-success">
                    Won
                </Badge>
            );
        } else if(status==0) {
            return (
                <Badge bg={"pink"} className="badge badge-danger">
                    Lost
                </Badge>
            );
        }
        else {
            return (
                <Badge bg={"info"} className="badge badge-seconday">
                    Pending
                </Badge>
            );
        }
    };

    // Map the columns to include HTML for status badges
    const columnsWithHTML = columns.map((column) => {
        if (column.toLowerCase() === "iswin") {
            return {
                header: "Status",
                accessor: column,
                Cell: ({ value }) => getStatusBadge(value), // Render status badge based on the value
            };
        } else if (column.toLowerCase() === "selection_id") {
            return {
                header: "Selection Id",
                accessor: column,
                Cell: ({ value }) => {
                    return value ? value : "--"
                }, // Render status badge based on the value
            };
        }
        else if (column.toLowerCase() === "select_name") {
            return {
                header: "Selection Name",
                accessor: column,
                Cell: ({ value }) => {
                    return value ? value : "--"
                }, // Render status badge based on the value
            };
        }
             // marketName
             else if (column.toLowerCase() === "marketname") {
                return {
                    header: "Market Name",
                    accessor: column,
                    Cell: ({ value }) => {

                        const obj = {
                            "MatchDetails": "Match Odds",
                            "BookerMakerMarket": "Book Maker",
                            "OtherMarketList":"Tied"
                        }
                        return value ? obj[value] : "--"
                    }, // Render status badge based on the value
                };
            }
        
        else if (column.toLowerCase() === "userid") {
            return {
                header: "User",
                accessor: column,
                Cell: ({ value, row }) => {
                    // console.log("value", value, column);
                    return (
                        <p className="text-truncate m-0 mw-50">
                            <a className="cursor-pointer " onClick={() => {
                                const state = { id: row.userId.id };
                                navigate(`/user-management/view`, { state })
                            }}>{(value?.username)?.toUpperCase() || "N/A"}</a>
                        </p>
                    );
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "createdat") {
            return {
                header: "Date",
                accessor: column,
                Cell: ({ value }) => {
                    // console.log("value", value, column);
                    return (
                        <span className="text-nowrap">{formatDateTime(value, 'gameBets') || "N/A"}</span>
                    )
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "match_id") {
            return {
                header: "Match Id",
                accessor: column,
                Cell: ({ value, row }) => {
                    // console.log("value-transaction", value, column);
                    // return value?.name || "N/A";
                    return (<p className="text-truncate m-0 mw-50">
                        <a className="cursor-pointer " onClick={() => {
                            const state = { match_id:value };
                            navigate(`/cricket-sports-history`, { state })
                        }}>{(value) || "N/A"}</a>
                    </p>)
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "betamount") {
            return {
                header: "Bet",
                accessor: column,
                Cell: ({ value, row }) => {
                    // console.log("row",row);
                    return (
                        <>
                            { <>{formatCurrency(value || '0', row?.currencyId?.currencyCodes)}</>}
                            
                            {/* {(<> <span className="small pe-1">{row?.currencyId?.currencySymbols}</span>{Number(value)?.toFixed(2) || "0.00"}</>)} */}
                        </>
                    )
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "openingbalance") {
            return {
                header: <span className="table-header-hover " title="Opening Balance">O.Balance</span>,
                accessor: column,
                Cell: ({ value, row }) => {
                    // console.log("value", value, column);
                    // return value?.toFixed(2) || "--";
                    return (
                        <>
                            { <>{formatCurrency(value || '0', row?.currencyId?.currencyCodes)}</>}
                            
                            {/* {(<> <span className="small pe-1">{row?.currencyId?.currencySymbols}</span>{value?.toFixed(2) || "0.00"}</>)} */}
                        </>
                    )
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "closingbalance") {
            return {
                header: <span className="table-header-hover " title="Closing Balance">C.Balance</span>,
                accessor: column,
                Cell: ({ value, row }) => {
                    // console.log("value", value, column);
                    return (
                        <>
                            { <>{formatCurrency(value || '0', row?.currencyId?.currencyCodes)}</>}
                            
                            {/* {<> <span title="Closing Balance" className="small pe-1">{row?.currencyId?.currencySymbols}</span>{value?.toFixed(2) || row.openingBalance?.toFixed(2) || '0.00'}</>} */}

                            {/* {value ? (<> <span title="Closing Balance" className="small pe-1">{row?.currencyId?.currencySymbols}</span>{value?.toFixed(2)}</>) :row.openingBalance?.toFixed(2)|| "0.00"} */}
                        </>
                    )
                }, // Render action dropdown based on the value
            };
        }

        else if (column.toLowerCase() === "payoutamount") {
            return {
                header: "Payout Amount",
                accessor: column,
                Cell: ({ value, row }) => {
                    // console.log("value", value, column);
                    return (
                        <>
                            { <>{formatCurrency(value || '0', row?.currencyId?.currencyCodes)}</>}
                            
                            {/* {(<> <span className="small pe-1">{row?.currencyId?.currencySymbols}</span>{value?.toFixed(2) || "0.00"}</>)} */}
                        </>
                    )
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "payoutmultiplier") {
            return {
                header: "Cashout Multiplier",
                accessor: column,
                Cell: ({ value }) => {
                    // console.log("value", value, column);
                    return Number(value)?.toFixed(2) || "0.00";
                }, // Render action dropdown based on the value
            };
        }

        else if (column.toLowerCase() === "is_back") {
            return {
                header: "Back/Lay",
                accessor: column,
                Cell: ({ value }) => {
                    const str = value == '1' ? 'Back' : 'Lay'
                    // console.log("value", value, column);
                    return str || 'N/A';
                }, // Render action dropdown based on the value
            };
        }
        // else if (column.toLowerCase() === "id") {
        //     return {
        //         header: "Actions",
        //         accessor: column,
        //         Cell: ({ value }) => getActionDropdown(value) // Render action dropdown based on the value
        //     };
        // }
        // else {
        //   return column;
        // }
    });

    const debouncedSearch = useCallback(
        _.debounce((value) => {
            setPagination((prevPagination) => ({
                ...prevPagination,
                searchBy: value,
            }));
        }, 300),
        []
    );

    const handleSearchChange = (event) => {
        const value = event.target.value.trim();
        debouncedSearch(value);
    };

    

    const handleRangeChange = (ranges) => {
        setState([ranges.selection]);
    };


    // useEffect(() => {
    //     const getUser = async () => {
    //         try {
    //             const res = await userService.getUserList();
    //             if (res?.code === 200) {
    //                 const formattedUserList = res.data.map((user) => ({
    //                     label: `${user.name} (${user.username})`,
    //                     value: user.id,
    //                 }));
    //                 setUserList(formattedUserList);
    //             }
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     };
    //     getUser();
    // }, []);

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <div className=" align-items-center">
                        <Card.Header>


                            <div className="d-flex justify-content-between align-items-center">

                                <PageBreadcrumb title="Cricket Bet List" />
                                <div>
                                    <Button className="" onClick={() => setIsHidden(!isHidden)}>
                                        Filter
                                    </Button>
                                </div>
                            </div>
                            {/* <h1>Transactions</h1> */}



                            {/* </div> */}
                            <div className={` betlist ${!isHidden ? 'd-none' : ''} `}>
                                {/* Search Input */}
                                <TableFilter
                                    handleRangeChange={handleRangeChange}
                                    handleSearchChange={handleSearchChange}
                                    setPagination={setPagination}
                                    setShowPicker={setShowPicker}
                                    state={state}
                                    tableType="betHistoryCricketSports"
                                    clearRange={clearRange}
                                    userList={userList}
                                    showPicker={showPicker}
                                />
                            </div>
                        </Card.Header>
                        <TableWithPagination
                            tableType={"transaction"}
                            data={[...betHistoryList]}
                            itemsPerPage={pagination?.limit}
                            columns={columnsWithHTML}
                            currentPage={pagination?.page}
                            totalPages1={pagination?.totalPages}
                            totalData={pagination?.totalDocs}
                            pagination={pagination}
                            setPagination={setPagination}
                            moduleId={7}


                        />
                    </div>
                </Card>
            </div>
        </React.Fragment>
    );
}
