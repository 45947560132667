import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import RoleService from "../../service/api/role.service";
import GameService from "../../service/api/game.service";
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";
import MerchantService from "../../service/api/merchant.service";
import ReactSelect from "react-select";
import { darkModeStyles, darkModeStyles2, lightModeStyles, lightModeStyles2 } from "../../common/reactSelectStyles";
import { useSelector } from "react-redux";
const AddNewGame = () => {
  const theme = useSelector((state) => state.ui.skin);
  const { skin, setSkin } = useSkinSwitcher();
  const roleService = RoleService();
  const gameService = GameService();
  const merchantService = MerchantService();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [roleOption, setRoleOption] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedValues, setSelectedValues] = useState([]);
  const [formData, setFormData] = useState({
    merchant: [],
    name: "",
    gameDescription: "",
    category:"",

  });
  const [underMaintainance, setunderMaintainance] = useState([{ value: false, label: "No" }])





  // {{baseurl}}v1/admin/merchant/list/dropdown
  // merchant dropdown list
  const [merchantList, setMerchantList] = useState([]);
  const [merchantOption, setMerchantOption] = useState(null);
  const [categoryList, setCategoryList] = useState(null);

  const [imagePreview, setImagePreview] = useState(null);
  const [maintainanceOption, setMaintainanceOption] = useState([{
    value: true, label: "Yes",

  }, { value: false, label: "No" }]);

  const getMerchantList = async () => {
    const res = await merchantService.getAllMerchants();
    if (res?.code === 200) {
      setMerchantList(res.data);
      let data = res.data.map((item) => ({ value: item.id, label: item.name }));
      setMerchantOption(data);
    }
    // console.log(merchantList, " and ", merchantOption)
  }
  

  const getRoleList = async () => {
    const res = await roleService.getRolelistDropdown();
    if (res?.code === 200) {
      setRoleList(res.data);
      let data = res.data.map((item) => ({ value: item.id, label: item.name }));
      setRoleOption(data);
    }
  };

  const getCategory = async () => {
    const res = await gameService.getCategoryList();
    if (res?.code === 200) {
      
      let data = res.data.map((item) => ({ value: item.id, label: item.name }));
      setCategoryList(data);
    }
    // console.log(merchantList, " and ", merchantOption)
  }

  useEffect(() => {
    getRoleList();
    getCategory();
    getMerchantList();
  }, []);

  const handleSelectChange = (items) => {
    console.log(items, 'form item');
    const valuesArray = items ? items.map(item => item.value) : [];
    // setSelectedValues(valuesArray);
    setFormData((preValue) => ({
      ...preValue,
      merchant: valuesArray
    }));
    setShowError(false)
    // Now you can send selectedValues to the API or use it elsewhere
    console.log(valuesArray, 'array of string values');
  };

  const handleMaintainanceSelectChange = (items) => {
    console.log(items, 'form item');
    // const valuesArray = items ? items?.map(item => item.value) : [];
    // setSelectedValues(valuesArray);
    setFormData((preValue) => ({
      ...preValue,
      underMaintenance: items.value
    }));
    setShowError(false);
    setunderMaintainance(items);

  };

  const handleChangeCategory=(items) => {
    // console.log(items, 'form item');
    // const valuesArray = items ? items?.map(item => item.value) : [];
    // setSelectedValues(valuesArray);
    setFormData((preValue) => ({
      ...preValue,
      category: items.value
    }));
    setShowError(false);

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("{ ...errors, [name]: errorMessage }", name, value);

    let errorMessage = "";

    // Perform validation based on the field name
    switch (name) {
      case "name":
        if (!value.trim()) {
          errorMessage = "Name is required.";
        } else if (!/^[a-zA-Z0-9\s]*$/.test(value)) {
          errorMessage = "Name is required";
        } else if (value?.trim().length > 50) {
          errorMessage = "Name must not contain more than 50 characters"
        }
        else {
          errorMessage = "";
        }
        break;
      case "category":
        if (!value) {
          errorMessage = "Category is required.";
        } 
        else {
          errorMessage = "";
        }
        break;
      break;
      // Add validation for other fields as needed
      default:
        break;
    }


    setFormData({ ...formData, [name]: value });
    // showError(false)
    setErrors({ ...errors, [name]: errorMessage });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add your form submission logic here
    // Perform validations
    console.log("formData", formData);
    const validationErrors = {};
    // if (formData.role == "") {
    //   validationErrors.role = "Role is required";
    // } else {
    //   validationErrors.role = "";
    // }
    if (formData.name.trim() == "") {
      validationErrors.name = "Name is required";
    } else if (!/^[a-zA-Z0-9\s]*$/.test(formData.name)) {
      validationErrors.name = "Name should be alphanumeric";
    } else if (formData.name?.trim().length > 50) {
      validationErrors.name = "Name must not contain more than 50 characters"
    }

    else {
      validationErrors.name = "";
    }


    // if (formData.merchant?.length ===0) {
    //   validationErrors.merchant = "Merchant is required";
    // } 
    // else {
    //   validationErrors.merchant = "";
    // }
    if (formData?.underMaintainance) {
      validationErrors.underMaintainance = "Under Maintainance is required";
    }
    else {
      validationErrors.underMaintainance = "";
    }


    // Add more password validation logic as needed
    console.log("validationErrors", Object.values(validationErrors));

    if (Object.values(validationErrors).every(item => item === '')) {
      // No errors, proceed with form submission
      console.log("resss===")
      setShowError(false);
      console.log("formData", formData);
      const formDataToSend = new FormData();

      // Append form fields to FormData
      formDataToSend.append("name", formData.name);
      formDataToSend.append("category", formData.category);
      formDataToSend.append("underMaintenance", JSON.stringify(formData.underMaintenance));
      formDataToSend.append("gameDescription", formData.gameDescription);
      if (formData.merchant.length !== 0) {
        formDataToSend.append("merchant", JSON.stringify(formData.merchant));
      }
      // Append file if it exists
      if (formData.image) {
        formDataToSend.append("image", formData.image);
      }
      const res = await gameService.createGame(formData);
      console.log("resss===", res)
      if (res?.code == 200) {
        toast.success(res?.message)
        navigate('/game-management')
      } else {
        toast.error(res?.message)
      }
    } else {
      // Set validation errors
      setShowError(true);
      setErrors(validationErrors);
    }
  };

  const handleRoleChange = (selectedOption) => {
    let validationErrors = "";
    if (selectedOption.value == "") {
      validationErrors = "Role is required";
    } else {
      validationErrors = "";
    }
    setShowError(true);
    setErrors({ ...errors, role: validationErrors });
    setFormData({ ...formData, role: selectedOption.value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      formData.image = file;
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Set the preview image URL
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <div className=" align-items-center mb-4">
          <Card>
            <Card.Header>
              <h4 className="card-title w-100">Add New Game</h4>
            </Card.Header>
            <Card.Body>
              <div className="d-flex justify-content-center">

                <Col md={6}>
                  <img src={imagePreview} style={{ maxWidth: '100%' }} />
                </Col>
              </div>
              <Form className="row" onSubmit={handleSubmit}>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="name">
                    <Form.Label>Banner Image</Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="Enter name"
                      name="image"
                      onChange={handleImageChange}
                    // value={formData.name}
                    // onChange={handleChange}
                    // isInvalid={showError && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-3">
                  <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {/* <Col md={4} className="mt-3">
                  <Form.Group controlId="merchant">
                    <Form.Label>Merchant</Form.Label>

                    <ReactSelect

                      styles={theme === 'dark' ? darkModeStyles2 : lightModeStyles2}
                      name="merchant"
                      options={merchantOption}
                      isMulti
                      onChange={handleSelectChange}

                    />
                   

                   
                  </Form.Group>
                </Col> */}

                <Col md={4} className="mt-3">
                  <Form.Group controlId="maintainance">
                    <Form.Label>Under Maintainance</Form.Label>

                    <ReactSelect
                      defaultValue={{ value: false, label: 'No' }}
                      styles={theme === 'dark' ? darkModeStyles2 : lightModeStyles2}
                      name="maintainance"
                      options={maintainanceOption}

                      onChange={handleMaintainanceSelectChange}

                    />
                    <Form.Text className="text-danger small">
                      {errors.underMaintainance !== "" && showError && "Under Maintainance is required"}
                    </Form.Text>
                    {/* <Form.Control.Feedback type="invalid">
                  {errors.merchant}
                </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="category">
                    <Form.Label>Category</Form.Label>

                    <ReactSelect
                      
                      styles={theme === 'dark' ? darkModeStyles2 : lightModeStyles2}
                      name="category"
                      options={categoryList}
                      onChange={handleChangeCategory}

                    />
                    {/* <Form.Text className="text-danger small">
                    
                      {errors.merchant !== "" && showError && "Merchant is required"}
                    </Form.Text> */}

                    {/* <Form.Control.Feedback type="invalid">
                  {errors.merchant}
                </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="gameDescription">
                    <Form.Label>Game Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="gameDescription"
                      placeholder="Enter game description"
                      value={formData.gameDescription}
                      onChange={handleChange}
                      rows={5} // Adjust the number of rows to control the height of the textarea
                      isInvalid={errors.gameDescription !== "" && showError}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.gameDescription !== "" && showError && "Game description is required"}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <div className="d-flex justify-content-center mt-5">
                  <Button variant="primary" type="submit" className="mx-4">
                    Submit
                  </Button>
                  <Button variant="danger" type="button" onClick={() => navigate("/game-management")}>
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddNewGame;
