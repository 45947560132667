import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useSelector } from "react-redux";
import './dateFilter.css'
import { NumericFormat } from 'react-number-format';
const TransactionStatistics = ({ statistics }) => {
    const skin = useSelector((state) => state.ui.skin);
    return (
        <>
            <Row className={`mb-4 ${skin == "dark" && 'customTable'}`}>
                <Col lg={3} md={6} sm={12} className="mb-3">
                    <Card>
                        <Card.Body>
                            <h6>Total Transaction</h6>
                            <h4>
                                {/* {statistics?.totalTransactions} */}
                                <NumericFormat
                                    value={statistics?.totalTransactions}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    // prefix={'₹'}
                                    allowedDecimalSeparators={['%']}
                                    allowNegative
                                    decimalScale={2}
                                    thousandsGroupStyle="lakh"
                                />

                            </h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3} md={6} sm={12} className="mb-3">
                    <Card>
                        <Card.Body>
                            <h6>Transaction Initiated</h6>
                            <h4>
                                {/* {statistics?.initiatedTransactions} */}

                                <NumericFormat
                                    value={statistics?.initiatedTransactions}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    // prefix={'₹'}
                                    allowedDecimalSeparators={['%']}
                                    allowNegative
                                    decimalScale={2}
                                    thousandsGroupStyle="lakh"
                                />
                            </h4>
                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={3} md={6} sm={12} className="mb-3">
                    <Card>
                        <Card.Body>
                            <h6>Net CashFlow</h6>
                            <h4>
                                {/* ₹{Number(statistics?.netCashFlow)?.toFixed(2)} */}
                                <NumericFormat
                                    value={statistics?.netCashFlow}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'₹'}
                                    allowedDecimalSeparators={['%']}
                                    allowNegative
                                    decimalScale={2}
                                    thousandsGroupStyle="lakh"
                                />
                            </h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3} md={6} sm={12} className="mb-3">
                    <Card>
                        <Card.Body>
                            <h6>Successfull Transactions</h6>
                            <h4>
                                {/* {statistics?.successfulTransactions} */}
                                <NumericFormat
                                    value={statistics?.successfulTransactions}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    // prefix={'₹'}
                                    allowedDecimalSeparators={['%']}
                                    allowNegative
                                    decimalScale={2}
                                    thousandsGroupStyle="lakh"
                                />
                            </h4>
                        </Card.Body>
                    </Card>
                </Col>


            </Row>
            <Row className={`mb-4 ${skin == "dark" && 'customTable'}`}>
                <Col lg={6} md={12} sm={12} className="mb-3">
                    <Card>
                        <Card.Body>
                            <h5>Deposits</h5>
                            <Row>
                                <Col lg={6} md={6} sm={12} className="mb-3">
                                    <div className="stat-item ">
                                        <div className="stat-icon bg-info text-white shadow-lg">
                                            <i className="fas fa-hand-holding-usd"></i>
                                        </div>
                                        <div>
                                            <h4>
                                                {/* ₹{Number(statistics?.totalDeposits)?.toFixed(2)} */}
                                                <NumericFormat
                                                    value={statistics?.totalDeposits}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'₹'}
                                                    allowedDecimalSeparators={['%']}
                                                    allowNegative
                                                    decimalScale={2}
                                                    thousandsGroupStyle="lakh"
                                                />
                                            </h4>
                                            <h6>Total Deposits</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} className="mb-3">
                                    <div className="stat-item">
                                        <div className="stat-icon">
                                            <i className="fa-solid fa-layer-group"></i>
                                        </div>
                                        <div>
                                            <h4>
                                                {/* {statistics?.totalDepositsTransactions} */}
                                                <NumericFormat
                                                    value={statistics?.totalDepositsTransactions}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    // prefix={'₹'}
                                                    allowedDecimalSeparators={['%']}
                                                    allowNegative
                                                    decimalScale={2}
                                                    thousandsGroupStyle="lakh"
                                                />
                                            
                                            </h4>
                                            <h6>Total Deposit Transaction</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} className="mb-3">
                                    <div className="stat-item">
                                        <div className="stat-icon bg-danger text-white shadow-lg">
                                            <i className="fa-solid fa-ban"></i>
                                        </div>
                                        <div>
                                            <h4>
                                                {/* {statistics?.depositRequestsRejected} */}
                                                <NumericFormat
                                                    value={statistics?.depositRequestsRejected}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    // prefix={'₹'}
                                                    allowedDecimalSeparators={['%']}
                                                    allowNegative
                                                    decimalScale={2}
                                                    thousandsGroupStyle="lakh"
                                                />
                                            
                                            </h4>
                                            <h6>Failed Deposits</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} className="mb-3">
                                    <div className="stat-item">
                                        <div className="stat-icon bg-success text-white shadow-lg" >
                                            <i class="fa-regular fa-circle-check"></i>
                                        </div>
                                        <div>
                                            <h4>
                                                {/* {statistics?.totalSuccessDepositsTransactions || '0'} */}
                                                <NumericFormat
                                                    value={statistics?.totalSuccessDepositsTransactions || '0'}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    // prefix={'₹'}
                                                    allowedDecimalSeparators={['%']}
                                                    allowNegative
                                                    decimalScale={2}
                                                    thousandsGroupStyle="lakh"
                                                />
                                            </h4>
                                            <h6>Success Deposits</h6>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={6} md={12} sm={12} className="mb-3">
                    <Card>
                        <Card.Body>
                            <h5>Withdrawals</h5>
                            <Row>
                                <Col lg={6} md={6} sm={12} className="mb-3">
                                    <div className="stat-item ">
                                        <div className="stat-icon bg-info text-white shadow-lg">
                                            <i className="fa-solid fa-wallet"></i>
                                        </div>
                                        <div>
                                            <h4>
                                                {/* ₹{Number(statistics?.totalWithdrawals)?.toFixed(2)} */}
                                                <NumericFormat
                                                    value={statistics?.totalWithdrawals}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'₹'}
                                                    allowedDecimalSeparators={['%']}
                                                    allowNegative
                                                    decimalScale={2}
                                                    thousandsGroupStyle="lakh"
                                                />
                                            </h4>
                                            <h6>Total Withdrawals</h6>
                                        </div>
                                    </div>
                                </Col>

                                <Col lg={6} md={6} sm={12} className="mb-3">
                                    <div className="stat-item">
                                        <div className="stat-icon">
                                            <i className="fa-solid fa-layer-group"></i>
                                        </div>
                                        <div>
                                            <h4>
                                                {/* {statistics?.totalWithdrawalRequests} */}
                                                <NumericFormat
                                                    value={statistics?.totalWithdrawalRequests}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    // prefix={'₹'}
                                                    allowedDecimalSeparators={['%']}
                                                    allowNegative
                                                    decimalScale={2}
                                                    thousandsGroupStyle="lakh"
                                                />
                                            </h4>
                                            <h6>Total Withdrawal Request</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} className="mb-3">
                                    <div className="stat-item">
                                        <div className="stat-icon bg-danger text-white shadow-lg" >
                                            <i className="fa-solid fa-ban"></i>
                                        </div>
                                        <div>
                                            <h4>
                                                {/* {statistics?.withdrawalRequestsRejected} */}
                                                <NumericFormat
                                                    value={statistics?.withdrawalRequestsRejected}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    // prefix={'₹'}
                                                    allowedDecimalSeparators={['%']}
                                                    allowNegative
                                                    decimalScale={2}
                                                    thousandsGroupStyle="lakh"
                                                />
                                            </h4>
                                            <h6>Failed Withdrawals</h6>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} className="mb-3">
                                    <div className="stat-item">
                                        <div className="stat-icon bg-success text-white shadow-lg" >
                                            <i class="fa-regular fa-circle-check"></i>
                                        </div>
                                        <div>
                                            <h4>
                                                {/* {statistics?.totalSuccessWidrawTransactions || '0'} */}
                                                <NumericFormat
                                                    value={statistics?.totalSuccessWidrawTransactions || '0'}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    // prefix={'₹'}
                                                    allowedDecimalSeparators={['%']}
                                                    allowNegative
                                                    decimalScale={2}
                                                    thousandsGroupStyle="lakh"
                                                />
                                            
                                            </h4>
                                            <h6>Success Withdrawals</h6>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default TransactionStatistics;
