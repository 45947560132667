import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import bg1 from "../assets/img/bg1.jpg";
import AuthService from "../service/api/auth.service";
import { toast } from "react-toastify";
import useSocketService from "../service/api/socket.service";
import { io } from "socket.io-client";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import logo from "../assets/img/logo.svg";
import Logo from "../common/logo";
import { useDispatch, useSelector } from "react-redux";
import { loginFailure, loginRequest, loginSuccess } from "../Redux/Action/auth.action";
export default function Signin2() {
  const authService = AuthService();

  const { connect, disconnect, emit, on } = useSocketService();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.isLoading);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    domain:window.location.origin
  });

  const [formErrors, setFormErrors] = useState({});
  const [token, setToken] = useState(localStorage.getItem("token"));
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/; // At least 8 characters, one letter, and one number

  const validateForm = (formData) => {
    const errors = {};

    if (!formData.email) {
      errors.email = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid email address";
    }
    if (!formData.password) {
      errors.password = "Password is required";
    }
    // else if (!passwordRegex.test(formData.password)) {
    //   console.log("formData.password", formData.password);
    //   errors.password =
    //     "Password must be at least 8 characters long and contain at least one letter and one number including special character.";
    // }
    return errors;
  };

  const validateField = (name, value) => {
    const errors = {};
    switch (name) {
      case "email":
        if (!value) {
          errors.email = "Email address is required";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          errors.email = "Invalid email address";
        }
        break;
      case "password":
        if (!value) {
          errors.password = "Password is required";
        }
        // else
        //   if (!passwordRegex.test(value)) {
        //   console.log("formData.password", value);
        //   errors.password =
        //     "Password must be at least 8 characters long and contain at least one letter and one number including special character.";
        // }
        break;
      default:
        break;
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const errors = validateField(name, value);
    setFormErrors({
      ...formErrors,
      [name]: errors[name] || "", // Clear previous error for this field
    });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const navigateToWelcomeOrDashboard = (data, loginDetails) => {
    if (data && loginDetails.type !== "superadmin") {
      console.log();
      for (let i = 0; i < data.resource.length; i++) {
        if (data.resource[i].moduleId) {
          if (
            data.resource[i].moduleId === "1" &&
            (data.resource[i].permissions.includes("selectedAll") ||
              data.resource[i].permissions.includes("list"))
          ) {
            console.log("roleeeeeeeeee");
            alert("hete");
            return navigate("/role-management");
          } else if (
            data.resource[i].moduleId === "2" &&
            (data.resource[i].permissions.includes("selectedAll") ||
              data.resource[i].permissions.includes("list"))
          ) {
            alert("hete");
            return navigate("/staff-management");
          } else if (
            data.resource[i].moduleId === "3" &&
            (data.resource[i].permissions.includes("selectedAll") ||
              data.resource[i].permissions.includes("list"))
          ) {
            alert("hete");
            return navigate("/user-management");
          } else if (
            data.resource[i].moduleId === "4" &&
            (data.resource[i].permissions.includes("selectedAll") ||
              data.resource[i].permissions.includes("list"))
          ) {
            alert("hete");
            return navigate("/merchant-management");
          }
        }
      }
    } else {
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Reset form errors
    setFormErrors({});

    // Validate form fields
    const errors = validateForm(formData);
    if (Object.keys(errors).length > 0) {
      // If there are errors, set them and return
      setFormErrors(errors);
      return;
    }

    try {
      dispatch(loginRequest());
      formData.domain = window.location.origin;
      const response = await authService.login(formData);
      if (response.code === 200) {
        toast.success(response.message);
        console.log(response, "=========hello login============");
        dispatch(loginSuccess(response?.data?.user))
        localStorage.setItem("token", response.data.tokens.refresh.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));

        navigate("/dashboard");
        // if (response?.data?.user?.type === 'superadmin') {
        // } else {
        //   localStorage.setItem('userType', 'subadmin');
        //   // navigateToWelcomeOrDashboard(response.data.user.role, response.data.user);
        // }
      } else {
        toast.error(response.message);
        dispatch(loginFailure(response.message));
      }
    } catch (error) {
      dispatch(loginFailure('An error occurred during login'));
      console.error("An error occurred during login:", error);
      // toast.error("An error occurred during login. Please try again.");
    }
  };

  const [togglePasswordShow, setTogglePasswordShow] = useState(false);
  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="5" xl="4" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              {/* <Link to="/" className="header-logo mb-5">
                
                KripaBets
              </Link> */}
              {/* <div className=" mb-5">
                <Logo />
              </div> */}

              <Card.Title>Sign In</Card.Title>
              <Card.Text>Welcome back! Please signin to continue.</Card.Text>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your email address"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {formErrors.email && (
                    <div className="text-danger">{formErrors.email}</div>
                  )}
                </div>
                <div className="mb-4">
                  <Form.Label className="d-flex justify-content-between">
                    Password 
                  </Form.Label>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      type={`${togglePasswordShow ? "text" : "password"}`}
                      placeholder="Enter your password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                      onClick={() => setTogglePasswordShow(!togglePasswordShow)}
                    >
                      {togglePasswordShow ? (
                        <VscEye style={{ height: "15px" }} />
                      ) : (
                        <VscEyeClosed style={{ height: "15px" }} />
                      )}
                    </div>
                  </div>

                  {formErrors.password && (
                    <div className="text-danger">{formErrors.password}</div>
                  )}
                </div>
                <Button disabled={isLoading} type="submit" className="btn-sign text-capitalize">
                  {isLoading?'Please wait...':'Sign In'}
                </Button>

                {/* <div className="divider">
                  <span>or sign in with</span>
                </div> */}

                {/* <Row className="gx-2">
                  <Col>
                    <Button variant="" className="btn-facebook">
                      <i className="ri-facebook-fill"></i> Facebook
                    </Button>
                  </Col>
                  <Col>
                    <Button variant="" className="btn-google">
                      <i className="ri-google-fill"></i> Google
                    </Button>
                  </Col>
                </Row> */}
              </Form>
            </Card.Body>
            {/* <Card.Footer>
              Don't have an account? <Link to="/signup">Create an Account</Link>
            </Card.Footer> */}
          </Card>
        </Col>
        <Col className="d-none d-lg-block">
          <img src={bg1} className="auth-img" alt="" />
        </Col>
      </Row>
    </div>
  );
}
