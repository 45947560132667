import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { AsyncPaginate } from "react-select-async-paginate";
import { useSelector } from "react-redux";
import { darkModeStyles, lightModeStyles } from "../../common/reactSelectStyles";
// import IpWhitelistService from "../../service/api/ipWhitelist.service"; // New service for IP Whitelisting
import { toast } from "react-toastify";
import IPWhitelistingService from "../../service/api/ip-whitelisting.service";
import MerchantService from "../../service/api/merchant.service";
import { BorderSpinner } from "../../common/Spinner";

const AddIpWhitelistModal = ({ setModalTypeAndId, modalTypeAndId, show, addTo, onClose }) => {
  const theme = useSelector((state) => state.ui.skin);
  const ipWhitelistService = IPWhitelistingService(); // Replace CurrencyService with IpWhitelistService
  const merchantService = MerchantService();
  // const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [formData, setFormData] = useState({
    ip: "",
    identifier: "",
    merchantId: "",
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!show) {
      // Reset the form when the modal closes
      setFormData({ ip: "", identifier: "", merchantId: "" });
      setValidationErrors({});
      // setSelectedMerchant(null);
      // setModalTypeAndId({type:'',id:''});
    }
    else if (show) {
      if (modalTypeAndId.type === 'edit' || modalTypeAndId.type === 'view') {
        getIPDetails(modalTypeAndId.id);
        console.log('called')
      }
    }
  }, [show, modalTypeAndId]);





  const getIPDetails = async (id) => {
    try {
      const res = await ipWhitelistService.getIPById(id);
      if (res.code === 200) {
        setFormData({
          ip: res.data.ip,
          identifier: res.data.identifier,
          // merchantId: res.data.merchantId?.id,
        });
        // setSelectedMerchant({
        //   value: res.data.merchantId?.id,
        //   label: res.data.merchantId?.name,
        // });
      }
      else {
        throw new Error(res.message);
      }
    } catch (error) {
      console.error("Error fetching IP details:", error);
    } finally {
      setLoading(false);
    }
  }

  const validateForm = () => {
    const errors = {};
    const ipv4Pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const ipv6Pattern = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}(([0-9]{1,3}\.){3}[0-9]{1,3})|([0-9a-fA-F]{1,4}:){1,4}:(([0-9]{1,3}\.){3}[0-9]{1,3}))$/;
  
    if (!formData.ip || !(ipv4Pattern.test(formData.ip) || ipv6Pattern.test(formData.ip))) {
      errors.ip = "Please enter a valid IPv4 or IPv6 address.";
    }
  
    if (!formData.identifier?.trim()) {
      errors.identifier = "Please enter an identifier.";
    }
    // if (addTo === 'merchant' && !formData.merchantId) { 
    //   errors.merchantId = "Please select a merchant.";
    // }
  
    return errors;
  };



  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));

    // Clear the validation error for the field being updated
    setValidationErrors((prev) => ({
      ...prev,
      [field]: "",
    }));
  };

  const handleSubmit = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setIsSubmitting(true);
    try {
      const payload = {
        ip: formData.ip,
        identifier: formData.identifier?.trim(),
        type: addTo,
      };

      // Add `merchantId` only if `addTo` is "merchant"
      // if (addTo === "merchant") {
      //   payload.merchantId = formData.merchantId;
      // }
      const res = await ipWhitelistService.AddIP(payload);

      if (res.code === 200) {
        toast.success(res.message);
        setIsSubmitting(false);
        onClose(); // Close the modal
        setLoading(true);
        setFormData({ ip: "", identifier: "", merchantId: formData.merchantId });
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error("Error adding IP to whitelist:", error);
      // toast.error("An error occurred while adding the IP to the whitelist.");
    } finally {
      setIsSubmitting(false);
      
    }
  };

  const handleupdate = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setIsSubmitting(true);
    try {
      const payload = {
        ip: formData.ip,
        identifier: formData.identifier?.trim(),
        type: addTo,
      };

      // Add `merchantId` only if `addTo` is "merchant"
      // if (addTo === "merchant") {
      //   payload.merchantId = formData.merchantId;
      // }
      const res = await ipWhitelistService.updateIP(modalTypeAndId?.id, payload);

      if (res.code === 200) {
        toast.success(res.message);
        setIsSubmitting(false);
        onClose(); // Close the modal
        setLoading(true);
        setFormData({ ip: "", identifier: "", merchantId: formData.merchantId });
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error("Error adding IP to whitelist:", error);
      toast.error("An error occurred while adding the IP to the whitelist.");
    } finally {
      setIsSubmitting(false);
      

    }
  };

  return (
    <Modal show={show} onHide={() => {
      onClose();
      setLoading(true);
    }} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Add IP to Whitelist</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {((loading && modalTypeAndId.type !== '') ? <div className="d-flex my-5 justify-content-center align-content-center">

          <BorderSpinner />
        </div> : <Form>
          {/* Merchant Select */}
          {/* {addTo==='merchant' && <Form.Group className="mb-3">
            <Form.Label>Select Merchant</Form.Label>
            {modalTypeAndId.type === 'view' ?
              
               <Form.Control
              type="text"
              disabled={modalTypeAndId?.type==='view'}
             
              value={selectedMerchant?.label ||''}
             
            />
             
              : <AsyncPaginate
              
              value={selectedMerchant}
              loadOptions={loadOptions}
              onChange={(selectedOption) => {
                setSelectedMerchant(selectedOption);
                handleInputChange("merchantId", selectedOption ? selectedOption.value : "");
              }}
              additional={{ page: 1 }}
              styles={theme === "dark" ? darkModeStyles : lightModeStyles}
              placeholder="Select a merchant"
              isClearable
            />}
            {validationErrors.merchantId && (
              <Form.Text className="text-danger">{validationErrors.merchantId}</Form.Text>
            )}
          </Form.Group>} */}

          {/* IP Input */}
          <Form.Group className="mb-3">
            <Form.Label>Enter IP Address</Form.Label>
            <Form.Control
              type="text"
              disabled={modalTypeAndId.type === 'view'}
              placeholder="Enter IP address"
              value={formData.ip}
              onChange={(e) => handleInputChange("ip", e.target.value)}
              isInvalid={!!validationErrors.ip}
            />
            <Form.Control.Feedback type="invalid">{validationErrors.ip}</Form.Control.Feedback>
          </Form.Group>

          {/* Identifier Input */}
          <Form.Group className="mb-3">
            <Form.Label>Enter Identifier</Form.Label>
            <Form.Control
              type="text"
              disabled={modalTypeAndId.type === 'view'}
              placeholder="Enter Identifier"
              value={formData.identifier}
              onChange={(e) => handleInputChange("identifier", e.target.value)}
              isInvalid={!!validationErrors.identifier}
            />
            <Form.Control.Feedback type="invalid">{validationErrors.identifier}</Form.Control.Feedback>
          </Form.Group>
        </Form>)}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
      onClose();
      setLoading(true);
    }} disabled={isSubmitting}>
          Close
        </Button>
        {modalTypeAndId.type === '' ? <Button variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? "Please Wait..." : "Add IP"}
        </Button> : modalTypeAndId.type === 'edit' ? <Button variant="primary" onClick={handleupdate} disabled={isSubmitting}>
          {isSubmitting ? "Please Wait..." : "Update IP"}
        </Button> : ''}
      </Modal.Footer>
    </Modal>
  );
};

export default AddIpWhitelistModal;
