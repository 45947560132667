// cricket-sport/admin/match-history

// import { BehaviorSubject } from "rxjs";
// import axios from "axios";
// import { environment } from "../../envirnoment/envirnoment.prod";
// import { map, catchError, tap, retry } from "rxjs/operators";

import createAxiosInstance from "../api.instance";
import { useDispatch } from "react-redux";
import { endApiRequest, startApiRequest } from "../../Redux/Action/loader.action";


const ReferralService = () => {
    const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage
    const dispatch = useDispatch();
    const axiosInstance = createAxiosInstance(bearerToken);

    const getReferralSettings = async () => {
        dispatch(startApiRequest());
        return await axiosInstance
            .get(`referral/get-settings`, { headers: {} })
            .then((response) => {
                dispatch(endApiRequest());
                console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                dispatch(endApiRequest());
                console.error("Registration failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };


    // cricket-sport/update-winner
    const updateReferralSettings = async (requestData) => {
        // const {matchId, winningSelectionId} = req.body
        dispatch(startApiRequest());
        return await axiosInstance
            .post(`/referral/update-settings`, requestData, { headers: {} })
            .then((response) => {
                dispatch(endApiRequest());
                console.log("updated =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                dispatch(endApiRequest());
                console.error("failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };


    return {
        updateReferralSettings,
        getReferralSettings

    };
};

export default ReferralService;
