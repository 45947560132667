import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import DashboardService from "../../service/api/dashboard.service";
import DateFilter from "./date-filter";
import { endOfMonth, format, startOfMonth } from "date-fns";
import cronParser from 'cron-parser';
import AdminCronSettings from "./AdminCronSetting";
import moment from "moment/moment";
import TransactionStatistics from "./TransactionsStatistics";
import { useSelector } from "react-redux";
import './dateFilter.css'
import { Loader } from "../../common/Spinner";
export default function GameMetrics() {
    const skin = useSelector((state) => state.ui.skin);
    const dashboardService = DashboardService();
    const [statistics, setStatistics] = useState(null);
    const today = new Date();
    const initialStartDate = startOfMonth(today);
    const initialEndDate = endOfMonth(today);

    // const [dateRange, setDateRange] = useState({
    //     startDate: format(initialStartDate, 'yyyy-MM-dd'),
    //     endDate: format(initialEndDate, 'yyyy-MM-dd')
    // });
    // const [dateRange, setDateRange] = useState({
    //     startDate: format(initialStartDate, 'yyyy-MM-dd'),
    //     endDate: format(initialEndDate, 'yyyy-MM-dd')
    // });
    const [dateRange, setDateRange] = useState({
        startDate: format(today, 'yyyy-MM-dd'),
        endDate: format(today, 'yyyy-MM-dd')
    })
    const [cronExpression, setCronExpression] = useState(localStorage.getItem('cronExpression') || '');
    const timeoutId = useRef(null);
    const axisColor = skin == "dark" ? 'rgba(255, 255, 255, 0.6)' : 'rgb(224, 224, 224)';
    const labelColor = skin == "dark" ? 'rgba(255, 255, 255, 0.6)' : '#41505f';
    useEffect(() => {
        localStorage.setItem('cronExpression', cronExpression);
        getStatistics(); // Initial fetch
        scheduleFetch();

        // Cleanup function to clear timeout if cronExpression or dateRange changes
        return () => {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
        };
    }, [cronExpression, dateRange]);

    // useEffect(() => {
    //     getStatistics();
    // }, [dateRange])

    const scheduleFetch = () => {
        try {
            const adjustedCron = cronExpression.split(' ').slice(1, 6).join(' ');
            const interval = cronParser.parseExpression(adjustedCron);
            const now = new Date();
            const nextExecution = interval.next().toDate();
            const delay = nextExecution - now;
            // console.log(delay, "execution delay", interval, interval.next().toDate(), adjustedCron, cronExpression)
            if (delay > 0) {
                const id = setTimeout(() => {
                    getStatistics();
                    scheduleFetch(); // Schedule next fetch
                }, delay);
                timeoutId.current = id; // Store timeout ID to clear it later
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getStatistics = async () => {
        try {
            const res = await dashboardService.getGameMetrics(dateRange);
            console.log(res.data, "console from transaction metrics");
            setStatistics(res?.data); // Assuming the API response is in the expected format
        } catch (error) {
            console.error("Error fetching bet statistics", error);
        }
    }



    const processedData = statistics?.successRate?.map((item) => {
        console.log(item)
        const timestamp = new Date(item.dateTime).getTime(); // Convert dateTime to timestamp
        const value = Number(item.successRate); // Ensure successRate is a number
        if (isNaN(timestamp) || isNaN(value)) {
            console.warn("Invalid data point:", { dateTime: item.dateTime, successRate: item.successRate });
        }
        return {
            x: timestamp,
            y: value
        };
    }).filter(point => !isNaN(point.x) && !isNaN(point.y)) || [];

    // console.log("Processed Data:", processedData);

   
    const transactionOverTimeData = {
        series: [{
            name: "Bets Placed",
            data: statistics?.gameSessions.map((item) => item.totalBets) || [],
        }],
        options: {
            chart: {
                height: 350,
                type: 'line',
                toolbar: {
                    autoSelected: 'zoom',
                    show: true, // Ensure the toolbar is enabled
                    tools: {
                        download: true, // Enable the download tool
                    },
                    export: {
                        csv: {
                            filename: 'Bets Over Month', // Custom file name for CSV export
                            headerCategory: 'Date & Time', // Custom header name for category (X-axis)
                            columnHeaders: ['Date & Time', 'Bet Placed'], // Explicitly set headers for each column
                            dateFormatter(timestamp) { // Custom date format for CSV export
                                return new Date(timestamp).toLocaleString();
                            }
                        },
                        svg: {
                            filename: 'Bets Over Month', // Custom file name for SVG export
                            width: 800, // Custom width
                            height: 600, // Custom height
                            backgroundColor: '#f0f0f0', // Custom background color
                        },
                        png: {
                            filename: 'Bets Over Month', // Custom file name for PNG export
                            width: 800, // Custom width
                            height: 600, // Custom height
                            backgroundColor: '#000000', // Custom background color
                        }
                    },
                },
            },
            stroke: {
                curve: 'smooth'
            },

            xaxis: {
                categories: statistics?.gameSessions?.map((item) => item.gameName) || [],
                axisBorder: {
                    show: true,
                    color: axisColor,
                    height: 2,
                },
                axisTicks: {
                    show: true,
                    color: axisColor,
                },
                labels: {
                    rotate: -45, // Rotates the labels by -45 degrees
                    style: {
                        colors: labelColor,
                    }
                }
            },
            yaxis: {
                axisBorder: {
                    show: true,
                    color: axisColor,
                },
                axisTicks: {
                    show: true,
                    color: axisColor,
                },
                labels: {
                    style: {
                        colors: labelColor,
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400
                    }
                }
            },
            tooltip: {
                theme: skin == "dark" ? "dark" : "light",
            },

            grid: {
                show: true,
                borderColor: axisColor,
                strokeDashArray: 0,
                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
                padding: {
                    left: 13,
                    right: 10,
                    top: 0,
                    bottom: 0
                }
            }
        }
    };

    const avgBetSizeData = {
        series: [
            {
                name: "Users",
                data: statistics?.popularGame.map((item) => item.numberOfUsers),
            },
        ],
        options: {
            tooltip: {
                theme: skin == "dark" ? "dark" : "light",
            },
            chart: {
                height: 350,
                type: 'bar',
                toolbar: {
                    autoSelected: 'zoom',
                    show: true, // Ensure the toolbar is enabled
                    tools: {
                        download: true, // Enable the download tool
                    },

                    export: {
                        csv: {
                            filename: 'Popular Games', // Custom file name
                            headerCategory: 'Games', // Custom header name for category (X-axis)
                            columnHeaders: ['Games', 'Users'], // Explicitly set headers for each column

                        },
                        svg: {
                            filename: 'Popular Games', // Custom file name for SVG export
                            width: 800, // Custom width
                            height: 600, // Custom height
                            backgroundColor: '#000000', // Custom background color
                        },
                        png: {
                            filename: 'Popular Games', // Custom file name for PNG export
                            width: 800, // Custom width
                            height: 600, // Custom height
                            backgroundColor: '#000000', // Custom background color
                        }
                    },
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: true,

                labels: {
                    colors: labelColor,  // Conditional color for dark/light theme
                    useSeriesColors: false  // If you want to use the same color as the series
                },
                markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    radius: 12,
                    offsetX: 0,
                    offsetY: 0
                },
                itemMargin: {
                    horizontal: 5,
                    vertical: 0
                },
                onItemClick: {
                    toggleDataSeries: true
                },
                onItemHover: {
                    highlightDataSeries: true
                }
            },
            xaxis: {
                categories: statistics?.popularGame?.map((item) => item.gameName) || [],
                axisBorder: {
                    show: true,
                    color: axisColor,  // Conditional color for dark/light theme
                    height: 2,
                },
                axisTicks: {
                    show: true,
                    color: axisColor,  // Conditional color for dark/light theme
                },
                labels: {
                    style: {
                        colors: labelColor,  // Conditional color for dark/light theme
                    }
                }
            },
            yaxis: {
                axisBorder: {
                    show: true,  // Show the axis line on the x-axis
                    color: axisColor,  // Conditional color for dark/light theme
                },
                axisTicks: {
                    show: true,  // Show the ticks on the x-axis
                    color: axisColor,  // Conditional color for dark/light theme
                },
                labels: {
                    style: {
                        colors: labelColor,  // Conditional color for dark/light theme
                        fontSize: '12px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 400
                    }
                }
            },
            grid: {
                show: true, // Enable grid to allow border customization
                borderColor: axisColor, // Set the color for axis border lines
                strokeDashArray: 0, // Solid border lines
                xaxis: {
                    lines: {
                        show: false // Hide vertical grid lines
                    }
                },
                yaxis: {
                    lines: {
                        show: false // Hide horizontal grid lines
                    }
                },
                padding: {
                    left: 13,
                    right: 10,
                    top: 0,
                    bottom: 0
                }
            },
        }
    };

    const handleDateChange = (range) => {
        setDateRange(range);
    };





    return (
        <React.Fragment>
            <Header />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="/bet-statics">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Games Metrics</li>
                        </ol>
                        <h4 className="main-title mt-2 mb-0">Games Metrics</h4>
                    </div>
                    <div className="d-flex flex-wrap gap-2 mt-3 mt-md-0">
                        <DateFilter onDateChange={handleDateChange} />
                        <AdminCronSettings onSave={(expression) => setCronExpression(expression)} />
                    </div>
                </div>


                {statistics ? <><Row className={`mb-4 ${skin == "dark" && 'customTable'}`}>
                    <Col lg={6} md={12} className="mb-3">
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Games Session
                                </Card.Title>
                                {/* <ReactApexChart options={options} series={options.series} type="area" height={350} /> */}
                                <ReactApexChart options={transactionOverTimeData?.options} series={transactionOverTimeData?.series} type="line" height={350} />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={6} md={12} className="mb-3">
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Popular Games
                                </Card.Title>
                                <ReactApexChart options={avgBetSizeData?.options} series={avgBetSizeData?.series} type="bar" height={350} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row></> : <>
                    <div className="d-flex justify-content-center align-content-center " style={{ height: '60vh' }} >
                        <Loader />
                    </div>
                </>}

                {/* <Footer /> */}
            </div>
        </React.Fragment>
    );
}
