
import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/Header";
import TableWithPagination from "../../common/table";
import useSkinSwitcher from "../../common/skin";
import AuthService from "../../service/api/auth.service";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Row, Col, Nav, ListGroup, Table, Badge, Modal, Form } from "react-bootstrap";
import PageBreadcrumb from "../../common/PageBreadcrum";

import "cryptofont/css/cryptofont.css";
import Sidebar from "../../layouts/Sidebar";
import { toast } from "react-toastify";

import globalSwal from "../../common/swalService";
import PlatformService from "../../service/api/platform-settings.service";
import formatDateTime from "../../helper/DateFormate";


export default function PlatformSettingsList() {
  const platFormService = PlatformService();
  const authService = AuthService();
 
  const navigate = useNavigate();
  const { skin, setSkin } = useSkinSwitcher();
  const [platFormSettingList, setPlatFormSettingList] = useState([]);

  const [pagination, setPagination] = useState({
    page: 1,
    searchBy: "",
    limit: 10,
  });
  const [columns, setColumns] = useState([
    "type",
    "createdAt",
    // "settings",
    "id",
  ]);

  // useEffect(() => {
  //   authService.getLoginData();
    
  // }, []);

  useEffect(() => {
    getList();

  }, [pagination.searchBy, pagination.page]);


  const getList = async () => {
    try {
      const res = await platFormService.getSettingList({
        page: pagination?.page,
        limit: pagination?.limit,
        searchBy: pagination?.searchBy,
      });
      if (res?.code === 200) {
        setPlatFormSettingList(res?.data?.docs);
        setPagination((prevPagination) => ({
          ...prevPagination,
          pages: res.data.pages,
          totalDocs: res.data.totalDocs,
          limit: res.data.limit,
        }));
      } else {
        console.error(res?.message);
        // toast.error(res?.message || "Failed to fetch user list.");
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
      // toast.error("An unexpected error occurred while fetching user list.");
    }
  };

  const getStatusBadge = (status, field) => {
    if(field == "status") {
      if (status === 1) {
        return (
          <Badge bg={"success"} className="badge badge-success">
            Active
          </Badge>
        );
      } else if (status === 0) {
        return (
          <Badge bg={"danger"} className="badge badge-danger">
            Inactive
          </Badge>
        );
      }
    }else {
      if (status) {
        return (
          <Badge bg={"primary"} className="badge badge-success">
            Yes
          </Badge>
        );
      } else if (!status) {
        return (
          <Badge bg={"warning"} className="badge badge-danger">
            No
          </Badge>
        );
      }
    }
    
  };

  const getActionDropdown = (id, status, username) => {
    console.log("Getting action dropdown",id,status,username)
    return (
      <Dropdown>
        <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
          <i className="ri-more-2-fill"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleView(id)}>View</Dropdown.Item>
          <Dropdown.Item onClick={() => handleUpdate(id)}>Edit</Dropdown.Item>
          <Dropdown.Item onClick={() => handleDelete(id)}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleUpdate = (id) => {
    const state = { id: id };
    navigate(`/platform-settings/edit`, { state });
  };

  const handleView = (id) => {
    const state = { id: id };
    navigate(`/platform-settings/view`, { state });
  };

  

  const handleStatusUpdate = async (id, currentStatus) => {
    try {

      globalSwal.confirm({
        title: "Are you sure?",
        text: "Do you really want to change the current status?",
        onConfirm: async() => {
          const res = await platFormService.updateUser(id, {
            status: currentStatus === 1 ? 0 : 1,
          });
          if (res?.code === 200) {
            globalSwal.success({
              title: "Success!",
              text: "Status has been changed successfully.",
            });
            toast.success(res?.message || "Status updated successfully.");
            getList();
          } else {
            toast.error(res?.message || "Failed to update status.");
          }
          
        },
      });
      
    } catch (error) {
      console.error("Error updating status:", error);
      // toast.error("An unexpected error occurred while updating status.");
    }
  };

  const handleDelete = async (id) => {
    try {
     
      globalSwal.confirm({
        title: "Are you sure?",
        text: "Do you really want to delete?",
        onConfirm: async () => {
  
  
          const res = await platFormService.deleteSetting(id);
          if (res?.code === 200) {
            globalSwal.success({
              title: "Success!",
              text: "User has been deleted successfully.",
            });
            toast.success(res?.message);
            getList();
            setPagination({ ...pagination, page: 1 })
          } else {
            toast.error(res?.message || "Failed to delete.");
          }
  
        },
      });
    } catch (error) {
      console.error("Error deleting user:", error);
      // toast.error("An unexpected error occurred while deleting user.");
    }
  };



  
  const useDebounce = (func, delay) => {
    const debounceRef = useRef(null);

    return (...args) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setPagination((prev) => ({ ...prev, searchBy: value, page: 1 }));
  };

  const debouncedHandleSearchChange = useDebounce(handleSearchChange, 300);

 

  const columnsWithHTML = columns.map((column) => {
    if (column.toLowerCase() === "type") {
      return {
        header: "Type",
        accessor: column,
        Cell: ({ value }) => {
          // <option value="cooldownPeriods">Cooldown Periods</option>
          // <option value="betLimits">Bet Limits</option>
          // <option value="withdrawalLimits">Withdrawal Limits</option>
          const typeObj = {
            cooldownPeriods: 'Cooldown Periods',
            betLimits: 'Bet Limits',
            withdrawalLimits:'Withdrawal Limits'
          }
          return (
            <span className="text-nowrap">
              {typeObj[value]}
            </span>
          )
        },
      };
    } else if (column.toLowerCase() === "createdat") {
      return {
        header: "Created Date",
        accessor: column,
        Cell: ({ value }) => {
          // console.log("value", value, column);
          return (
            <span className="text-nowrap">
              {formatDateTime(value, 'gameBets') || "N/A"}
            </span>
          )
        }, // Render action dropdown based on the value
      };
    }
    
    else if (column.toLowerCase() === "id") {
      return {
        header: "Actions",
        accessor: column,
        Cell: ({ value, row }) => getActionDropdown(value, row?.status,row?.username),
      };
    }
    else if (column.toLowerCase() === "settings") {
      return {
        header: "Settings",
        accessor: column,
        Cell: ({ value }) => {
          if (!value || typeof value !== "object") return null;
    
          // Render each key-value pair in settings
          return (
            <div>
              {Object.entries(value).map(([key, val]) => (
                <div key={key}>
                  <strong>{key}:</strong> {val}
                </div>
              ))}
            </div>
          );
        },
      };
    }
    
    else {
      return {
        header: column.charAt(0).toUpperCase() + column.slice(1),
        accessor: column,
        Cell: ({ value }) => {
          return <span className="text-nowrap">
            {value || "N/A"}
          </span>
        },
      };
    }
  });

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <Card>
          <Card.Header>
            <PageBreadcrumb title="Platform Settings List" />
          </Card.Header>
          <Card.Body>
            <TableWithPagination
              data={platFormSettingList}
              itemsPerPage={pagination.limit}
              columns={columnsWithHTML}
              currentPage={pagination.page}
              totalData={pagination.totalDocs}
              pagination={pagination}
              setPagination={setPagination}
              redirectUrl={"/platform-settings/add"}
              handleSearchChange={debouncedHandleSearchChange}
              moduleId={18}
              
            />
          </Card.Body>
        </Card>

      </div>
    </React.Fragment>
  );
}




