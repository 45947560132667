// apiService.js

import axios from "axios";
import { toast } from "react-toastify";
let hasShownToast = false; // Track if toast has already been shown

const createAxiosInstance = (bearerToken,type) => {
  // Create a reusable Axios instance with the provided bearer token
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Base URL for your API
    headers: {
      Authorization: `${type?bearerToken:'Bearer '+bearerToken}`, // Bearer token header
      // Add other common headers here if needed
    },
  });

  // Add response interceptor to handle 401 errors
  instance.interceptors.response.use(
    (response) => {
      console.log(response,"heyyyyyyy 401")
      if (response?.data?.code === 401 && !hasShownToast) {
        // localStorage.clear();
        hasShownToast = true;
        // toast.error("Session expired. Please sign in again.")
        toast.error(response.data.message)

        setTimeout(() => {
          localStorage.clear();
          window.location.href = "/"; // Redirect to sign-in page
        }, 3000); // 3 seconds delay
        return;
      }
      return response;
    },
    (error) => {
      const response=error.response;
      if (response?.data?.code === 401 && !hasShownToast) {
        // localStorage.clear();
        hasShownToast = true;
        // toast.error("Session expired. Please sign in again.")
        toast.error(response.data.message)

        setTimeout(() => {
          localStorage.clear();
          window.location.href = "/"; // Redirect to sign-in page
        }, 3000); // 3 seconds delay
       
      }
      console.log("error api instance", error);
      return error?.response?.data;
      // if (error.response && error.response.status === 401) {
      //   // logout(); // Call logout function on 401 error
      // }
      // return Promise.reject(error);
    }
  );

  return instance;
};

export default createAxiosInstance;



// import axios from "axios";
// import { toast } from "react-toastify";

// let hasShownToast = false; // Track if toast has already been shown

// const handle401Error = (response) => {
//   if (!hasShownToast) {
//     hasShownToast = true;
//     toast.error(response?.data?.message || "Session expired. Please sign in again.");

//     setTimeout(() => {
//       localStorage.clear();
//       window.location.href = "/"; // Redirect to sign-in page
//     }, 3000); // 3 seconds delay
//   }
// };

// const createAxiosInstance = (bearerToken, type) => {
//   // Create a reusable Axios instance
//   const instance = axios.create({
//     baseURL: process.env.REACT_APP_API_URL, // Base URL for your API
//     headers: {
//       Authorization: type ? bearerToken : `Bearer ${bearerToken}`, // Bearer token header
//     },
//   });

//   // Add response interceptor to handle 401 errors
//   instance.interceptors.response.use(
//     (response) => {
//       // Handle custom 401 response code
//       if (response?.data?.code === 401) {
//         handle401Error(response);
//         return Promise.reject(response); // Reject the response to allow chaining
//       }
//       return response;
//     },
//     (error) => {
//       const { response } = error;
//       if (response?.data?.code === 401) {
//         handle401Error(response);
//       }
//       console.error("API error:", error);
//       return Promise.reject(response?.data || error); // Pass the error further
//     }
//   );

//   return instance;
// };

// export default createAxiosInstance;
