import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Nav, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../service/api/auth.service";
import { toast } from "react-toastify";

import pageSvg from "../assets/svg/forgot_password.svg";

export default function ForgotPassword() {
  document.body.classList.remove("sidebar-show");
  const authService = AuthService();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleChange = (e) => {
    const { value } = e.target;
    setEmail(value);
    setEmailError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform email validation
    if (!email) {
      setEmailError("Email is required.");
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email address.");
      return;
    }

    const response = await authService.forgotPassword({email: email});
    console.log("Form submitted:", response);

    if (response.code == 200) {
      toast.success(response.message);
      setEmail("");
      
      // navigate("/dashboard");
    } else {
      console.log("response", response);
      toast.error(response.message);
    }

    // If validation passes, continue with form submission
    console.log("Form submitted:", email);
    // Perform your form submission logic here
  };
  return (
    <div className="page-auth">
      <div className="header">
        <Container>
          <Link to="/" className="header-logo">
          KripaBets
          </Link>
          <Nav className="nav-icon">
            <Nav.Link href="">
              <i className="ri-twitter-fill"></i>
            </Nav.Link>
            <Nav.Link href="">
              <i className="ri-github-fill"></i>
            </Nav.Link>
            <Nav.Link href="">
              <i className="ri-dribbble-line"></i>
            </Nav.Link>
          </Nav>
        </Container>
      </div>

      <div className="content">
        <Container>
          <Card className="card-auth">
            <Card.Body className="text-center">
              <div className="mb-5">
                <object
                  type="image/svg+xml"
                  data={pageSvg}
                  className="w-50"
                  aria-label="svg image"
                ></object>
              </div>
              <Card.Title>Reset your password</Card.Title>
              <Card.Text className="mb-5">
                Enter your username or email address and we will send you a link
                to reset your password.
              </Card.Text>
              <Form onSubmit={handleSubmit}>
                <Row className="g-2">
                  <Col lg="8">
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Enter email address"
                      value={email}
                      name="email"
                      onChange={handleChange}
                    />
                    {emailError && (
                      <div className="text-danger">{emailError}</div>
                    )}
                  </Col>
                  <Col lg="4">
                    {" "}
                    <Button variant="primary" type="submit" className="text-capitalize">
                      Reset
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </div>
  );
}
