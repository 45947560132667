import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CronGenerator from 'react-cron-generator';
// import 'react-cron-generator/dist/cron-builder.css';
import './dateFilter.css'; // Custom styles if needed
import { toast } from 'react-toastify';

const AdminCronSettings = ({ onSave }) => {
    const [cronExpression, setCronExpression] = useState(() => {
        const value = localStorage.getItem('cronExpression');
        return value;
  }); // Default cron expression
  const [showModal, setShowModal] = useState(false);

    const handleCronChange = (expression) => {
    //   console.log(expression)
    setCronExpression(expression);
  };

  const handleApply = () => {
    onSave(cronExpression);
      setShowModal(false);
      toast.success("Schedule updated successfully")
  };

  return (
    <>
      <Button variant="primary" onClick={() => setShowModal(true)}>
        <i className='fa-regular fa-clock me-1'></i> Schedule Automatic Updates
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title><i className='fa-regular fa-clock'></i> Set Automatic Update Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Select the frequency at which you want the system to automatically update the dashboard.</p>
          <CronGenerator
            value={cronExpression}
            onChange={handleCronChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleApply}>
            Save Schedule
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminCronSettings;
