import React, { useCallback, useEffect, useState } from "react";
import { Table, Badge, Card, Form } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import GameService from "../../service/api/game.service";
import "./user.css";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import '../../common/table.css'
import { useSelector } from "react-redux";
import { BorderSpinner } from "../../common/Spinner";
import { toast } from "react-toastify";

export default function GameList() {
  const location = useLocation();
  const categoryId = location?.state?.id || "";
  const gameService = GameService();
  const theme = useSelector((state) => state.ui.skin);
  const [isLoading,setIsLoading]=useState(true)
  const [gameList, setGameList] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalDocs: 0,
    searchBy: "",
  });

  // Fetch game list from API
  const getGameList =useCallback( async () => {
   try {
    const response = await gameService.getGame({
      page: pagination.page,
      limit: pagination.limit,
      searchBy: pagination.searchBy,
      category: categoryId,
    });

    if (response?.code === 200) {
      const games = response?.data?.docs[0]?.games || [];
      setGameList(games);
      setPagination((prev) => ({
        ...prev,
        totalDocs: response?.data?.totalDocs,
      }));
    } else {
      console.error(response?.message || "Failed to fetch games");
    }
   } catch (error) {
     console.error(error);
   } finally {
     setIsLoading(false);
   }
  },[]);

  useEffect(() => {
    getGameList();
  }, []);

  // Toggle status update
  const toggleStatus = async (gameId, currentStatus) => {
    const updatedStatus = currentStatus == 1 ? 0 : 1;
    const payload = {
      games: [
        {
          gameId,
          status: updatedStatus,
        },
      ],
    };
    const response = await gameService.updateGameStatus(payload);

    if (response?.code === 200) {
      toast.success(response.message);
      const updatedGames = response.data.games; // Get the updated games from the response

      setGameList((prevGames) =>
        prevGames.map((game) => {
          const updatedGame = updatedGames.find(
            (updated) => updated.gameId === game.gameId?.id
          );
          return updatedGame ? { ...game, status: updatedGame.status } : game;
        })
      );
      // setGameList((prevGames) =>
      //   prevGames.map((game) =>
      //     game.gameId?.id === gameId ? { ...game, status: updatedStatus } : game
      //   )
      // );
    } else {
      console.error(response?.message || "Failed to update game status");
    }
  };

  return (
    <React.Fragment>
      <Header />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <Card>
          <Card.Header>
            <h4>
             Games
            </h4>
          </Card.Header>
          <Card.Body>
            {isLoading ? <div className="d-flex mt-5 justify-content-center align-content-center">
              <BorderSpinner />
            </div> : (
              <Table
                className={`table-bordered table-centered ${theme === "dark" ? "customTable" : ""
                  } mb-0`}
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Game Name</th>
                    <th>Category</th>
                    <th>Maintenance</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {gameList.map((game, index) => (
                    <tr key={game._id}>
                      <td>{index + 1}</td>
                      <td>{game?.gameId?.name || "N/A"}</td>
                      <td>{game?.categoryId?.name || "N/A"}</td>
                      <td>
                        {game?.gameId?.underMaintenance ? (
                          <Badge bg="warning">Yes</Badge>
                        ) : (
                          <Badge bg="success">No</Badge>
                        )}
                      </td>
                      <td>
                        <Form.Check
                          type="switch"
                          id={`status-toggle-${game._id}`}
                          label=""
                          checked={game?.status == 1}
                          onChange={() => toggleStatus(game?.gameId?.id, game?.status)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>)}
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
