
import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/Header";
import TableWithPagination from "../../common/table";
import useSkinSwitcher from "../../common/skin";
import UserService from "../../service/api/user.service ";
import AuthService from "../../service/api/auth.service";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import "./user.css";
import { Button, Card, Row, Col, Nav, ListGroup, Table, Badge, Modal, Form } from "react-bootstrap";
import PageBreadcrumb from "../../common/PageBreadcrum";

import "cryptofont/css/cryptofont.css";
import Sidebar from "../../layouts/Sidebar";
import { toast } from "react-toastify";
import CurrencyService from "../../service/api/currency.service";
import TransactionService from "../../service/api/transaction.service";
import globalSwal from "../../common/swalService";
import { BorderSpinner } from "../../common/Spinner";
import { useDispatch } from "react-redux";
import { updateUserData } from "../../Redux/Action";
import ResetUserPasswordModal from "./ResetUserPassword";


export default function UserList() {
  const userService = UserService();
  const dispatch = useDispatch();
  const authService = AuthService();
  const transactionService = TransactionService();
  const currencyService = CurrencyService();
  const navigate = useNavigate();
  // const { skin, setSkin } = useSkinSwitcher();
  const [staffList, setStaffList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    searchBy: "",
    limit: 10,
  });

  const [showResetModal, setShowResetModal] = useState({
    show: false,
    id:''
  });
  
  const [columns, setColumns] = useState([
    "name",
    "username",
    "email",
    "phone",
    "status",
    "isEmailVerified",
    "isBettingAllowed",
    "isLoginAllowed",
    "isWithdrawalAllowed",
    "id",
  ]);

  // New state variables for Add Amount Modal
  const [showAddAmountModal, setShowAddAmountModal] = useState(false);
  const [addAmountForm, setAddAmountForm] = useState({
    userId: "",
    currencyId: "",
    amount: "",
  });
  const [withdrawAmountForm, setWithdrawAmountForm] = useState({
    userId: "",
    username: "",
    currencyId: "",
    amount: "",
  });
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {

    if (showAddAmountModal||showWithdrawModal) {
      getCurrencyList();
     }
  }, [showAddAmountModal,showWithdrawModal]);

  useEffect(() => {
    getList();

  }, [pagination.searchBy, pagination.page]);


  const getCurrencyList = async () => {
    try {
      const res = await currencyService.getCurrencyList({
        page: pagination?.page,
        limit: pagination?.limit,
        searchBy: pagination?.searchBy,
      });
      if (res?.code === 200) {
        setCurrencyList(res?.data?.docs);
       
      } else {
        console.error(res?.message);
        // toast.error(res?.message || "Failed to fetch user list.");
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
      // toast.error("An unexpected error occurred while fetching user list.");
    }
  }

  const getUserData = async() => {
      try {
        const res = await authService.getLoginData();
        if (res.code === 200) {
          console.log(res, "hey there");
          dispatch(updateUserData(res?.data));
        }
        else {
          throw new Error(res?.message);
        }
      } catch (error) {
        console.error(error);
      }
  }
  const getList = async () => {
    try {
      const res = await userService.getUser({
        page: pagination?.page,
        limit: pagination?.limit,
        searchBy: pagination?.searchBy,
      });
      if (res?.code === 200) {
        setStaffList(res?.data?.docs);
        setPagination((prevPagination) => ({
          ...prevPagination,
          pages: res.data.pages,
          totalDocs: res.data.totalDocs,
          limit: res.data.limit,
        }));
      } else {
        console.error(res?.message);
        // toast.error(res?.message || "Failed to fetch user list.");
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
      // toast.error("An unexpected error occurred while fetching user list.");
    } finally {
      setIsLoading(false);
    }
  };

  const getStatusBadge = (status, field) => {
    if(field == "status") {
      if (status === 1) {
        return (
          <Badge bg={"success"} className="badge badge-success">
            Active
          </Badge>
        );
      } else if (status === 0) {
        return (
          <Badge bg={"danger"} className="badge badge-danger">
            Inactive
          </Badge>
        );
      }
    }else {
      if (status) {
        return (
          <Badge bg={"primary"} className="badge badge-success">
            Yes
          </Badge>
        );
      } else if (!status) {
        return (
          <Badge bg={"warning"} className="badge badge-danger">
            No
          </Badge>
        );
      }
    }
    
  };

  const getActionDropdown = (id, status, username) => {
    console.log("Getting action dropdown",id,status,username)
    return (
      <Dropdown>
        <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
          <i className="ri-more-2-fill"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleView(id)}>View</Dropdown.Item>
          <Dropdown.Item onClick={() => handleUpdate(id)}>Edit</Dropdown.Item>
          <Dropdown.Item onClick={() => {
            setShowResetModal({
              show: true,
              id:id
            });
          }}>
            Reset Password
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleBankView(id)}>
            View Bank
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleWalletView(id)}>
            View Wallet
          </Dropdown.Item>
          {/* <Dropdown.Item onClick={() => handleBankView(id)}>
            View Bank
          </Dropdown.Item> */}

          <Dropdown.Item onClick={() => handleAddAmount(id,username)}>
            Add Amount
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleWithdrawAmount(id,username)}>
            Withdraw Amount
          </Dropdown.Item>
          
          <Dropdown.Item onClick={() => handleStatusUpdate(id, status)}>
            {status === 1 ? "Deactivate" : "Activate"}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleDelete(id)}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleUpdate = (id) => {
    const state = { id: id };
    navigate(`/user-management/edit`, { state });
  };

  const handleView = (id) => {
    const state = { id: id };
    navigate(`/user-management/view`, { state });
  };

  const handleWalletView = (id) => {
    const state = { id: id };
    navigate("/user-management/view-wallet", { state });
  };

  const handleBankView = (id) => {
    const state = { id: id };
    navigate("/user-management/view-bank", { state });
  };

  const handleStatusUpdate = async (id, currentStatus) => {
    try {

      globalSwal.confirm({
        title: "Are you sure?",
        text: "Do you really want to change the current status?",
        onConfirm: async() => {
          const res = await userService.updateUser(id, {
            status: currentStatus === 1 ? 0 : 1,
          });
          if (res?.code === 200) {
            globalSwal.success({
              title: "Success!",
              text: "Status has been changed successfully.",
            });
            toast.success(res?.message || "Status updated successfully.");
            getList();
          } else {
            toast.error(res?.message || "Failed to update status.");
          }
          
        },
      });
      
    } catch (error) {
      console.error("Error updating status:", error);
      // toast.error("An unexpected error occurred while updating status.");
    }
  };

  const handleDelete = async (id) => {
    try {
     
      globalSwal.confirm({
        title: "Are you sure?",
        text: "Do you really want to delete?",
        onConfirm: async () => {
  
  
          const res = await userService.deleteStaff(id);
          if (res?.code === 200) {
            globalSwal.success({
              title: "Success!",
              text: "User has been deleted successfully.",
            });
            toast.success(res?.message);
            getList();
            setPagination({ ...pagination, page: 1 })
          } else {
            toast.error(res?.message || "Failed to delete.");
          }
  
        },
      });
    } catch (error) {
      console.error("Error deleting user:", error);
      // toast.error("An unexpected error occurred while deleting user.");
    }
  };

  // Handle Add Amount Modal Open
  const handleAddAmount = (id,username) => {
    setAddAmountForm({
      userId: id,
      username:username,
      currencyId: "",
      amount: "",
    });
    setShowAddAmountModal(true);
  };

  // Handle Add Amount Form Change
  const handleAddAmountChange = (e) => {
    const { name, value } = e.target;
    setAddAmountForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  // Handle Add Amount Form Submission
  const handleAddAmountSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const { userId, currencyId, amount } = addAmountForm;

    // Basic validation
    if (!currencyId || !amount) {
      toast.error("Please fill in all required fields.");
      setIsSubmitting(false);
      return;
    }

    try {
      const res = await transactionService.manualAddAmount({
        userId,
        currencyId,
        amount,
      });
      if (res?.code === 200) {
        toast.success(res?.message || "Amount added successfully.");
        setShowAddAmountModal(false);
        getList();
        getUserData();
      } else {
        toast.error(res?.message);
      }
    } catch (error) {
      console.error("Error adding amount:", error);
      // toast.error("An unexpected error occurred while adding amount.");
    } finally {
      setIsSubmitting(false);
    }
  };


  // withdraw amount
 
  const handleWithdrawAmount = (id,username) => {
    setWithdrawAmountForm({
      userId: id,
      username:username,
      currencyId: "",
      amount: "",
    });
    setShowWithdrawModal(true);
  }

  const handleWithdrawAmountChange = (e) => {
    const { name, value } = e.target;
    setWithdrawAmountForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  // Handle Add Amount Form Submission
  const handleWithdrawAmountSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const { userId, currencyId, amount } = withdrawAmountForm;

    // Basic validation
    if (!currencyId || !amount) {
      toast.error("Please fill in all required fields.");
      setIsSubmitting(false);
      return;
    }

    try {
      const res = await transactionService.manualWithdrawAmount({
        userId,
        currencyId,
        amount,
      });
      if (res?.code === 200) {
        toast.success(res?.message || "Amount added successfully.");
        setShowWithdrawModal(false);
        getList();
      } else {
        toast.error(res?.message);
      }
    } catch (error) {
      console.error("Error adding amount:", error);
      // toast.error("An unexpected error occurred while adding amount.");
    } finally {
      setIsSubmitting(false);
    }
  };
















  const useDebounce = (func, delay) => {
    const debounceRef = useRef(null);

    return (...args) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setPagination((prev) => ({ ...prev, searchBy: value, page: 1 }));
  };

  const debouncedHandleSearchChange = useDebounce(handleSearchChange, 300);

  // Currency options (This should ideally come from an API or constants file)
  const currencyOptions = [
    { id: "USD", name: "US Dollar" },
    { id: "EUR", name: "Euro" },
    { id: "GBP", name: "British Pound" },
    // Add more currencies as needed
  ];

  const columnsWithHTML = columns.map((column) => {
    if (column.toLowerCase() === "status") {
      return {
        header: "Status",
        accessor: column,
        Cell: ({ value }) => getStatusBadge(value, "status"),
      };
    } else if (column.toLowerCase() === "isemailverified") {
      return {
        header: "Email Verified",
        accessor: column,
        Cell:( { value, row }) =>getStatusBadge(row.isVerifyEmailOtp, "email"),
      };
    }
      
    else if (column.toLowerCase() === "isbettingallowed") {
      return {
        header: "Bet Allowed",
        accessor: column,
        Cell:( { value, row }) =>getStatusBadge(value, ""),
      };
    }
      
    else if (column.toLowerCase() === "iswithdrawalallowed") {
      return {
        header: "Withdrawal Allowed",
        accessor: column,
        Cell:( { value, row }) =>getStatusBadge(value, ""),
      };
    }
      
    else if (column.toLowerCase() === "isloginallowed") {
      return {
        header: "Login Allowed",
        accessor: column,
        Cell:( { value, row }) =>getStatusBadge(value, ""),
      };
    }
    
    else if (column.toLowerCase() === "id") {
      return {
        header: "Actions",
        accessor: column,
        Cell: ({ value, row }) => getActionDropdown(value, row?.status,row?.username),
      };
    }
    else if (column.toLowerCase() === "username") {
      return {
        header: "Username",
        accessor: column,
        Cell: ({ value }) => value?.toUpperCase() || 'N/A',
      };
    }
    else {
      return {
        header: column.charAt(0).toUpperCase() + column.slice(1),
        accessor: column,
        Cell: ({ value }) => {
          return <span className="text-nowrap">
            {value || "N/A"}
          </span>
        },
      };
    }
  });

  return (
    <React.Fragment>
      <Header  />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <Card>
          <Card.Header>
            <PageBreadcrumb title="Users" />
          </Card.Header>
          <Card.Body>
            {isLoading ? <>
              <div className="d-flex mt-5 justify-content-center align-content-center">
                 <BorderSpinner />
            </div>
            </> : (<TableWithPagination
              data={staffList}
              itemsPerPage={pagination.limit}
              columns={columnsWithHTML}
              currentPage={pagination.page}
              totalData={pagination.totalDocs}
              pagination={pagination}
              setPagination={setPagination}
              handleSearchChange={debouncedHandleSearchChange}
                moduleId={3}
                redirectUrl={'/user-management/add'}
              
            />)}
          </Card.Body>
        </Card>
        {/* Reset Password Modal */}
        <ResetUserPasswordModal
          show={showResetModal}
          onHide={() => setShowResetModal({
            show: false,
            id:''
          })}
          // onSubmit={handlePasswordReset}
        />
        {/* Add Amount Modal */}
        <Modal
          show={showAddAmountModal}
          onHide={() => setShowAddAmountModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Amount</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleAddAmountSubmit}>
            <Modal.Body>
              <Form.Group controlId="formUserId">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  name="userId"
                  value={addAmountForm.username}
                  readOnly
                />
              </Form.Group>

              <Form.Group controlId="formCurrencyId" className="mt-3">
                <Form.Label>Currency</Form.Label>
                <Form.Select
                  name="currencyId"
                  value={addAmountForm.currencyId}
                  onChange={handleAddAmountChange}
                  required
                >
                  <option value="">Select Currency</option>
                  {currencyList.map((currency) => (
                    <option key={currency.id} value={currency.id}>
                      {currency.currencyCodes}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group controlId="formAmount" className="mt-3">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="number"
                  name="amount"
                  value={addAmountForm.amount}
                  onChange={handleAddAmountChange}
                  min="0"
                  max="99999999"
                  step="0.01"
                  placeholder="Enter amount"
                  required
                  onKeyDown={(e) => {
                    // Prevent entering '+' or '-' symbols
                    if (e.key === '+' || e.key === '-') {
                      e.preventDefault();
                    }
                  }
                  }
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowAddAmountModal(false)}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Add Amount"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        {/* Deduct amount model */}
        <Modal
          show={showWithdrawModal}
          onHide={() => setShowWithdrawModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Withdraw Amount</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleWithdrawAmountSubmit}>
            <Modal.Body>
              <Form.Group controlId="formUserId">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  name="userId"
                  value={withdrawAmountForm.username}
                  readOnly
                />
              </Form.Group>

              <Form.Group controlId="formCurrencyId" className="mt-3">
                <Form.Label>Currency</Form.Label>
                <Form.Select
                  name="currencyId"
                  value={withdrawAmountForm.currencyId}
                  onChange={handleWithdrawAmountChange}
                  required
                >
                  <option value="">Select Currency</option>
                  {currencyList.map((currency) => (
                    <option key={currency.id} value={currency.id}>
                      {currency.currencyCodes}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group controlId="formAmount" className="mt-3">
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="number"
                  name="amount"
                  value={withdrawAmountForm.amount}
                  onChange={handleWithdrawAmountChange}
                  min="0"
                  max="99999999"
                  step="0.01"
                  placeholder="Enter amount"
                  required
                  onKeyDown={(e) => {
                    // Prevent entering '+' or '-' symbols
                    if (e.key === '+' || e.key === '-') {
                      e.preventDefault();
                    }
                  }
                  }
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowWithdrawModal(false)}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Withdraw Amount"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </React.Fragment>
  );
}




