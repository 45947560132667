import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import RoleService from "../../service/api/role.service";
import UserService from "../../service/api/user.service ";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { BorderSpinner } from "../../common/Spinner";

const EditNewUser = () => {
  const { skin, setSkin } = useSkinSwitcher();
  const roleService = RoleService();
  const userService = UserService();
  const location = useLocation();
  const { id } = location.state;
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [roleOption, setRoleOption] = useState([]);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    role: "",
    name: "",
    phone: "",
    email: "",
    password: "",
    isBettingAllowed: true,
    isLoginAllowed: true,
    isWithdrawalAllowed: true,
  });

  useEffect(() => {
    getUserDetail();
  }, []);

  const getUserDetail = async () => {
    try {
      const res = await userService.getUserById(id);
      if (res?.code === 200) {
        let data = {
          ...res.data?.userDetail,
          phone: res.data?.userDetail?.phone?.substring(res?.data?.userDetail?.phone?.length - 10)
        };
        setFormData(data);
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    let errorMessage = "";

    // Validation based on field name
    switch (name) {
      case "name":
        errorMessage = !value.trim() ? "Name is required." : /^[a-zA-Z0-9\s]*$/.test(value) ? "" : "Name should be alphanumeric";
        break;
      case "email":
        errorMessage = !value.trim() ? "Email is required." : /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) ? "" : "Invalid email address.";
        break;
      case "phone":
        errorMessage = !value.trim() ? "Phone number is required." : /^\d{10}$/.test(value) ? "" : "Phone number should be 10 digits";
        break;
      default:
        break;
    }

    setFormData({ ...formData, [name]: newValue });
    setErrors({ ...errors, [name]: errorMessage });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};

    // Additional validation
    if (formData.role === "") validationErrors.role = "Role is required";
    if (formData.name.trim() === "") validationErrors.name = "Name is required";
    if (formData.phone === "") validationErrors.phone = "Phone number is required";
    if (formData.email === "") validationErrors.email = "Email is required";

    if (Object.values(validationErrors).every((item) => item === "")) {
      setShowError(false);
      const res = await userService.updateUser(id, formData);
      if (res?.code === 200) {
        toast.success(res?.message);
        navigate("/user-management");
      } else {
        toast.error(res?.message);
      }
    } else {
      setShowError(true);
      setErrors(validationErrors);
    }
  };

  if (isLoading) {
    return (
      <React.Fragment>
        <Header onSkin={setSkin} />
        <Sidebar />
        <div className="main main-app p-3 p-lg-4">
          <div className="d-flex mt-5 justify-content-center align-content-center">
            <BorderSpinner />
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <div className="align-items-center mb-4">
          <Card>
            <Card.Header>
              <h4 className="card-title w-100">Edit User</h4>
            </Card.Header>
            <Card.Body>
              <Form className="row" onSubmit={handleSubmit}>

                <Col md={4} className="mt-3">
                  <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-3">
                  <Form.Group controlId="phone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.phone}
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={4} className="mt-3">
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.email}
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                {/* Switches with justify-between layout */}
                <Col md={6} className="mt-3">
                  <Form.Group controlId="isBettingAllowed" className="d-flex justify-content-between align-items-center">
                    <Form.Label>Betting Allowed</Form.Label>
                    <Form.Switch
                      name="isBettingAllowed"
                      checked={formData.isBettingAllowed}
                      onChange={handleChange}
                    />
                  </Form.Group>

                </Col>

                <Col  md={6} className="mt-3">
                  <Form.Group controlId="isLoginAllowed" className="d-flex justify-content-between align-items-center mt-2">
                    <Form.Label>Login Allowed</Form.Label>
                    <Form.Switch
                      className="form-switch"
                      name="isLoginAllowed"
                      checked={formData.isLoginAllowed}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>





                <Col md={4} className="mt-3">

                  <Form.Group controlId="isWithdrawalAllowed" className="d-flex justify-content-between align-items-center mt-2">
                    <Form.Label>Withdrawal Allowed</Form.Label>
                    <Form.Switch
                      name="isWithdrawalAllowed"
                      checked={formData.isWithdrawalAllowed}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <div className="d-flex justify-content-center mt-5">
                  <Button variant="primary" type="submit" className="mx-4">
                    Submit
                  </Button>
                  <Button
                    variant="danger"
                    type="button"
                    onClick={() => navigate("/user-management")}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditNewUser;
