import moment from "moment/moment";

const formatDateTime = (createdAt, tableType) => {
    const date = new Date(createdAt);

    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    const dateString = `${day}-${month}-${year}`;

    const timeOptions = {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    };

    // if (tableType && (tableType === 'crashHistory' || tableType === 'gameBets')) {
    //     timeOptions.second = "2-digit";
    // }

    const timeString = date.toLocaleTimeString("en-US", timeOptions);

    return `${dateString} ${timeString}`;
};

export default formatDateTime;

export const formatDateTimeWithMoment = (dateTime) => {
    return {
      date: moment(dateTime).format("D/M/YYYY"),
      time: moment(dateTime).format("h:mm:ss A")
    };
  };