import React, { useEffect, useRef, useState, useCallback } from "react";
import Header from "../../layouts/Header";
import { addDays, format } from "date-fns";
import PageBreadcrumb from "../../common/PageBreadcrum";
import { toast } from "react-toastify";

import _ from "lodash";
// import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Row,
  Col,
  Nav,
  ListGroup,
  Table,
  Badge,
} from "react-bootstrap";
import Sidebar from "../../layouts/Sidebar";
import CommonPageEditor from "./Editor";

export default function TermsAndConditions() {
  let domain = JSON.parse(localStorage.getItem('user'))?.domain || ""
  
        return (
          <React.Fragment>
            <Header />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
              <CommonPageEditor
                apiEndpoint={`static-content/page/terms?domain=${domain}`}
                pageTitle="Terms and Conditions"
              />
            </div>
          </React.Fragment>
        );
      }