import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import { Button, Card, Form, Alert, Table } from "react-bootstrap";
import Switch from "react-switch";
import PageBreadcrumb from "../../common/PageBreadcrum";
import ReferralService from "../../service/api/referral.service";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CurrencyService from "../../service/api/currency.service";

export default function ReferralSetting() {
    const referralService = ReferralService();
    const currencyService = CurrencyService();
    const [showForm, setShowForm] = useState(false);
    const [levels, setLevels] = useState(1);
    const [rewards, setRewards] = useState([]);
    const [formData, setFormData] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [levelError, setLevelError] = useState(""); // Error for levels input
    const [rewardErrors, setRewardErrors] = useState([]); // Errors for each reward input
    const [isEnabled, setIsEnabled] = useState(false); // Enable/Disable toggle
    const isLoading = useSelector((state) => state.state.loading);

    // Handle toggle change
    const handleToggle = () => {
        setIsEnabled((prev) => !prev);
    };

    // Validate if the input is a positive integer
    const validatePositiveInteger = (value) => {
        const intValue = parseInt(value, 10);
        return intValue > 0 && Number.isInteger(intValue);
    };

    // Handle Generate button click to show input fields
    const handleGenerate = () => {
        if (Number(levels) > 5) {
            setLevelError("Levels should not exceed 5");
        } else if (validatePositiveInteger(levels)) {
            setShowForm(true);
            let newRewards = Array.from({ length: levels }, (_, i) => ({
                level: i + 1,
                rewardType: "", // Default to "amount"
                rewardValue: "",
                currencyId:""
            }));
            setFormData(newRewards);

            setLevelError(""); // Clear error when valid
        } else {
            setLevelError("Please enter a valid number of levels (positive integer).");
        }
    };

    // Handle form input change for rewards
    const handleChange = (index, field, value) => {
        const newRewardErrors = [...rewardErrors];
        const newRewards = [...formData];

        if (field === "rewardValue") {
            if (validatePositiveInteger(value) || value === "") {
                newRewards[index].rewardValue = value;
                setRewardErrors([]); // Clear the error if input is valid
            } else {
                newRewardErrors[index] = "Please enter a valid reward amount (positive integer).";
                setRewardErrors(newRewardErrors);
            }
        } else if (field === "rewardType") {
            newRewards[index].rewardType = value;
        }
        else if (field === 'currencyId') {
            newRewards[index].currencyId = value;

        }

        setFormData(newRewards);
    };

    // Handle removing a reward
    const removeReward = (index) => {
        const updatedRewards = formData.filter((_, i) => i !== index);
        setFormData(updatedRewards);

    };

    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Validate all reward fields
        const newRewardErrors = formData.map(item =>
            validatePositiveInteger(item.rewardValue) ? "" : "Please enter a valid reward amount."
        );
        setRewardErrors(newRewardErrors);

        // If any errors, do not submit
        if (newRewardErrors.some(error => error !== "")) return;

        try {
            const response = await referralService.updateReferralSettings({ multiLevelSettings: { levels, rewards: formData } });
            if (response.code === 200) {
                toast.success(response.message);
                getSettings();
            } else {
                throw new Error("Failed to update settings");
            }
        } catch (err) {
            console.error(err.message);
        }
    };


    const getCurrencyList = async () => {
        try {
            const res = await currencyService.getCurrencyList({
                page: 1,
                limit: 10,
                searchBy: "",
            });
            if (res?.code === 200) {
                setCurrencyList(res?.data?.docs);

            } else {
                console.error(res?.message);
                // toast.error(res?.message || "Failed to fetch user list.");
            }
        } catch (error) {
            console.error("Error fetching user list:", error);
            // toast.error("An unexpected error occurred while fetching user list.");
        }
    }

    const getSettings = async () => {
        try {
            const response = await referralService.getReferralSettings();
            if (response.code === 200) {
                const { levels, rewards } = response.data.multiLevelSettings;
                setLevels(levels);
                setRewards(rewards);
                setIsEnabled(response.data.isEnabled);
            } else {
                throw new Error(response.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {

        getCurrencyList();
        getSettings();
    }, []);

    return (
        <React.Fragment>
            <Header />
            <Sidebar />
            <div className="main main-app p-3  p-lg-4">
                <Card>
                    <Card.Header>
                        <PageBreadcrumb title="Referral" />
                    </Card.Header>
                    <Card.Body className="border-1 rounded-2 pb-4 m-2 mx-auto shadow-lg col-sm-8 col-md-7 col-lg-6">
                        <div className="d-flex rounded-2 p-2 justify-content-between align-items-center gap-2 mb-3">
                            <h5 className="mb-0">Deposit Referral Commission</h5>
                            <div className="d-flex bg-primary px-2 py-1 rounded-3 gap-2 align-items-center">
                                <Switch
                                    height={25}
                                    checked={isEnabled}
                                    onChange={handleToggle}
                                    onColor="#22c55e"
                                    offColor="#ccc"
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                />
                                <span className="ml-2 text-white">
                                    {isEnabled ? "Disable Now" : "Enable Now"}
                                </span>
                            </div>
                        </div>

                        <div className="reward-levels mx-auto mb-4">
                            <Table  striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Level</th>
                                        <th>Reward Type</th>
                                        <th>Reward Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rewards.map((reward, index) => (
                                        <tr key={index}>
                                            <td>Level {reward.level}</td>
                                            <td>{reward.rewardType}</td>
                                            <td>{reward.rewardValue}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>

                        <div className="d-flex align-items-center justify-content-center">
                            <hr style={{ flex: 1 }} />
                            <h5 className="mx-3 mb-3">Update Setting</h5>
                            <hr style={{ flex: 1 }} />
                        </div>

                        <div className="d-flex justify-content-between align-items-center mt-4 mb-3">
                            <Form.Group controlId="levels" className="w-100">
                                <Form.Label className="mb-2">Number of Levels</Form.Label>
                                <div className="d-flex">
                                    <Form.Control
                                        type="number"
                                        min="1"
                                        max="5"
                                        name="levels"
                                        value={levels}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (validatePositiveInteger(value) || value === "") {
                                                setLevels(value);
                                            }
                                        }}
                                        isInvalid={!!levelError}
                                        className="w-100"
                                        style={{ borderRadius: '4px 0px 0px 4px', }}
                                    />
                                    <Button
                                        className="btn btn-primary"
                                        onClick={handleGenerate}
                                        style={{ borderRadius: '0 4px 4px 0', }}

                                    >
                                        Generate
                                    </Button>
                                </div>
                                <Form.Control.Feedback style={{ display: `${levelError && 'block'}` }} type="invalid">
                                    {levelError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>

                        {showForm && (
                            <>
                                <Alert variant="danger" className="mt-3">
                                    The old setting will be removed after generating new
                                </Alert>
                                <Form onSubmit={handleSubmit}>
                                    {formData.map((item, index) => (
                                        <div key={index} className="d-flex gap-2 justify-content-between align-items-center mt-4 mb-3">
                                            <Form.Group controlId={`reward-type-${index}`} className="w-50 mr-2">
                                                <Form.Label>Reward Type</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={item.rewardType}
                                                    onChange={(e) => handleChange(index, "rewardType", e.target.value)}
                                                >  
                                                    <option  value="">Select Type</option>
                                                    
                                                    <option value="amount">Flat</option>
                                                    <option value="percentage">Percentage</option>
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group controlId={`currencyId-${index}`} className="w-50 mr-2">
                                                <Form.Label>Currency Type</Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={item.currencyId}
                                                    onChange={(e) => handleChange(index, "currencyId", e.target.value)}
                                                >
                                                            <option  value="">Select Currency</option>

                                                    {currencyList?.map((item, index) => {
                                                        console.log(item,"currency============")
                                                        return <>
                                                            <option key={index} value={item.id}>{item.currencyCodes}</option>
                                                        </>
                                                    })}
                                                </Form.Control>
                                            </Form.Group>
                                            <Form.Group controlId={`reward-${index}`} className="w-100">
                                                <Form.Label className="mb-2">Level {item.level}</Form.Label>
                                                <div className="d-flex">
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Reward Value"
                                                        value={item.rewardValue}
                                                        onChange={(e) => handleChange(index, "rewardValue", e.target.value)}
                                                        isInvalid={!!rewardErrors[index]}
                                                        className="w-100"
                                                        style={{ borderRadius: '4px 0 0 4px', }}

                                                    />
                                                    <Button
                                                        className="btn btn-danger"
                                                        onClick={() => removeReward(index)}
                                                        style={{ borderRadius: '0 4px 4px 0', }}

                                                    >
                                                        X
                                                    </Button>
                                                </div>
                                                <Form.Control.Feedback style={{ display: `${rewardErrors[index] ? 'block' : 'none'}` }} type="invalid">
                                                    {rewardErrors[index]}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    ))}

                                    <Button className="btn btn-success" type="submit">
                                        {isLoading ? "Saving..." : "Save Settings"}
                                    </Button>
                                </Form>
                            </>
                        )}
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    );
}
