import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Form,
  Alert
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import AuthService from '../../service/api/auth.service';
import Header from '../../layouts/Header';
import Sidebar from '../../layouts/Sidebar';
import { BorderSpinner } from '../../common/Spinner';
import UserService from '../../service/api/user.service ';
// const emailRegex = new RegExp(/^([a-zA-Z0-9.]+)@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/);
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
const emailRegex = new RegExp(
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
);
const nameRegex = /^[a-zA-Z0-9\s]*$/;

export default function AddUser() {
  const dispatch = useDispatch();
  const authService = AuthService();
  const navigate = useNavigate();
  const userService = UserService();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const theme = useSelector((state) => state.ui.skin);
  const [imagePreview, setImagePreview] = useState(null);
  const [faviconPreview, setFaviconPreview] = useState(null);
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  // Form state
  const [formData, setFormData] = useState({
    name: "",
    password: "",
    confirmPassword: "",
    email: "",
    phone: "",
  });



  const togglePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    setErrors({ ...errors, [name]: "" });
  };
  // const handleInputChange = (e) => {
  //     const { name, value } = e.target;


  //     setFormData({ ...formData, [name]: value });
  //     // showError(false)
  //     setErrors({ ...errors, [name]: "" });
  //   };


  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // Required fields validation
    const requiredFields = ['name', 'email', 'phone', 'password', 'confirmPassword'];
    requiredFields.forEach(field => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
        isValid = false;
      }
    });

    // Name validation
    // console.log(formData,formData?.name?.trim(),"formData")
    if (formData?.name?.trim()=='') {
      newErrors.name = 'Name is required';
      isValid = false;
    }
    else if (!nameRegex.test(formData?.name)) {
      newErrors.name = 'Name should be alphanumeric';
      isValid = false;
    }

    // Email validation
    if (formData.email && !emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
      isValid = false;
    }

    // Phone number validation
    if (formData.phone && !/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = 'Please enter a valid 10-digit phone number';
      isValid = false;
    }
    if (!formData.password) {
      newErrors.password = 'Password is required';
      isValid = false;
    } else if (!passwordRegex.test(formData.password)) {
      newErrors.password = 'Password must be at least 8 characters long and include uppercase, lowercase, number, and special character';
      isValid = false;
    }
    // Password validation
    if (!formData.confirmPassword) {
      newErrors.confirmPassword = 'Confirm password is required';
      isValid = false;
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
      isValid = false;
    }

    setErrors(newErrors);
    setShowError(true);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowError(true);
    if (!validateForm()) return;
    setShowError(false);
    setSaving(true);
    try {
      const formDataToSend = new FormData();

      // Append all form fields
      Object.keys(formData).forEach(key => {
        if (formData[key] !== null && key !== 'confirmPassword') {
          if (formData[key] === '' || formData[key] === null) {
            return;
          }
          formDataToSend.append(key, formData[key]);
        }
      });
      const payload = { ...formData };
      delete payload.confirmPassword;
      const response = await userService.createUser(payload);
      if (response.code === 200) {
        toast.success(response.message);
        navigate('/user-management');
      } else {
        toast.error(response.message || 'Failed to update profile');
      }
    } catch (error) {
      setMessage({ type: 'danger', text: 'An error occurred while updating profile' });
    } finally {
      setSaving(false);
    }
  };

  return (
    <React.Fragment>
      <Header />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <Container className="align-items-center mb-4">
          {/* <Row>
            <Col>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to='/'>Dashboard</Link>
                </BreadcrumbItem>
                
                <BreadcrumbItem active>Add New User</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row> */}

          {loading ? (
            <div className="d-flex mt-5 justify-content-center align-content-center">
              <BorderSpinner />
            </div>
          ) : (
            <>


              <>
                <Card className="mb-4">

                  <Card.Header>
                    <h4 className="card-title w-100">Add New User</h4>
                  </Card.Header>
                  <Card.Body>

                    <Form onSubmit={handleSubmit}>


                      <Row className="mb-3">

                        <Col md={4} className="mt-3">
                          <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter name"
                              name="name"
                              value={formData.name}
                              onChange={handleInputChange}
                              isInvalid={showError && !!errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.name}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md={4} className="mt-3">
                          <Form.Group controlId="phone">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter phone number"
                              name="phone"
                              value={formData.phone}
                              onChange={handleInputChange}
                              isInvalid={showError && !!errors.phone}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.phone}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        <Col md={4} className="mt-3">
                          <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter email"
                              name="email"
                              value={formData.email}
                              onChange={handleInputChange}
                              isInvalid={showError && !!errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>

                        <Col md={4} className="mt-3">
                          <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <div style={{ position: "relative" }}>
                              <Form.Control
                                type={showPassword.password ? "text" : "password"}
                                placeholder="Enter password"
                                name="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                isInvalid={showError && !!errors.password}

                              />
                              <span
                                onClick={() => togglePasswordVisibility("password")}
                                style={{
                                  position: "absolute",
                                  top: "18px",
                                  right: "10px",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                  color: "#6c757d",
                                  zIndex: 100
                                }}
                              >
                                {showPassword.password ? <VscEyeClosed size={20} /> : <VscEye size={20} />}
                              </span>
                              <Form.Control.Feedback type="invalid">
                                {errors.password}
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col md={4} className="mt-3">
                          <Form.Group controlId="confirmPassword">
                            <Form.Label>Confirm Password</Form.Label>
                            <div style={{ position: "relative" }}>
                              <Form.Control
                                type={showPassword.confirmPassword ? "text" : "password"}
                                placeholder="Enter Confirm Password"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleInputChange}
                                isInvalid={showError && !!errors.confirmPassword}
                              />
                              <span
                                onClick={() => togglePasswordVisibility("confirmPassword")}
                                style={{
                                  position: "absolute",
                                  top: "18px",
                                  right: "10px",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                  color: "#6c757d",
                                  zIndex: 10000
                                }}
                              >
                                {showPassword.confirmPassword ? <VscEyeClosed size={20} /> : <VscEye size={20} />}
                              </span>

                              <Form.Control.Feedback type="invalid">
                                {errors.confirmPassword}
                              </Form.Control.Feedback>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-center mt-5">
                        <Link to="/user-management" className="btn btn-danger me-2">Cancel</Link>
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={saving}
                        >
                          {saving ? 'Please Wait...' : 'Submit'}
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}