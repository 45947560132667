import { ReactNode } from 'react'
import { Breadcrumb, Col, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import './table.css';
import { useSelector } from 'react-redux';
// interface PageTitleProps {
// 	subName?: string
// 	title: string
// 	addedChild?: ReactNode
// }
const PageBreadcrumb = ({ subName, title, addedChild }) => {
	const user = useSelector(state => state.user.user);
	return (
		<>
			<Helmet>
				<title>
					{title} | {user.name} Admin
				</title>
			</Helmet>
			{subName ? (
				<Row>
					<Col xs={12}>
						<div className="page-title-box  text-dark">
							<div className="page-title-right">
								<ol className="breadcrumb m-0">
									<Link
										to="/"
										style={{ color: '#6C757D' }}
										className="breadcrumb-item"
									>
										Dashboard
									</Link>
									<Breadcrumb.Item  >{subName}</Breadcrumb.Item>
									<Breadcrumb.Item active>{title}</Breadcrumb.Item>
								</ol>
							</div>
							{/* <h4 className="page-title">{title}</h4>
							{addedChild} */}
						</div>
					</Col>
				</Row>
			) :
				<div className="page-title-box text-dark">
					<div className="page-title-right">

						<h4 className="page-title">{title}</h4>
					</div>
				</div>}
		</>
	)
}

export default PageBreadcrumb
