import { Convert } from "easy-currencies";


export const formatCurrency = (amount, currencyCode) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: amount % 1 === 0 ? 0 : 2, // No decimal if it's a whole number
      maximumFractionDigits: 2,
    }).format(amount);
};
  
export const getCurrencyFromTO = async (from, to, value) => {
  try {
    console.log('%c conversion','background:green;color:white;font-size:20px',value,from,to)
    const result = await Convert(value).from(from).to(to);
    return result; // Return the result after the conversion is complete
  } catch (error) {
    console.error('Error during currency conversion:', error); // Handle any errors
    throw error; // Re-throw the error to handle it in the calling code
  }
};