import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { InfoCircle } from 'react-bootstrap-icons'

const InfoComponent = ({message}) => {
    return (
        <>
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id="tooltip-next-draw">
                        {message}
                    </Tooltip>
                }
            >
                <InfoCircle className="ms-2" style={{ cursor: "pointer" }} />
            </OverlayTrigger>
        </>
    )
}

export default InfoComponent