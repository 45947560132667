import React from 'react';
// import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import Chart from 'react-apexcharts';
import { Card, Col, Row } from 'react-bootstrap';
import './dateFilter.css'


const SimplifiedLotteryGraphs = ({ skin, data }) => {
    // Monthly betting data
    const axisColor = skin == "dark" ? 'rgba(255, 255, 255, 0.6)' : 'rgb(224, 224, 224)';
    const labelColor = skin == "dark" ? 'rgba(255, 255, 255, 0.6)' : '#41505f';
    
    console.log(data,"data============")
    
    // Formatting monthly betting trends
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthlyData = data?.monthlyBettingTrends?.map(item => ({
        name: monthNames[item.month - 1],
        bets: item.totalBets
    })) || [];

    // Formatting lottery type distribution
    const lotteryTypes = data?.lotteryTypeDistribution?.map(item => ({
        name: item.type.charAt(0).toUpperCase() + item.type.slice(1),
        value: Math.round(item.percentage) // rounding to nearest integer
    })) || [];


    const monthlyRevenue = data?.formattedRevenue?.map(item => ({
        name: monthNames[item?.month - 1],
        total: item?.totalRevenueInINR
    })) || [];
   

    

    // Lottery Type Distribution (Pie Chart) options
    const pieChartOptions = {
        chart: { type: 'pie' },
        
        labels: lotteryTypes.map(type => type.name),
        colors: ['#0088FE', '#00C49F', '#FFBB28'],
        legend: {
            position: 'bottom',
            colors: skin === 'dark' ? 'rgba(255, 255, 255, 0.6)' : '#333', // Gray white in dark mode, dark in light mode
        },
        responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
    };
    const pieChartSeries = lotteryTypes.map(type => type.value);

    // Monthly Revenue (Bar Chart) options
    // const barChartOptions = {
    //     chart: { type: 'bar', height: 300 },
    //     xaxis: { categories: monthlyData.map(data => data.name) },
    //     tooltip: { x: { show: true } },
    // };
    // const barChartSeries = [{ name: 'Number of Bets', data: monthlyData.map(data => data.bets) }];
    const MonthlyBettingTrendsLineChartOption = {
        series: [{ name: 'Number of Bets', data: monthlyData?.map(data => data.bets) }],
        chart: {
            type: 'line',
            height: 350,
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true,
            },
            toolbar: {
                show: true,
                tools: {
                    download: true,
                },
                export: {
                    csv: {
                        filename: 'Monthly Revenue Data', // Custom filename for CSV export
                        columnDelimiter: ',', // Customize CSV column delimiter if needed
                        headerCategory: 'Month', // Custom header name for category (X-axis)
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toLocaleString();
                        },
                    },
                    svg: {
                        filename: 'Monthly Revenue Chart',
                        width: 800,
                        height: 600,
                        backgroundColor: '#000000',
                    },
                    png: {
                        filename: 'Monthly Revenue Chart',
                        width: 800,
                        height: 600,
                        backgroundColor: '#000000',
                    },
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            // type: 'gradient',
            // gradient: {
            //     shadeIntensity: 1,
            //     inverseColors: false,
            //     opacityFrom: 0.5,
            //     opacityTo: 0,
            //     stops: [0, 90, 100],
            // },
        },
        xaxis: {
            categories: monthlyData?.map(data => data.name),
            axisBorder: {
                show: true,
                color: axisColor,  // Conditional color for dark/light theme
            },
            axisTicks: {
                show: true,
                color: axisColor,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                },
            },
        },
        yaxis: {
            title: {
                text: 'Number of Bets',
                style: {
                    color: labelColor,
                },
            },
            axisBorder: {
                show: true,
                color: axisColor,  // Conditional color for dark/light theme
            },
            axisTicks: {
                show: true,
                color: axisColor,
            },
            labels: {
                formatter: (val) => val.toFixed(0),
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                },
            },
        },
        tooltip: {
            theme: skin === 'dark' ? 'dark' : 'light',
            y: {
                formatter: (val) => val.toFixed(0),
            },
        },
        grid: {
            borderColor: axisColor,
            xaxis: {
                lines: { show: false },
            },
            yaxis: {
                lines: { show: false },
            },
            padding: {
                left: 13,
                right: 10,
                top: 0,
                bottom: 0
            }
        },
    };


    const MonthlyRevenueBarChartOption = {
        series: [{ name: 'Total Revenue', data: monthlyRevenue?.map(data => data.total) }],
        chart: {
            type: 'bar',
            height: 350,
            zoom: {
                enabled: true,
                type: 'x',
                autoScaleYaxis: true,
            },
            toolbar: {
                show: true,
                tools: {
                    download: true,
                },
                export: {
                    csv: {
                        filename: 'Monthly Revenue Data', // Custom filename for CSV export
                        columnDelimiter: ',', // Customize CSV column delimiter if needed
                        headerCategory: 'Month', // Custom header name for category (X-axis)
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toLocaleString();
                        },
                    },
                    svg: {
                        filename: 'Monthly Revenue Chart',
                        width: 800,
                        height: 600,
                        backgroundColor: '#000000',
                    },
                    png: {
                        filename: 'Monthly Revenue Chart',
                        width: 800,
                        height: 600,
                        backgroundColor: '#000000',
                    },
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            // type: 'gradient',
            // gradient: {
            //     shadeIntensity: 1,
            //     inverseColors: false,
            //     opacityFrom: 0.5,
            //     opacityTo: 0,
            //     stops: [0, 90, 100],
            // },
        },
        xaxis: {
            categories: monthlyRevenue?.map(data => data.name),
            axisBorder: {
                show: true,
                color: axisColor,  // Conditional color for dark/light theme
            },
            axisTicks: {
                show: true,
                color: axisColor,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                },
            },
        },
        yaxis: {
            title: {
                text: 'Revenue (in INR)',
                style: {
                    color: labelColor,
                },
            },
            axisBorder: {
                show: true,
                color: axisColor,  // Conditional color for dark/light theme
            },
            axisTicks: {
                show: true,
                color: axisColor,  // Conditional color for dark/light theme
            },
            labels: {
                formatter: (val) => val.toFixed(0),
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                },
            },
        },
        tooltip: {
            theme: skin === 'dark' ? 'dark' : 'light',
            y: {
                formatter: (val) => val.toFixed(0),
            },
        },
        grid: {
            borderColor: axisColor,
            xaxis: {
                lines: { show: false },
            },
            yaxis: {
                lines: { show: false },
            },
            padding: {
                left: 13,
                right: 10,
                top: 0,
                bottom: 0
            }
        },
    };

    return (
        <div className="">
            {/* Monthly Bets Trend */}

            <Row className={`mb-4 ${skin == "dark" && 'customTable'}`}>
                <Col className="mb-3">
                    <Card>
                        <Card.Header>
                            <Card.Title>Monthly Betting Trends</Card.Title>
                        </Card.Header>
                        <Card.Body >
                            <Chart options={MonthlyBettingTrendsLineChartOption} series={MonthlyBettingTrendsLineChartOption.series} type="line" height={350} />
                            {/* <div className="h-75 w-full">
                            </div> */}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>


            {/* Lottery Type Distribution */}
            <Row className={`mb-4 ${skin == "dark" && 'customTable'}`}>
                <Col lg={6} md={12} className="mb-3">
                    <Card>
                        <Card.Header>
                            <Card.Title>Lottery Type Distribution (%)</Card.Title>
                        </Card.Header>
                        <Card.Body >
                            <Chart options={pieChartOptions} series={pieChartSeries} type="pie" height={350} />
                            {/* <div className="h-75 w-full">
                            </div> */}
                        </Card.Body>
                    </Card>
                </Col>
                {/* Revenue Bar Chart */}
                <Col lg={6} md={12} className="mb-3">

                    <Card>
                        <Card.Header>
                            <Card.Title>Monthly Revenue</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Chart options={MonthlyRevenueBarChartOption} series={MonthlyRevenueBarChartOption.series} type="bar" height={350} />
                            {/* <div className="h-75 w-full">?.
                            </div> */}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default SimplifiedLotteryGraphs;
