import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Form, Button, Toast } from "react-bootstrap";
import AuthService from "../service/api/auth.service";
import bg1 from "../assets/img/bg1.jpg";
import { toast } from "react-toastify";
export default function Signup2() {
  const authService = AuthService();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
    name: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    console.log("name", value);
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" }); // Clear error when input changes
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Reset all previous errors
    setFormErrors({
      email: "",
      password: "",
      name: "",
    });

    // Regular expressions for email format and password combination
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = new RegExp(/^([a-zA-Z0-9.]+)@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/);

    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/; // At least 8 characters, one letter, and one number

    // Perform form validations
    const errors = {};
    if (!formData.email) {
      errors.email = "Please enter your email.";
    } else if (!emailRegex.test(formData.email)) {
      errors.email = "Please enter a valid email address.";
    }
    if (!formData.password) {
      errors.password = "Please enter your password.";
    } else if (!passwordRegex.test(formData.password)) {
      console.log("formData.password",formData.password)
      errors.password =
        "Password must be at least 8 characters long and contain at least one letter and one number.";
    }
    if (!formData.name) {
      errors.name = "Please enter your full name.";
    } else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
      errors.name =
        "Please enter only alphabetical characters for your full name.";
    }

    // If any validation fails, set errors and return
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    } else {
      const response = await authService.register(formData);
      console.log("Form submitted:", response);

      if (response.code == 200) {
        toast.success(response.message);
        
        navigate("/signin");
      } else {
        console.log("response", response);
        toast.error(response.message);
      }
    }

    // If all validations pass, submit the form
  };

  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="5" xl="4" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <Link to="/" className="header-logo mb-5">
                Kripa
              </Link>
              <Card.Title>Sign Up</Card.Title>
              <Card.Text>
                It's free to signup and only takes a minute.
              </Card.Text>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter your email address"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {formErrors.email && (
                    <div className="text-danger">{formErrors.email}</div>
                  )}
                </div>
                <div className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Enter your password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  {formErrors.password && (
                    <div className="text-danger">{formErrors.password}</div>
                  )}
                </div>
                <div className="mb-3">
                  <Form.Label>Full name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter your full name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  {formErrors.name && (
                    <div className="text-danger">{formErrors.name}</div>
                  )}
                </div>

                <div className="mb-4">
                  <small>
                    By clicking <strong>Create Account</strong> below, you agree
                    to our terms of service and privacy statement.
                  </small>
                </div>
                <Button type="submit" variant="primary" className="btn-sign">
                  Create Account
                </Button>

                {/* <div className="divider">
                  <span>or sign up using</span>
                </div>

                <Row className="gx-2">
                  <Col>
                    <Button variant="" className="btn-facebook">
                      <i className="ri-facebook-fill"></i> Facebook
                    </Button>
                  </Col>
                  <Col>
                    <Button variant="" className="btn-google">
                      <i className="ri-google-fill"></i> Google
                    </Button>
                  </Col>
                </Row> */}
              </Form>
            </Card.Body>
            <Card.Footer>
              Already have an account? <Link to="/signin">Sign In</Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col className="d-none d-lg-block">
          <img src={bg1} className="auth-img" alt="" />
        </Col>
      </Row>
    </div>
  );
}
