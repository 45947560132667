const getAdminData = () => {
  const user = localStorage.getItem("user")
  if (user) {
    return JSON.parse(localStorage.getItem("user"))
  } else {
    return {}
  }
  // return JSON.parse(localStorage.getItem("user") )
}
const checkAccess = (moduleId, type) => {
  var data = JSON.parse(localStorage.getItem("user")).role;
  console.log("data", data)
  var loginDetails = getAdminData();
  // console.log('data-----===>>122==', data)
  if (data && loginDetails.type != 'superadmin') {
    for (let i = 0; i < data.resource.length; i++) {
      // console.log("moduleId", moduleId)
      // console.log("moduleId", moduleId)
      if (data.resource[i].moduleId == moduleId) {
        return data.resource[i].permissions.includes(type) ? true : false
      }
    }
  } else {
    return true;
  }
}

const dashboardMenu = [
  {
    "label": "Dashboard",
    // "link": "/transaction-history",
    "icon": "ri-suitcase-2-line",
    "moduleId": 9,
    "submenu": [
      {
        "label": "Bet Statics",
        "link": "/bet-statics",
        "moduleId": 9
      },
      {
        "label": "Financial Metrics",
        "link": "/financial-metrics",
        "moduleId": 9
      },
      {
        "label": "User Metrics",
        "link": "/user-metrics",
        "moduleId": 9
      },
      {
        "label": "Game Metrics",
        "link": "/game-metrics",
        "moduleId": 4
      },
      {
        "label": "Lottery Metrics",
        "link": "/lottery-metrics",
        "moduleId": 17
      }
    ]
  },
  // {
  //   "label": "Dashboard",
  //   "link": "/dashboard",
  //   "icon": "ri-suitcase-2-line"
  // },
  // {
  //   "label": "Role & Permission",
  //   "link": "/role-management",
  //   "icon": "ri-pie-chart-2-line",
  //   "moduleId": 1
  // },
  // {
  //   "label": "Staffs",
  //   "link": "/staff-management",
  //   "icon": "ri-calendar-todo-line",
  //   "moduleId": 2
  // },
  // class="ri-account-circle-line"
  {
    "label": "Users",
    "link": "/user-management",
    "icon": "ri-account-circle-line",
    "moduleId": 3
  },

  // class="ri-pencil-ruler-2-line"
  // {
  //   "label": "Games Category",
  //   "link": "/game-management-category",
  //   "icon": "ri-pencil-ruler-2-line",
  //   "moduleId": 14
  // },
  {
    "label": "Games",
    "link": "/game-management",
    "icon": "ri-pencil-ruler-2-line",
    "moduleId": 4
  },
  // {
  //   "label": "Merchants",
  //   "link": "/merchant-management",
  //   "icon": "ri-shopping-bag-3-fill",
  //   "moduleId": 5
  // },
  {
    "label": "Transactions",
    // "link": "/transaction-history",
    "icon": "ri-bar-chart-2-line",
    "moduleId": 6,
    "submenu": [
      {
        "label": "Transactions",
        "link": "/transaction-history",
        "moduleId": 6
      },
      {
        "label": "Payin Transactions",
        "link": "/pay-in/transaction",
        "moduleId": 6
      },
      {
        "label": "Payout Transactions",
        "link": "/pay-out/transaction",
        "moduleId": 6
      },
    ]
  },

  {
    "label": "Game Record",
    "link": "/bet-history",
    "icon": "ri-list-check-2",
    "moduleId": 7,
    "submenu": [
      {
        "label": "Bets",
        "link": "/bet-history",
        "moduleId": 7,
      },
      {
        "label": "History",
        "link": "/crash-history",
        "moduleId": 7,
      }
    ]
  },
  {
    "label": "Game Settings ",
    "link": "/game-setting",
    "icon": "ri-hard-drive-2-line",
    "moduleId": 8
  },


  {
    "label": "Static Pages",
    // "link": "/transaction-history",
    "icon": "ri-service-line",
    "moduleId": 15,
    "submenu": [
      {
        "label": "Privacy Policy",
        "link": "/privacy-policy",
        "moduleId": 15
      },
      {
        "label": "Terms & Condtions",
        "link": "/terms-and-conditions",
        "moduleId": 15
      },
      {
        "label": "FAQ's",
        "link": "/faq",
        "moduleId": 15
      },
      {
        "label": "About Us",
        "link": "/about-us",
        "moduleId": 15
      },
    ]
  },
  // {
  //   "label": "Banners ",
  //   "link": "/banner",
  //   "icon": "ri-hard-drive-2-line",
  //   "moduleId": 10
  // },

  {
    "label": "Sports Management",
    // "link": "/transaction-history",
    "icon": "ri-service-line",
    "moduleId": 11,
    "submenu": [
      {
        "label": "Cricket",
        "link": "/cricket-sports-history",
        "moduleId": 11
      },

    ]
  },

  // cricket-sports-betList
  {
    "label": "Sports Bet",
    // "link": "/transaction-history",
    "icon": "ri-service-line",
    "moduleId": 12,
    "submenu": [
      {
        "label": "Cricket Bet List",
        "link": "/cricket-sports-betList",
        "moduleId": 12
      },

    ]
  },

  {
    "label": "Lottery",
    // "link": "/transaction-history",
    "icon": "ri-shopping-bag-3-line",
    "moduleId": 17,
    "submenu": [
      {
        "label": "Lottery List",
        "link": "/lottery-list",
        "moduleId": 17
      },
      {
        "label": "Ticket List",
        "link": "/ticket-list",
        "moduleId": 17
      },

    ]
  },
  // referral-setting

  // {
  //   "label": "Referral",
  //   // "link": "/transaction-history",
  //   "icon": "ri-shopping-bag-3-line",
  //   "moduleId": 13,
  //   "submenu": [
  //     {
  //       "label": "Referral Settings",
  //       "link": "/referral-setting",
  //       "moduleId": 13
  //     },

  //   ]
  // },

  {
    "label": "Currency",
    "link": "/currency",
    "icon": "ri-coin-line",
    "moduleId": 16
  },
  {
    "label": "Platform Settings",
    "link": "/platform-settings/list",
    "icon": "ri-coin-line",
    "moduleId": 18 
  },

  {
    "label": "IP Whitelisting",
    "link": "/ip/list",
    "icon": "ri-coin-line",
    "moduleId": 19 
  },
  {
    "label": "Exposure-Management",
    "link": "/exposure-management",
    "icon": "ri-coin-line",
    "moduleId": 20
  }



  
  // {
  //   "label": "Sales Monitoring",
  //   "link": "/dashboard/events",
  //   "icon": "ri-shopping-bag-3-line"
  // },
  // {
  //   "label": "Website Analytics",
  //   "link": "/dashboard/analytics",
  //   "icon": "ri-bar-chart-2-line"
  // },
  // {
  //   "label": "Cryptocurrency",
  //   "link": "/dashboard/crypto",
  //   "icon": "ri-coin-line"
  // },
  // {
  //   "label": "Helpdesk Service",
  //   "link": "/dashboard/helpdesk",
  //   "icon": "ri-service-line"
  // },
  // {
  //   "label": "Storage Management",
  //   "link": "/dashboard/storage",
  //   "icon": "ri-hard-drive-2-line"
  // },
  // {
  //   "label": "Product Management",
  //   "link": "/dashboard/product",
  //   "icon": "ri-suitcase-2-line"
  // }
];

const applicationsMenu = [
  {
    "label": "File Manager",
    "link": "/apps/file-manager",
    "icon": "ri-folder-2-line"
  },
  {
    "label": "Email",
    "link": "/apps/email",
    "icon": "ri-mail-send-line"
  },
  {
    "label": "Calendar",
    "link": "/apps/calendar",
    "icon": "ri-calendar-line"
  },
  {
    "label": "Chat",
    "link": "/apps/chat",
    "icon": "ri-question-answer-line"
  },
  {
    "label": "Contacts",
    "link": "/apps/contacts",
    "icon": "ri-contacts-book-line"
  },
  {
    "label": "Task Manager",
    "link": "/apps/tasks",
    "icon": "ri-checkbox-multiple-line"
  },
  {
    "label": "Media Gallery",
    "icon": "ri-gallery-line",
    "submenu": [
      {
        "label": "Music Stream",
        "link": "/apps/gallery-music"
      },
      {
        "label": "Video Stream",
        "link": "/apps/gallery-video"
      }
    ]
  }
];

const pagesMenu = [
  {
    "label": "User Pages",
    "icon": "ri-account-circle-line",
    "submenu": [
      {
        "label": "User Profile",
        "link": "/pages/profile"
      },
      {
        "label": "People & Groups",
        "link": "/pages/people"
      },
      {
        "label": "Activity Log",
        "link": "/pages/activity"
      },
      {
        "label": "Events",
        "link": "/pages/events"
      },
      {
        "label": "Settings",
        "link": "/pages/settings"
      }
    ]
  },
  {
    "id": 27,
    "label": "Authentication",
    "icon": "ri-lock-2-line",
    "submenu": [
      {
        "label": "Sign In Basic",
        "link": "/pages/signin"
      },
      {
        "label": "Sign In Cover",
        "link": "/pages/signin2"
      },
      {
        "label": "Sign Up Basic",
        "link": "/pages/signup"
      },
      {
        "label": "Sign Up Cover",
        "link": "/pages/signup2"
      },
      {
        "label": "Verify Account",
        "link": "/pages/verify"
      },
      {
        "label": "Forgot Password",
        "link": "/pages/forgot"
      },
      {
        "label": "Lock Screen",
        "link": "/pages/lock"
      }
    ]
  },
  {
    "label": "Error Pages",
    "icon": "ri-error-warning-line",
    "submenu": [
      {
        "label": "Page Not Found",
        "link": "/pages/error-404"
      },
      {
        "label": "Internal Server Error",
        "link": "/pages/error-500"
      },
      {
        "label": "Service Unavailable",
        "link": "/pages/error-503"
      },
      {
        "label": "Forbidden",
        "link": "/pages/error-505"
      }
    ]
  },
  {
    "label": "Other Pages",
    "icon": "ri-file-text-line",
    "submenu": [
      {
        "label": "Pricing",
        "link": "/pages/pricing"
      },
      {
        "label": "FAQ",
        "link": "/pages/faq"
      }
    ]
  }
];

const uiElementsMenu = [
  {
    "label": "Getting Started",
    "icon": "ri-pencil-ruler-2-line",
    "submenu": [
      {
        "label": "Grid System",
        "link": "/docs/layout/grid"
      },
      {
        "label": "Columns",
        "link": "/docs/layout/columns"
      },
      {
        "label": "Gutters",
        "link": "/docs/layout/gutters"
      }
    ]
  },
  {
    "label": "Components",
    "icon": "ri-suitcase-line",
    "submenu": [
      {
        "label": "Accordion",
        "link": "/docs/com/accordions"
      },
      {
        "label": "Alerts",
        "link": "/docs/com/alerts"
      },
      {
        "label": "Avatars",
        "link": "/docs/com/avatars"
      },
      {
        "label": "Badge",
        "link": "/docs/com/badge"
      },
      {
        "label": "Breadcrumbs",
        "link": "/docs/com/breadcrumbs"
      },
      {
        "label": "Buttons",
        "link": "/docs/com/buttons"
      },
      {
        "label": "Cards",
        "link": "/docs/com/cards"
      },
      {
        "label": "Carousel",
        "link": "/docs/com/carousel"
      },
      {
        "label": "Dropdown",
        "link": "/docs/com/dropdown"
      },
      {
        "label": "Images",
        "link": "/docs/com/images"
      },
      {
        "label": "List Group",
        "link": "/docs/com/listgroup"
      },
      {
        "label": "Markers",
        "link": "/docs/com/markers"
      },
      {
        "label": "Modal",
        "link": "/docs/com/modal"
      },
      {
        "label": "Nav & Tabs",
        "link": "/docs/com/navtabs"
      },
      {
        "label": "Offcanvas",
        "link": "/docs/com/offcanvas"
      },
      {
        "label": "Pagination",
        "link": "/docs/com/pagination"
      },
      {
        "label": "Placeholders",
        "link": "/docs/com/placeholders"
      },
      {
        "label": "Popovers",
        "link": "/docs/com/popovers"
      },
      {
        "label": "Progress",
        "link": "/docs/com/progress"
      },
      {
        "label": "Spinners",
        "link": "/docs/com/spinners"
      },
      {
        "label": "Toast",
        "link": "/docs/com/toasts"
      },
      {
        "label": "Tooltips",
        "link": "/docs/com/tooltips"
      },
      {
        "label": "Tables",
        "link": "/docs/com/tables"
      }
    ]
  },
  {
    "label": "Forms",
    "icon": "ri-list-check-2",
    "submenu": [
      {
        "label": "Text Elements",
        "link": "/docs/form/elements"
      },
      {
        "label": "Selects",
        "link": "/docs/form/selects"
      },
      {
        "label": "Checks & Radios",
        "link": "/docs/form/checksradios"
      },
      {
        "label": "Range",
        "link": "/docs/form/range"
      },
      {
        "label": "Pickers",
        "link": "/docs/form/pickers"
      },
      {
        "label": "Layouts",
        "link": "/docs/form/layouts"
      }
    ]
  },
  {
    "label": "Charts & Graphs",
    "icon": "ri-bar-chart-2-line",
    "submenu": [
      {
        "label": "ApexCharts",
        "link": "/docs/chart/apex"
      },
      {
        "label": "Chartjs",
        "link": "/docs/chart/chartjs"
      }
    ]
  },
  {
    "label": "Maps & Icons",
    "icon": "ri-stack-line",
    "submenu": [
      {
        "label": "Leaflet Maps",
        "link": "/docs/map/leaflet"
      },
      {
        "label": "Vector Maps",
        "link": "/docs/map/vector"
      },
      {
        "label": "Remixicon",
        "link": "/docs/icon/remix"
      },
      {
        "label": "Feathericons",
        "link": "/docs/icon/feather"
      }
    ]
  },
  {
    "label": "Utilities",
    "icon": "ri-briefcase-4-line",
    "submenu": [
      {
        "label": "Background",
        "link": "/docs/util/background"
      },
      {
        "label": "Border",
        "link": "/docs/util/border"
      },
      {
        "label": "Colors",
        "link": "/docs/util/colors"
      },
      {
        "label": "Divider",
        "link": "/docs/util/divider"
      },
      {
        "label": "Flex",
        "link": "/docs/util/flex"
      },
      {
        "label": "Sizing",
        "link": "/docs/util/sizing"
      },
      {
        "label": "Spacing",
        "link": "/docs/util/spacing"
      },
      {
        "label": "Opacity",
        "link": "/docs/util/opacity"
      },
      {
        "label": "Position",
        "link": "/docs/util/position"
      },
      {
        "label": "Typography",
        "link": "/docs/util/typography"
      },
      {
        "label": "Shadows",
        "link": "/docs/util/shadows"
      },
      {
        "label": "Extras",
        "link": "/docs/util/extras"
      }
    ]
  }
];

export { dashboardMenu, applicationsMenu, pagesMenu, uiElementsMenu };