import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Card, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import useSkinSwitcher from "../../common/skin";
import GameSettingService from "../../service/api/game-setting.service";
import BannerService from "../../service/api/banner.service";
import { darkModeStyles2, lightModeStyles2 } from "../../common/reactSelectStyles";

const imageBaseUrl = process.env.REACT_APP_IMAGE_URL;

const ViewGameDetails = () => {
  const theme = useSelector((state) => state.ui.skin);
  const { skin, setSkin } = useSkinSwitcher();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state;

  const gameSettingService = GameSettingService();
  const bannerService = BannerService();

  const [formData, setFormData] = useState({
    name: "",
    merchant: [],
  });
  const [merchantOptions, setMerchantOptions] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [underMaintenance, setUnderMaintenance] = useState(null);

  const underMaintenanceOptions = [
    { value: true, label: "True" },
    { value: false, label: "False" },
  ];

  // Fetch game list for dropdown
  const fetchMerchantList = async () => {
    try {
      const res = await gameSettingService.getGameListDropdown();
      if (res?.code === 200) {
        const options = res.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setMerchantOptions(options);
      }
    } catch (error) {
      console.error("Error fetching merchant list:", error);
    }
  };

  // Fetch banner details
  const fetchBannerDetails = async () => {
    try {
      const res = await bannerService.getBannerById(id);
      if (res?.code === 200) {
        const selectedMerchant = merchantOptions.find(
          (option) => option.value === res.data.gameId
        );

        setFormData({
          name: res.data.name,
          merchant: selectedMerchant ? [selectedMerchant] : [],
        });

        setSelectedMerchant(selectedMerchant ? [selectedMerchant] : []);
        setImagePreview(`${res.data.bannerImages[0]?imageBaseUrl/res.data.bannerImages[0]:null}`);
        setUnderMaintenance(
          underMaintenanceOptions.find(
            (option) => option.value === res.data.underMaintenance
          )
        );
      }
    } catch (error) {
      console.error("Error fetching banner details:", error);
    }
  };

  useEffect(() => {
    fetchMerchantList();
  }, []);

  useEffect(() => {
    if (merchantOptions.length > 0) {
      fetchBannerDetails();
    }
  }, [merchantOptions]);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <Card>
          <Card.Header>
            <h4 className="card-title w-100">View Banner</h4>
          </Card.Header>
          <Card.Body>
            <div className="d-flex justify-content-center">
              <Col md={6}>
                <img
                  src={imagePreview || "../images/banner-placeholder.png"}
                  alt="Banner Preview"
                  style={{ maxWidth: "100%" }}
                />
              </Col>
            </div>
            <Form className="row mt-3">
              <Col md={4} className="mt-3">
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={formData.name}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mt-3">
                <Form.Group controlId="merchant">
                  <Form.Label>Link to</Form.Label>
                  <Select
                    styles={
                      theme === "dark" ? darkModeStyles2 : lightModeStyles2
                    }
                    value={selectedMerchant}
                    options={merchantOptions}
                    isDisabled
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mt-3">
                <Form.Group controlId="underMaintenance">
                  <Form.Label>Under Maintenance</Form.Label>
                  <Select
                    styles={
                      theme === "dark" ? darkModeStyles2 : lightModeStyles2
                    }
                    value={underMaintenance}
                    options={underMaintenanceOptions}
                    isDisabled
                  />
                </Form.Group>
              </Col>
              <div className="d-flex justify-content-center mt-5">
                <Button
                  variant="primary"
                  onClick={() => navigate("/banner")}
                >
                  Back
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default ViewGameDetails;
