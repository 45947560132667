import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { darkModeStyles, darkModeStyles2, lightModeStyles, lightModeStyles2 } from "../../common/reactSelectStyles";
import { useSelector } from "react-redux";
import GameSettingService from "../../service/api/game-setting.service";

const EditGameSetting = () => {
    const location = useLocation();
    const { id } = location.state;
    const theme = useSelector((state) => state.ui.skin);
    const { skin, setSkin } = useSkinSwitcher();
    const gameService = GameSettingService();
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [errors, setErrors] = useState({});
    const [selectedValues, setSelectedValues] = useState([]);
    const [gameListOptions, setGameListOptions] = useState([]);
    const [formData, setFormData] = useState({
        gameId: '',
        houseEdge: '',
    });


    useEffect(() => {
        getList();
        getGameSettingDetails();
    }, []);


    const getList = async () => {
        try {

            const res = await gameService.getGameListDropdown();
            if (res?.code === 200) {
                console.log('Game list', res);
                const list = res.data.map((item) => ({
                    label: item.name,
                    value: item.id
                }))
                setGameListOptions(list);
            }
            else {
                console.log('Game list', res);
            }
        } catch (error) {
            console.error(error);
        }
    }


    const getGameSettingDetails = async () => {

        try {
            const res = await gameService.getGameSettingById(id);
            if (res?.code === 200) {
                console.log(res, "hello view game")
                setFormData({ gameId: res?.data?.gameId?.id, houseEdge: res?.data?.houseEdge.toString() });
                setSelectedValues({ label: res?.data.gameId.name, value: res?.data?.gameId?.id });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSelectChange = (items) => {
        console.log(items, "Selected value")
        // const valuesArray = items ? items.map(item => item.value) : [];
        setFormData((preValue) => ({
            ...preValue,
            gameId: items.value
        }));
        setSelectedValues(items);
        setShowError(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        let errorMessage = "";

        if (name === "houseEdge") {
            const numericValue = parseFloat(value);
            if (isNaN(numericValue) || numericValue < 0 || numericValue > 1) {
                errorMessage = "House Edge must be a number between 0 and 1.";
            }
        }

        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: errorMessage });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = {};

        if (formData?.houseEdge?.trim() === "") {
            validationErrors.houseEdge = "House Edge is required";
        } else {
            const numericValue = parseFloat(formData.houseEdge);
            if (isNaN(numericValue) || numericValue < 0 || numericValue > 1) {
                validationErrors.houseEdge = "House Edge must be a number between 0 and 1.";
            }
        }

        if (formData.gameId.length === 0) {
            validationErrors.gameId = "Game is required";
        }

        if (Object.keys(validationErrors).length === 0) {
            setShowError(false);
            const res = await gameService.updateGameSetting(id, formData);
            if (res?.code === 200) {
                toast.success(res?.message);
                navigate('/game-setting');
            } else {
                toast.error(res?.message);
            }
        } else {
            setShowError(true);
            setErrors(validationErrors);
        }
    };

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <div className=" align-items-center mb-4">
                    <Card>
                        <Card.Header>
                            <h4 className="card-title w-100">Edit Game Setting</h4>
                        </Card.Header>
                        <Card.Body>
                            <Form className="row" onSubmit={handleSubmit}>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="merchant">
                                        <Form.Label>Game</Form.Label>
                                        <ReactSelect
                                            styles={theme === 'dark' ? darkModeStyles2 : lightModeStyles2}
                                            name="gameId"
                                            options={gameListOptions}
                                            // isMulti
                                            value={selectedValues}
                                            onChange={handleSelectChange}
                                        />
                                        <Form.Text className="text-danger small">
                                            {errors.gameId !== "" && showError && "Game is required"}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="houseEdge">
                                        <Form.Label>House Edge</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter House Edge"
                                            name="houseEdge"
                                            value={formData.houseEdge}
                                            onChange={handleChange}
                                            isInvalid={showError && !!errors.houseEdge}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.houseEdge}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <div className="d-flex justify-content-center mt-5">
                                    <Button variant="primary" type="submit" className="mx-4">
                                        Submit
                                    </Button>
                                    <Button variant="danger" type="button" onClick={() => navigate("/game-setting")}>
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EditGameSetting;
