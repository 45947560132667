import { useEffect, useRef } from 'react';
import io from 'socket.io-client';
// import { environment } from "../../envirnoment/envirnoment.prod";

const useSocketService = () => {
  const socket = useRef(null);
  // const socketUrl = 'http://localhost:3002';
  const prevSocketId = useRef(null); // Ref to store the previous socket ID
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_URL);

    // Cleanup function to disconnect socket when component unmounts
    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, []);

  // useEffect(() => {
  //   // Compare the current socket ID with the previous socket ID
  //   if (socket.current && (prevSocketId.current !== socket.current.id || !prevSocketId.current) ) {
  //     // Emit 'init' event only if the socket ID has changed
  //     emitInitEvent();
  //     // Update the previous socket ID to the current socket ID
  //     prevSocketId.current = socket.current.id;
  //   }
  // }, [socket.current?.id]); // Run this effect whenever the socket ID changes

  const emitInitEvent = () => {
    let userDetail = localStorage.getItem('user');
    if (userDetail) {
      userDetail = JSON.parse(userDetail);
      console.log("🚀 ~ file: socket.service.js:34 ~ emitInitEvent ~ userDetail:", userDetail)
      emit('init-game', {
        userId: userDetail.id,
        userName: userDetail.email,
        userType: "admin"
      });
    }
  };

  const connect = (onConnect) => {
    if (socket.current) {
      socket.current.on('connect', () => {
        console.log("getege", onConnect)
        // Send socket ID to backend on connection
        onConnect && onConnect(socket.current.id);
      });
      socket.current.connect();
    }
  };

  const disconnect = () => {
    if (socket.current) {
      socket.current.disconnect();
    }
  };

  const on = (eventName, callback) => {
    console.log("socket==on",socket)
    if (socket.current) {
      socket.current.on(eventName, callback);
    }
  };

  const off = (eventName, callback) => {
    if (socket.current) {
      socket.current.off(eventName, callback);
    }
  };

  const emit = (eventName, data) => {
    if (socket.current) {
      socket.current.emit(eventName, data);
    }
  };

  return { connect, disconnect, on, off, emit, emitInitEvent };
};

export default useSocketService;

