import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import { toast } from 'react-toastify';
import globalSwal from '../../common/swalService';
import UserService from '../../service/api/user.service ';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

const ResetUserPasswordModal = ({ show, onHide, onSubmit }) => {
    const userService = UserService();
    const [formData, setFormData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        userId:show?.id || '',
    });

    const [showPassword, setShowPassword] = useState({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const togglePasswordVisibility = (field) => {
        setShowPassword(prev => ({
            ...prev,
            [field]: !prev[field]
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        setErrors(prev => ({
            ...prev,
            [name]: ''
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        let isValid = true;

        // Password validation
        // if (!formData.oldPassword) {
        //     newErrors.oldPassword = 'Old password is required';
        //     isValid = false;
        // }
        if (!formData.newPassword) {
            newErrors.newPassword = 'New password is required';
            isValid = false;
        } else if (!passwordRegex.test(formData.newPassword)) {
            newErrors.newPassword = 'New password must be at least 8 characters long and include uppercase, lowercase, number, and special character';
            isValid = false;
        }

        // Confirm password validation
        if (!formData.confirmPassword) {
            newErrors.confirmPassword = 'Confirm password is required';
            isValid = false;
        } else if (formData.newPassword !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setLoading(true);
        try {


            globalSwal.confirm({
                text: 'Do you really want to change the user password',
                onConfirm: async () => {
                    const payload = { ...formData ,userId:show.id ||''};
                    delete payload.confirmPassword;
                    delete payload.oldPassword;

                    const res = await userService.resetUserPassword(payload);

                    if (res.code === 200) {

                        toast.success('Password reset successfully');
                        onHide();
                        setFormData({
                            oldPassword: '',
                            newPassword: '',
                            confirmPassword: ''
                        });
                    }
                    else {
                        toast.error(res.message);
                        console.error(res.message);
                    }

                }
            })


            // await onSubmit(formData);
        } catch (error) {
            toast.error(error.message || 'Failed to reset password');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={show.show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Reset Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    {/* <Form.Group className="mb-3">
                        <Form.Label>Old Password</Form.Label>
                        <div className="position-relative">
                            <Form.Control
                                type={showPassword.oldPassword ? "text" : "password"}
                                placeholder="Enter old password"
                                name="oldPassword"
                                value={formData.oldPassword}
                                onChange={handleInputChange}
                                isInvalid={!!errors.oldPassword}
                            />
                            <span
                                onClick={() => togglePasswordVisibility("oldPassword")}
                                className="position-absolute"
                                style={{
                                    position: "absolute",
                                    top: "18px",
                                    right: '10px',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                    color: '#6c757d',
                                    zIndex: 100
                                }}
                            >
                                {showPassword.oldPassword ? <VscEyeClosed size={20} /> : <VscEye size={20} />}
                            </span>
                            <Form.Control.Feedback type="invalid">
                                {errors.oldPassword}
                            </Form.Control.Feedback>
                        </div>
                    </Form.Group> */}
                    <Form.Group className="mb-3">

                        <Form.Label>New Password</Form.Label>
                        <div className="position-relative">
                            <Form.Control
                                type={showPassword.newPassword ? "text" : "password"}
                                placeholder="Enter new password"
                                name="newPassword"
                                value={formData.newPassword}
                                onChange={handleInputChange}
                                isInvalid={!!errors.newPassword}
                            />
                            <span
                                onClick={() => togglePasswordVisibility("newPassword")}
                                className="position-absolute"
                                style={{
                                    position: "absolute",
                                    top: "18px",
                                    right: '10px',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                    color: '#6c757d',
                                    zIndex: 100
                                }}
                            >
                                {showPassword.newPassword ? <VscEyeClosed size={20} /> : <VscEye size={20} />}
                            </span>
                            <Form.Control.Feedback type="invalid">
                                {errors.newPassword}
                            </Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Confirm New Password</Form.Label>
                        <div className="position-relative">
                            <Form.Control
                                type={showPassword.confirmPassword ? "text" : "password"}
                                placeholder="Confirm new password"
                                name="confirmPassword"
                                value={formData.confirmPassword}
                                onChange={handleInputChange}
                                isInvalid={!!errors.confirmPassword}
                            />
                            <span
                                onClick={() => togglePasswordVisibility("confirmPassword")}
                                className="position-absolute"
                                style={{
                                    position: "absolute",
                                    top: "18px",

                                    // top: '50%',
                                    right: '10px',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                    color: '#6c757d',
                                    zIndex: 100
                                }}
                            >
                                {showPassword.confirmPassword ? <VscEyeClosed size={20} /> : <VscEye size={20} />}
                            </span>
                            <Form.Control.Feedback type="invalid">
                                {errors.confirmPassword}
                            </Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <div className="d-flex justify-content-end gap-2">
                        <Button variant="secondary" onClick={onHide}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="primary" disabled={loading}>
                            {loading ? 'Please Wait...' : 'Reset Password'}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ResetUserPasswordModal;