import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import RoleService from "../../service/api/role.service";
import UserService from "../../service/api/user.service ";
import GameService from "../../service/api/game.service";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import MerchantService from "../../service/api/merchant.service";
import ReactSelect from "react-select";
import { darkModeStyles2, lightModeStyles2 } from "../../common/reactSelectStyles";
import { useSelector } from "react-redux";
import GameSettingService from "../../service/api/game-setting.service";

const EditGameCategory = () => {
    const theme = useSelector((state) => state.ui.skin);
    const { skin, setSkin } = useSkinSwitcher();
    const gameSettingService = GameSettingService();
    const merchantService = MerchantService();
    const gameService = GameService();
    const location = useLocation();
    const { id } = location.state;
    const isLoading = useSelector((state) => state.state.loading);
    //    console.log("hello loader======",isLoading)
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [gameList, setGameList] = useState([]);
    const [errors, setErrors] = useState({});
    const [imagePreview, setImagePreview] = useState(null);
    const [formData, setFormData] = useState({

        name: "",

    });

    const [selectedResult, setSelectedResult] = useState([])

    const [underMaintainanceOptions, setunderMaintainanceOptions] = useState([{ value: true, label: "Yes" }, { value: false, label: "No" }])
    const [underMaintainance, setunderMaintainance] = useState([])


    useEffect(() => {
        getDetails();
        getGameList();
    }, []);

    // useEffect(() => {
    //         const result = gameList?.filter(option => res.data?.games?.includes(option.value))

    // },[gameList])
    const getGameList = async () => {
        const res = await gameSettingService.getGameListDropdown();
        if (res?.code === 200) {
            // setGameList(res.data);
            // let data = res.data.map((item) => item.category == '' && ({ value: item.id, label: item.name }));
            let data = res.data
                .filter(item => item.category ==null|| item.category == '' || item.category == undefined) // Filter out items with category === '' or undefined
                .map(item => ({ value: item.id, label: item.name })); // Map the filtered data to desired format

            setGameList(data);
        }
        console.log(gameList, "gameList")
    }





    const getDetails = async () => {
        const res = await gameService.getCategoryDetails(id);
        if (res?.code === 200) {
            // const result = gameList?.filter(option => res.data?.games?.includes(option.value))
            // console.log(result, "Hello category======================", res.data)
            let data = res.data?.games?.map((item) => ({ value: item?.id, label: item?.name }));
            setSelectedResult(data);

            setFormData((preValue) => ({
                ...preValue,
                undermaintainance: res.data.undermaintainance,
                name: res.data.name,
                games: res.data?.games?.map((item) => (item?.id))
            }));
            const value = underMaintainanceOptions.filter((item) => item.value === res?.data?.undermaintainance);

            setunderMaintainance(value)

        }
    };



    const handleSelectChange = (items) => {
        console.log(items, 'form item');
        const valuesArray = items ? items?.map(item => item.value) : [];
        // setSelectedValues(valuesArray);
        setFormData((preValue) => ({
            ...preValue,
            games: valuesArray
        }));
        setShowError(false);
        setSelectedResult(items);
        // Now you can send selectedValues to the API or use it elsewhere
        console.log(valuesArray, 'array of string values');
    };

    const handleMaintainanceSelectChange = (items) => {
        console.log(items, 'form item');
        // const valuesArray = items ? items?.map(item => item.value) : [];
        // setSelectedValues(valuesArray);
        setFormData((preValue) => ({
            ...preValue,
            undermaintainance: items.value
        }));
        setShowError(false);
        setunderMaintainance(items);

    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        // Add your form submission logic here
        // Perform validations
        console.log("formData", formData);
        const validationErrors = {};

        if (formData.name.trim() == "") {
            validationErrors.name = "Name is required";
        } else if (!/^[a-zA-Z0-9\s]*$/.test(formData.name)) {
            validationErrors.name = "Name should be alphanumeric";
        } else {
            validationErrors.name = "";
        }

        // console.log("validationErrors", Object.values(validationErrors));

        if (Object.values(validationErrors).every((item) => item === "")) {
            // No errors, proceed with form submission
            console.log("resss===");
            setShowError(false);
            console.log("formData", formData);
            // Create a new FormData object
            const formDataToSend = new FormData();

            // Append form fields to FormData
            formDataToSend.append("undermaintainance", formData.undermaintainance);
            // formDataToSend.append("name", formData.name);

            // if (formData.games.length !== 0) {
                formDataToSend.append("games", JSON.stringify(formData.games));
            // }

            const res = await gameService.updateCategoryDetails(id, formDataToSend);
            console.log("resss===", res);
            if (res?.code == 200) {
                toast.success(res?.message);
                navigate("/game-management-category");
            } else {
                toast.error(res?.message);
            }
        } else {
            // Set validation errors
            setShowError(true);
            setErrors(validationErrors);
        }
    };




    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <div className=" align-items-center mb-4">
                    <Card>
                        <Card.Header>
                            <h4 className="card-title w-100">Edit Category</h4>
                        </Card.Header>
                        <Card.Body>

                            <Form className="row" onSubmit={handleSubmit}>

                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            // readOnly={true}
                                            type="text"
                                            placeholder="Enter name"
                                            name="name"
                                            value={formData.name}
                                            // onChange={(e) => {
                                            //     setFormData((pre) => ({
                                            //         ...pre,
                                            //         name:e.target.value,
                                            //    }))
                                            // }}
                                            // onChange={handleChange}
                                            isInvalid={showError && !!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group>
                                        <Form.Label>Under Maintenance</Form.Label>
                                        <ReactSelect
                                            styles={
                                                theme === "dark" ? darkModeStyles2 : lightModeStyles2
                                            }
                                            value={underMaintainance}
                                            name="underMaintainance"
                                            options={underMaintainanceOptions}
                                            onChange={handleMaintainanceSelectChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={8} className="mt-3">
                                    <Form.Group>
                                        <Form.Label>Games</Form.Label>
                                        <ReactSelect
                                            styles={
                                                theme === "dark" ? darkModeStyles2 : lightModeStyles2
                                            }
                                            isMulti
                                            value={selectedResult}
                                            name="underMaintainance"
                                            options={gameList}
                                            onChange={handleSelectChange}
                                        />
                                    </Form.Group>
                                </Col>

                                <div className="d-flex justify-content-center mt-5">
                                    <Button disabled={isLoading} variant="primary" type="submit" className="mx-4">
                                        {isLoading ? "Please Wait.." : "Submit"}
                                    </Button>
                                    <Button
                                        variant="danger"
                                        type="button"
                                        onClick={() => navigate("/game-management-category")}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EditGameCategory;
