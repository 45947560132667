import React, { useEffect, useRef, useState, useCallback } from "react";
import Header from "../../layouts/Header";
import TableWithPagination from "../../common/table";
import useSkinSwitcher from "../../common/skin";
import StaffService from "../../service/api/staff.service";
import TransactionService from "../../service/api/transaction.service";
import AuthService from "../../service/api/auth.service";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import "./staff.css";
import { DateRange, DateRangePicker } from "react-date-range";
import { addDays, format } from "date-fns";
import PageBreadcrumb from "../../common/PageBreadcrum";
import { toast } from "react-toastify";

import capitalizeFirstLetter from "../../helper/Capitalise";
import Footer from "../../layouts/Footer";
import _ from "lodash";
// import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Row,
  Col,
  Nav,
  ListGroup,
  Table,
  Badge,
} from "react-bootstrap";
import Sidebar from "../../layouts/Sidebar";
import ReactSelect from "react-select";
import UserService from "../../service/api/user.service ";
import TableFilter from "../../common/filter";
import formatDateTime from "../../helper/DateFormate";
import { formatCurrency } from "../../helper/CurrencyFormate";

export default function TransactionList({ transactionType, type }) {
  // console.log("")
  const location = useLocation();
  const { id:userID } = location.state ||{};
  const staffService = StaffService();
  const userService = UserService();
  const transactionService = TransactionService();
  const authService = AuthService();
  const navigate = useNavigate();
  const { skin, setSkin } = useSkinSwitcher();
  const [transactionList, setTransactionList] = useState([]);
   
  
  console.log(userID,"log from transaction list")
  const [pagination, setPagination] = useState({
    searchBy: "",
    status:"",
    user: userID || "",
    transactionType: transactionType,
    type: type,
    page: 1,
    limit: 10,
  });
  const [transactionOverview, setTransactionOverview] = useState({
    failedTransactions: "",
    initiatesTransactions: "",
    pendingTransactions: "",
    successTransactions: "",
  });
  const [state, setState] = useState([
    {
      startDate: null,
      // endDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const clearRange = () => {
    setState([{ startDate: null, endDate: null, key: "selection" }]);
    setShowPicker(false);
  };
  const [columns, setColumns] = useState([
    "userId",
    "merchantId",
    "createdAt",
    "transactionId",
    "betId",
    "walletType",
    "transactionType",
    "type",
    "rrn",
    "openingBalance",
    "amount",
    "closingBalance",
    "status",
    // "id",
  ]);
  const [isHidden, setIsHidden] = useState(false);
  const [userList, setUserList] = useState([]);

  const getWalletType = (type) => {
    // 0: Deposit, 1: Winning, 2: Bonus
    switch (type) {
      case 0: return 'Deposit';
      case 1: return 'Winning';
      case 2: return 'Bonus';
      default: return 'N/A';
    }
  }

  const getList = useCallback(async () => {
    const res = await transactionService.getTransaction({
      page: pagination?.page,
      limit: pagination?.limit,
      searchBy: pagination?.searchBy?.trim(),
      status: pagination?.status ? pagination?.status : "",
      user: pagination?.user ? pagination?.user : "",
      transactionType: pagination?.transactionType
        ? pagination?.transactionType
        : "",
      type: pagination?.type ? pagination?.type : "",
      fromDate: pagination?.fromDate ? pagination?.fromDate : "",
      toDate: pagination?.toDate ? pagination?.toDate : "",
    });
    if (res?.code === 200) {
      console.log("===>", res?.data?.docs);
      setTransactionList(res?.data?.docs);
      setPagination((prevPagination) => ({
        ...prevPagination,
        pages: res.data.pages,
        totalDocs: res.data.totalDocs,
        limit: res.data.limit,
      }));
      setTransactionOverview((preValue) => ({
        ...preValue,
        failedTransactions: res?.data?.failedTransactions,
        initiatesTransactions: res?.data?.initiatesTransactions,
        pendingTransactions: res?.data?.pendingTransactions,
        successTransactions: res?.data?.successTransactions,
      }));
    } else {
      console.error(res?.message);
      // toast.error(res?.message);
    }
  }, [
    pagination.toDate,
    pagination.page,
    pagination?.status,
    pagination.user,
    pagination.searchBy,
    state,
    pagination.transactionType,
  ]);
  useEffect(() => {
    getList();
  }, [
    pagination.toDate,
    pagination.page,
    pagination.status,
    pagination.user,
    pagination.searchBy,
    state,
    pagination.transactionType,
    transactionType,

  ]);

  useEffect(() => {
    setPagination({ ...pagination, transactionType });
    if (transactionType != "") {
      // setTransactionList((preValue) => 
      //   preValue.filter((transaction) => !transaction.betId)
      // );

      setColumns([

        "userId",
        "merchantId",
        "createdAt",
        "transactionId",
        // "betId",
        "walletType",
        // "transactionType",
        "openingBalance",
        "rrn",
        "amount",
        "closingBalance",
        "status",

        // "id"
      ])
    }
    else {
      setColumns(
        [
          "userId",
          "merchantId",
          "createdAt",
          "transactionId",
          "betId",
          "walletType",
          "transactionType",
          "type",
          "rrn",
          "openingBalance",
          "amount",
          "closingBalance",
          "status",
          // "id",
        ]
      )
    }
  }, [transactionType]);

  // Define HTML for status badges
  const getStatusBadge = (status) => {
    if (status === 1) {
      return (
        <Badge bg={"success"} className="badge badge-success">
          Success
        </Badge>
      );
    } else if (status === 2) {
      return (
        <Badge bg={"pink"} className="badge badge-danger">
          Failed
        </Badge>
      );
    } else if (status === 3) {
      return (
        <Badge bg={"info"} className="badge badge-info">
          Initiated
        </Badge>
      );
    } else if (status === 0) {
      return (
        <Badge bg={"warning"} className="badge badge-warning">
          Pending
        </Badge>
      );
    }
  };

  const getActionDropdown = (id) => {
    const transactionInfo = transactionList.find((t) => t.id === id);
    const isDisabled = transactionInfo?.status !== 3;
    return (
      <Dropdown>
        <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
          <i className="ri-more-2-fill"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            as="button"
            disabled={isDisabled}
            className={isDisabled ? "disabled-item" : ""}
            style={{ color: isDisabled ? "gray" : "green" }}
            onClick={() => handleUpdate("approve", id)}
          >
            Approve
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            disabled={isDisabled}
            className={isDisabled ? "disabled-item" : ""}
            style={{ color: isDisabled ? "gray" : "red" }}
            onClick={() => handleUpdate("reject", id)}
          >
            Reject
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleUpdate = async (type, id) => {
    try {
      const status = type === "approve" ? "1" : "2";
      const res = await transactionService.updateTransaction(
        { status: status },
        id
      );
      if (res?.code === 200) {
        toast.success(res?.message)
        getList();

      }
    } catch (error) {
      console.log(error);
    }
  };

  // Map the columns to include HTML for status badges
  const columnsWithHTML = columns.map((column) => {
    if (column.toLowerCase() === "status") {
      return {
        header: "Status",
        accessor: column,
        Cell: ({ value }) => getStatusBadge(value), // Render status badge based on the value
      };
    }
    else if (column.toLowerCase() === "userid") {
      return {
        header: "User",
        accessor: column,
        Cell: ({ value, row }) => {
          console.log("value", value, column);
          // return capitalizeFirstLetter(value?.name) || "N/A";
          return (
            <p className="text-truncate m-0 mw-50">
             {row?.userId?.id?( <a
                className="cursor-pointer "
                onClick={() => {
                
                  const state = { id: row.userId.id };
                  navigate(`/user-management/view`, { state });
                }}
              >
                {(value?.username)?.toUpperCase() || "N/A"}
              </a>):"N/A"}
            </p>
          );
        }, // Render action dropdown based on the value
      };
    }
    else if (column.toLowerCase() === "merchantid") {
      return {
        header: "Merchant",
        accessor: column,
        Cell: ({ value, row }) => {
          console.log("value", value, column);
          // return capitalizeFirstLetter(value?.name) || "N/A";
          return (
            <p className="text-truncate m-0 mw-50">
             {value?.id && !row.userId?( <a
                className="cursor-pointer "
                onClick={() => {
                
                  const state = { id: value.id };
                  navigate(`/view-profile`, { state });
                }}
              >
                {(value?.name)?.toUpperCase() || "N/A"}
              </a>):"N/A"}
            </p>
          );
        }, // Render action dropdown based on the value
      };
    }
    else if (column.toLowerCase() === "createdat") {
      return {
        header: "Txn Date",
        accessor: column,
        Cell: ({ value }) => {
          console.log("value", value, column);
          return (
            <span className="text-nowrap">{formatDateTime(value) || "N/A"}</span>
          )
        }, // Render action dropdown based on the value
      };
    } else if (column.toLowerCase() === "transactionid") {
      return {
        header: "Txn Id",
        accessor: column,
        Cell: ({ value }) => {
          console.log("value-transaction", value, column);
          return value || "N/A";
        }, // Render action dropdown based on the value
      };
    } else if (column.toLowerCase() === "transactiontype") {
      return {
        header: "Txn Type",
        accessor: column,
        Cell: ({ value }) => {
          console.log("value", value, column);
          return capitalizeFirstLetter(value) || "N/A";
        }, // Render action dropdown based on the value
      };
    } else if (column.toLowerCase() === "type") {
      return {
        header: "Type",
        accessor: column,
        Cell: ({ value }) => {
          console.log("value", value, column);
          return capitalizeFirstLetter(value) || "N/A";
        }, // Render action dropdown based on the value
      };
    }

    else if (column.toLowerCase() === "betid") {
      return {
        header: "Bet Id",
        accessor: column,
        Cell: ({ value }) => {

          return value || "N/A";
        }, // Render action dropdown based on the value
      };
    }
    else if (column.toLowerCase() === "wallettype") {
      return {
        header: "Wallet Type",
        accessor: column,
        Cell: ({ value }) => {

          return getWalletType(value) || "N/A";
        }, // Render action dropdown based on the value
      };
    }

    else if (column.toLowerCase() === "rrn") {
      return {
        header: "RRN",
        accessor: column,
        Cell: ({ value }) => {
          console.log("value", value, column);
          return capitalizeFirstLetter(value) || "N/A";
        }, // Render action dropdown based on the value
      };
    } else if (column.toLowerCase() === "amount") {
      return {
        header: "Amount",
        accessor: column,
        Cell: ({ value, row }) => {
          // console.log("value", value, column);
          return (
            <>
              {value ? (
                <>
                  {" "}
                  {/* <span className="small pe-1">
                    {row?.currencyId?.currencySymbols}
                  </span> */}
                  {formatCurrency(value, row?.currencyId?.currencyCodes)}

                  {/* {Number(value)?.toFixed(2)} */}
                </>
              ) : (
                "0.00"
              )}
            </>
          );
        }, // Render action dropdown based on the value
      };
    } else if (column.toLowerCase() === "id") {
      return {
        header: " ",
        accessor: column,
        Cell: ({ value }) => getActionDropdown(value), // Render action dropdown based on the value
      };
    } else if (column.toLowerCase() === "openingbalance") {
      return {
        header: "Opening Balance",
        accessor: column,
        Cell: ({ value, row }) => {
          return (
            <>
              {
                value ? <> {formatCurrency(value, row?.currencyId?.currencyCodes)}</> : '0.00'
              }



            </>
          )
          // value?.toFixed(2) || '--' // Render action dropdown based on the value}
        },
      };
    }
    else if (column.toLowerCase() === "closingbalance") {
      return {
        header: "Closing Balance",
        accessor: column,
        Cell: ({ value, row }) => {
          return (
            <>
              {
                value ? <> {formatCurrency(value, row?.currencyId?.currencyCodes)}</> : '0.00'
              }

            </>
          )
          // value?.toFixed(2) || '--' // Render action dropdown based on the value}
        },
      };
    }
    // else {
    //   return column;
    // }
  });
  console.log("columnsWithHTML", columnsWithHTML);

  // const handleSearchChange = (event) => {
  //   const value = event.target.value.trim();
  //   if(value.length > 3) {

  //     setPagination({...pagination, searchBy: value})
  //   }
  //   if(value.length == 0 ) {

  //     setPagination({...pagination, searchBy: value})
  //   }
  // }
  const useDebounce = (func, delay) => {
    const debounceRef = useRef(null);

    return (...args) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const debouncedSearch = useCallback(
    _.debounce((value) => {
      setPagination((prevPagination) => ({
        ...prevPagination,
        searchBy: value,
      }));
    }, 300),
    []
  );
  const handleSearchChange = (event) => {
    console.log("searchBy==");
    const value = event.target.value.trim();
    // if (value.length > 3 || value.length === 0) {
    // debouncedSearch(value);
    // }
  };

  // const debouncedHandleSearchChange = useDebounce(handleSearchChange, 300);
  const [showPicker, setShowPicker] = useState(false);

  const handleRangeChange = (ranges) => {
    setState([ranges.selection]);
    // setShowPicker(false); // Hide the picker after selecting the date range
  };

  // useEffect(() => {
  //   const getList = async () => {
  //     try {
  //       const res = await userService.getUserList();
  //       if (res?.code === 200) {
  //         console.log(res, "use list==========");
  //         const formattedUserList = res.data.map((user) => ({
  //           label: `${user.name} (${user.username})`,
  //           value: user.id,
  //         }));
  //         setUserList(formattedUserList);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getList();
  // }, []);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <Card>
          <div className=" align-items-center">
            <Card.Header>


              <div className="d-flex justify-content-between align-items-center">
                <PageBreadcrumb
                  title={
                    transactionType == ""
                      ? "Transactions"
                      : transactionType == "credit"
                        ? "Payin Transactions"
                        : "Payout Transactions"
                  }
                />
                <div>
                  <Button className="" onClick={() => setIsHidden(!isHidden)}>
                    Filter
                  </Button>
                </div>
              </div>
              {/* <h1>Transactions</h1> */}

              {/* </div> */}
              <div className={`${transactionType != '' && 'payinFilter'} ${!isHidden ? "d-none" : ""} `}>
                {/* Search Input */}
                <TableFilter
                  handleRangeChange={handleRangeChange}
                  handleSearchChange={handleSearchChange}
                  setPagination={setPagination}
                  setShowPicker={setShowPicker}
                  state={state}
                  transactionType={transactionType}
                  clearRange={clearRange}
                  userList={userList}
                  userId={userID}
                  status={''}
                  showPicker={showPicker}
                />
              </div>
            </Card.Header>
            <TableWithPagination
              tableType={"transaction"}
              // data={[
              //   ...(transactionType !=""
              //     ? transactionList.filter((transaction) => !transaction.betId)
              //     : transactionList)
              // ]}
              data={[...transactionList]}
              itemsPerPage={pagination?.limit}
              columns={columnsWithHTML}
              currentPage={pagination?.page}
              totalPages1={pagination?.totalPages}
              totalData={pagination?.totalDocs}
              pagination={pagination}
              setPagination={setPagination}
              moduleId={6}
            />
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
}
