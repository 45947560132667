import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary",
    cancelButton: "btn btn-danger me-3",
  },
  buttonsStyling: false,
});

const globalSwal = {
  confirm: ({
    title = "Are you sure?",
    text = "Do you really want to proceed?",
    icon = "warning",
    confirmButtonText = "<i class='fas fa-check-circle me-1'></i> Yes",
    cancelButtonText = "<i class='fas fa-times-circle me-1'></i> No",
    showCancelButton = true,
    reverseButtons = true,
    focusConfirm = true,
    onConfirm = null, // Callback for when confirmed
    ...rest
  }) => {
    swalWithBootstrapButtons.fire({
      title,
      text,
      icon,
      confirmButtonText,
      cancelButtonText,
      showCancelButton,
      reverseButtons,
      focusConfirm,
      ...rest,
    }).then((result) => {
      if (result.isConfirmed && onConfirm) {
        onConfirm();
      }
    });
  },

  success: ({
    title = "Success!",
    text = "Operation completed successfully.",
    icon = "success",
    showConfirmButton = false,
    timer = 1500,
    ...rest
  }) => {
    Swal.fire({
      title,
      text,
      icon,
      showConfirmButton,
      timer,
      ...rest,
    });
  },

  error: ({
    title = "Error!",
    text = "Something went wrong.",
    icon = "error",
    ...rest
  }) => {
    Swal.fire({
      title,
      text,
      icon,
      ...rest,
    });
  },
};

export default globalSwal;
