// transaction/admin?page=1&limit=10&searchBy=&transactionType=credit

import { useDispatch } from "react-redux";
import createAxiosInstance from "../api.instance";
import { endApiRequest, startApiRequest } from "../../Redux/Action/loader.action";

const GameBetHistoryService = () => {
  const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage

  const axiosInstance = createAxiosInstance(bearerToken);

  const dispatch = useDispatch();
//   crash-game/bet-list?page=1&limit=10&searchBy=
  const getCrashBetHistory = async ({ page, limit, searchBy, fromDate, toDate, status, user, roundId,gameId }) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .get(
        `/crash-game/merchant/bet-list?page=${page}&limit=${limit}&searchBy=${searchBy}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&user=${user}&roundId=${roundId}&gameId=${gameId}`,
        { headers: {} }
      )
      .then((response) => {
        console.log("gameHistory =============", response);
        dispatch(endApiRequest());
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  const getCrashHistory = async ({ page, limit, searchBy, fromDate, toDate, status, user }) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .get(
        `/crash-game/merchant/crash-history?page=${page}&limit=${limit}&searchBy=${searchBy}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&user=${user}`,
        { headers: {} }
      )
      .then((response) => {
        console.log("gameHistory =============", response);
        dispatch(endApiRequest());
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  // transaction/admin/665496422b91287a90cb3560

//   const updateTransaction = async (data,id) => {
//     return await axiosInstance
//       .patch(
//         `/transaction/admin/${id}`,data,
//         { headers: {} }
//       )
//       .then((response) => {
//         console.log("Update transaction =============", response);
//         return response.data; // Return response data
//       })
//       .catch((error) => {
//         console.error("Update transaction failed:", error);
//         //  return error.response.data; // Throw error to be caught by the caller
//       });
//   };
  return {
    // updateTransaction,
    getCrashBetHistory,
    getCrashHistory
  };
};
export default GameBetHistoryService;

// export
