import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../../layouts/Sidebar";
import Header from "../../../layouts/Header";
import useSkinSwitcher from "../../../common/skin";
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { darkModeStyles, darkModeStyles2, lightModeStyles, lightModeStyles2 } from "../../../common/reactSelectStyles";
import { useSelector } from "react-redux";
import StaticPagesService from "../../../service/api/static.pages.service";

const EditFAQ = () => {
    const location = useLocation();
    const { id } = location.state;
    const theme = useSelector((state) => state.ui.skin);
    const { skin, setSkin } = useSkinSwitcher();
    const staticPageService = StaticPagesService();
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [errors, setErrors] = useState({});
    const [selectedValues, setSelectedValues] = useState([]);
    const [formData, setFormData] = useState({
        title:'',
        question:'',
        answer: '',
    });

    useEffect(() => {
        const getDetails = async () => {
            try {
                
                const res = await staticPageService.getFaqDetails(id);
                if (res?.code === 200) {
                    console.log('details', res);
                    setFormData((preValue) => ({
                        ...preValue,
                        title:res.data?.title,
                        question:res.data?.question,
                        answer: res.data?.answer,
                    }))
                }
                else {
                    console.log('Game list',res);
                }
            } catch (error) {
                console.error(error);
            }
        }

        getDetails();
    },[])


    const handleChange = (e) => {
        const { name, value } = e.target;
        let errorMessage = "";

        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: errorMessage });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = {};
        if (formData?.title?.trim() === "") {
            validationErrors.title = "Title is required";
        }
        if (formData?.question?.trim() === "") {
            validationErrors.question = "Question is required";
        } 
        if (formData?.answer?.trim() === "") {
            validationErrors.answer = "Answer is required";
        } 


       

        if (Object.keys(validationErrors).length === 0) {
            setShowError(false);
            const res = await staticPageService.updateFaqDetails(id,formData);
            if (res?.code === 200) {
                toast.success(res?.message);
                navigate('/faq');
            } else {
                toast.error(res?.message);
            }
        } else {
            setShowError(true);
            setErrors(validationErrors);
        }
    };

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <div className=" align-items-center mb-4">
                    <Card>
                        <Card.Header>
                            <h4 className="card-title w-100">Edit FAQ</h4>
                        </Card.Header>
                        <Card.Body>
                            <Form className="row" onSubmit={handleSubmit}>
                            <Col md={4} className="mt-3">
                                    <Form.Group controlId="title">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Title"
                                            name="title"
                                            value={formData.title}
                                            onChange={handleChange}
                                            isInvalid={showError && !!errors.title}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.title}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="question">
                                        <Form.Label>Question</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={4}
                            
                                            placeholder="Enter Question"
                                            name="question"
                                            value={formData.question}
                                            onChange={handleChange}
                                            isInvalid={showError && !!errors.question}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.question}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="answer">
                                        <Form.Label>Answer</Form.Label>
                                        <Form.Control
                                             as="textarea"
                                             rows={4}
                                            placeholder="Enter Answer"
                                            name="answer"
                                            value={formData.answer}
                                            onChange={handleChange}
                                            isInvalid={showError && !!errors.answer}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.answer}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <div className="d-flex justify-content-center mt-5">
                                    <Button variant="primary" type="submit" className="mx-4">
                                        Submit
                                    </Button>
                                    <Button variant="danger" type="button" onClick={() => navigate("/faq")}>
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EditFAQ;
