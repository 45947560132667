import React, { useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Form,
    Alert
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AuthService from '../service/api/auth.service';
import { BorderSpinner } from '../common/Spinner';
import Header from './Header';
import Sidebar from './Sidebar';
import { toast } from 'react-toastify';
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import { updateUserData } from '../Redux/Action';
import InfoComponent from '../common/Info';
const emailRegex = new RegExp(/^([a-zA-Z0-9.]+)@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/);
const nameRegex = /^[a-zA-Z0-9\s]*$/;

export default function EditProfile() {
    const dispatch = useDispatch();
    const authService = AuthService();
    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [message, setMessage] = useState({ type: '', text: '' });
    const theme = useSelector((state) => state.ui.skin);
    const [imagePreview, setImagePreview] = useState(null);
    const [faviconPreview, setFaviconPreview] = useState(null);
    const [ogImagePreview, setOgImagePreview] = useState(null);
    const [errors, setErrors] = useState({});
    const [showError, setShowError] = useState(false);
    const [showPassword, setShowPassword] = useState({
        password: false,
        confirmPassword: false,
    });
    // Form state
    const [formData, setFormData] = useState({
        name: "",
        password: "",
        confirmPassword: "",
        email: "",
        supportNumber: "",
        supportType: "",
        depositeNumber: "",
        phone: "",
        paymentMethod: "",
        metaDescription: "",
        metaKeywords: "",
        metaTitle: "",
        favicon: null,
        logo: null,
        ogImage: null,
        id: '',
        isUserSignUpAllowed: false
    });

    useEffect(() => {
        const getProfileData = async () => {
            try {
                const res = await authService.getLoginData();
                if (res.code === 200) {
                    setProfileData(res.data);
                    setFormData({

                        name: res.data.name || '',
                        email: res.data.email || '',
                        phone: res.data.phone || '',
                        paymentMethod:res.data.paymentMethod || '',
                        supportNumber: res.data.supportNumber || '',
                        depositeNumber: res.data.depositeNumber || '',
                        supportType: res?.data?.supportType || '',
                        metaKeywords: res?.data?.metaKeywords || '',
                        metaDescription: res?.data?.metaDescription || '',
                        metaTitle: res?.data?.metaTitle || '',
                        logo: res?.data?.logo || null,
                        favicon: res?.data?.favicon || null,
                        isUserSignUpAllowed: res.data.isUserSignUpAllowed || false,
                        password: '',
                        confirmPassword: '',
                        id: res?.data?.id

                    });

                    setImagePreview(res?.data?.logo ? `${process.env.REACT_APP_IMAGE_URL}/${res?.data?.logo}` : null);
                    setFaviconPreview(res?.data?.favicon ? `${process.env.REACT_APP_IMAGE_URL}/${res?.data?.favicon}` : null);
                    setOgImagePreview(res?.data?.ogImage ? `${process.env.REACT_APP_IMAGE_URL}/${res?.data?.ogImage}` : null)
                }
            } catch (error) {
                setMessage({ type: 'danger', text: 'Failed to load profile data' });
            } finally {
                setLoading(false);
            }
        };
        getProfileData();
    }, []);


    const togglePasswordVisibility = (field) => {
        setShowPassword((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
        setErrors({ ...errors, [name]: "" });
    };
    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;


    //     setFormData({ ...formData, [name]: value });
    //     // showError(false)
    //     setErrors({ ...errors, [name]: "" });
    //   };

    // const handleFaviconChange = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         if (!["image/jpeg", "image/png"].includes(file.type)) {
    //             setErrors((prev) => ({
    //                 ...prev,
    //                 favicon: "Only JPG and PNG files are allowed.",
    //             }));
    //             return;
    //         }
    //         if (file.size > 100 * 1024) {
    //             setErrors((prev) => ({
    //                 ...prev,
    //                 favicon: "Logo size must be less than 100KB.",
    //             }));
    //             return;
    //         }

    //         const reader = new FileReader();
    //         reader.onload = () => {
    //             const img = new Image();
    //             img.onload = () => {
    //                 setErrors((prev) => ({ ...prev, favicon: "" }));
    //                 setFormData((prev) => ({ ...prev, favicon: file }));
    //                 setFaviconPreview(reader.result); // Set preview only if dimensions are valid
    //             };
    //             img.src = reader.result;
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // }

    // const handleImageChange = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         if (!["image/jpeg", "image/png"].includes(file.type)) {
    //             setErrors((prev) => ({
    //                 ...prev,
    //                 logo: "Only JPG and PNG files are allowed.",
    //             }));
    //             // validationErrors.logo = "Only JPG and PNG files are allowed.";
    //             return;
    //         }
    //         if (file.size > 100 * 1024) {
    //             setErrors((prev) => ({
    //                 ...prev,
    //                 logo: "Logo size must be less than 100KB.",
    //             }));
    //             // validationErrors.logo = "Logo size must be less than 100KB.";
    //             return;
    //         }

    //         const reader = new FileReader();
    //         reader.onload = () => {
    //             const img = new Image();
    //             img.onload = () => {
    //                 if (img.width !== 280 || img.height !== 76) {
    //                     setErrors((prev) => ({
    //                         ...prev,
    //                         logo: "Logo dimensions must be 280x76 pixels.",
    //                     }));
    //                     // validationErrors.logo = "Logo dimensions must be 280x76 pixels.";
    //                 } else {
    //                     setErrors((prev) => ({ ...prev, logo: "" }));
    //                     setFormData((prev) => ({ ...prev, logo: file }));
    //                     setImagePreview(reader.result); // Set preview only if dimensions are valid
    //                 }
    //             };
    //             img.src = reader.result;
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };

    const handleImageUpload = (e, type, name, { maxSize, allowedTypes, dimensions }) => {
        const file = e.target.files[0];
        if (file) {
            // Validate file type
            if (!allowedTypes.includes(file.type)) {
                setErrors((prev) => ({
                    ...prev,
                    [type]: `Only ${allowedTypes.map((t) => t.split("/")[1].toUpperCase()).join(", ")} files are allowed.`,
                }));
                return;
            }

            // Validate file size
            if (file.size > maxSize) {
                setErrors((prev) => ({
                    ...prev,
                    [type]: `${name} size must be less than ${maxSize / 1024}KB.`,
                }));
                return;
            }

            const reader = new FileReader();
            reader.onload = () => {
                const img = new Image();
                img.onload = () => {
                    // Validate image dimensions if required
                    if (dimensions && (img.width !== dimensions.width || img.height !== dimensions.height)) {
                        setErrors((prev) => ({
                            ...prev,
                            [type]: `${name} dimensions must be ${dimensions.width}x${dimensions.height} pixels.`,
                        }));
                    } else {
                        setErrors((prev) => ({ ...prev, [type]: "" }));
                        setFormData((prev) => ({ ...prev, [type]: file }));
                        if (type === "ogImage") setOgImagePreview(reader.result);
                        if (type === "favicon") setFaviconPreview(reader.result);
                        if (type === "logo") setImagePreview(reader.result);
                    }
                };
                img.src = reader.result;
            };
            reader.readAsDataURL(file);
        }
    };

    const handleOgImage = (e) =>
        handleImageUpload(e, "ogImage", "OG Image", {
            maxSize: 100 * 1024,
            allowedTypes: ["image/jpeg", "image/png"],
        });

    const handleFaviconChange = (e) =>
        handleImageUpload(e, "favicon", "Favicon", {
            maxSize: 100 * 1024,
            allowedTypes: ["image/jpeg", "image/png"],
        });

    const handleImageChange = (e) =>
        handleImageUpload(e, "logo", "Logo", {
            maxSize: 100 * 1024,
            allowedTypes: ["image/jpeg", "image/png"],
            dimensions: { width: 280, height: 76 },
        });
    const validateForm = () => {
        const newErrors = {};
        let isValid = true;

        // Required fields validation
        const requiredFields = ['name', 'email', 'phone','paymentMethod', 'supportType', 'supportNumber', 'depositeNumber'];
        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
                isValid = false;
            }
        });
        if (formData?.name?.trim() == '') {
            newErrors.name = 'Name is required';
            isValid = false;
        }

        else if (!nameRegex.test(formData?.name)) {
            newErrors.name = 'Name should be alphanumeric';
            isValid = false;
        }
        // Email validation
        if (formData.email && !emailRegex.test(formData.email)) {
            newErrors.email = 'Please enter a valid email address';
            isValid = false;
        }

        // Phone number validation
        if (formData.phone && !/^\d{10}$/.test(formData.phone)) {
            newErrors.phone = 'Please enter a valid 10-digit phone number';
            isValid = false;
        }

        // Password validation
        if (formData.password || formData.confirmPassword) {
            if (formData.password !== formData.confirmPassword) {
                newErrors.confirmPassword = 'Passwords do not match';
                isValid = false;
            }
            if (formData.password && formData.password.length < 6) {
                newErrors.password = 'Password must be at least 6 characters';
                isValid = false;
            }
        }

        setErrors(newErrors);
        setShowError(true);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowError(true);
        if (!validateForm()) return;
        setShowError(false);
        setSaving(true);
        try {
            const formDataToSend = new FormData();

            // Append all form fields
            Object.keys(formData).forEach(key => {
                if (formData[key] !== null && key !== 'confirmPassword') {
                    if (formData[key] === '' || formData[key] === null) {
                        return;
                    }
                    formDataToSend.append(key, formData[key]);
                }
            });

            const response = await authService.updateProfile(formDataToSend);
            if (response.code === 200) {
                toast.success(response.message);
                dispatch(updateUserData(response.data));
                // navigate('/');
            } else {
                toast.error(response.message || 'Failed to update profile');
            }
        } catch (error) {
            setMessage({ type: 'danger', text: 'An error occurred while updating profile' });
        } finally {
            setSaving(false);
        }
    };

    return (
        <React.Fragment>
            <Header />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <Container className="py-2">
                    <Row>
                        <Col>
                            <Breadcrumb>
                                <BreadcrumbItem>
                                    <Link to='/'>Dashboard</Link>
                                </BreadcrumbItem>
                                {/* <BreadcrumbItem>
                                    <Link to='/profile'>Profile</Link>
                                </BreadcrumbItem> */}
                                <BreadcrumbItem active>Edit Profile</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                    </Row>

                    {loading ? (
                        <div className="d-flex mt-5 justify-content-center align-content-center">
                            <BorderSpinner />
                        </div>
                    ) : (
                        <Row>
                            <Col lg="4">
                                <Card className="mb-4">
                                    <Card.Body className="text-center">
                                        <Card.Img
                                            variant="top"
                                            src="./images/profile.png"
                                            alt="avatar"
                                            className="rounded-circle mb-3"
                                            style={{ width: '150px' }}
                                        />
                                        <Card.Title>{profileData?.name}</Card.Title>
                                        <Card.Text>{profileData?.role.toUpperCase()}</Card.Text>
                                        <Card.Text>{profileData?.email}</Card.Text>

                                        <Card.Text className="text-muted">{profileData?.domain}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col lg="8">
                                <Card className="mb-4">
                                    <Card.Body>

                                        <Form onSubmit={handleSubmit}>


                                            <Row className="mb-3">

                                                <Col md={6} className="mt-3">
                                                    <Form.Group controlId="name">
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter name"
                                                            name="name"
                                                            value={formData.name}
                                                            onChange={handleInputChange}
                                                            isInvalid={showError && !!errors.name}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.name}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} className="mt-3">
                                                    <Form.Group controlId="phone">
                                                        <Form.Label>Phone Number</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter phone number"
                                                            name="phone"
                                                            value={formData.phone}
                                                            onChange={handleInputChange}
                                                            isInvalid={showError && !!errors.phone}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.phone}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} className="mt-3">
                                                    <Form.Group controlId="paymentMethod">
                                                        <Form.Label>Payment Method</Form.Label>


                                                        <Form.Select
                                                            name="paymentMethod"
                                                            onChange={handleInputChange}
                                                            value={formData.paymentMethod}
                                                            placeholder="Select payment method"

                                                            isInvalid={showError && !!errors.paymentMethod}
                                                        >
                                                            {/* ["whatsapp", "telegram", "intercom"] */}
                                                            <option value="">Select Type</option>
                                                            <option value="manual">Manual</option>
                                                            <option value="paytme">Paytme</option>
                                                            {/* <option value="intercom">Intercom</option> */}
                                                            {/* <option value="kripabetintercom">Kripabet Intercom</option> */}

                                                        </Form.Select>
                                                        <Form.Text className="text-danger small">
                                                            {errors.paymentMethod}

                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} className="mt-3">
                                                    <Form.Group controlId="role">
                                                        <Form.Label>Support Type</Form.Label>


                                                        <Form.Select
                                                            name="supportType"
                                                            onChange={handleInputChange}
                                                            value={formData.supportType}
                                                            placeholder="Select support type"

                                                            isInvalid={showError && !!errors.supportType}
                                                        >
                                                            {/* ["whatsapp", "telegram", "intercom"] */}
                                                            <option value="">Select Type</option>
                                                            <option value="whatsapp">whatsapp</option>
                                                            <option value="telegram">Telegram</option>
                                                            {/* <option value="intercom">Intercom</option> */}
                                                            <option value="kripabetintercom">Kripabet Intercom</option>

                                                        </Form.Select>
                                                        <Form.Text className="text-danger small">
                                                            {errors.supportType}
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} className="mt-3">
                                                    <Form.Group controlId="supportNumber">
                                                        <Form.Label>Support Mobile Number</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter support number"
                                                            name="supportNumber"
                                                            value={formData.supportNumber}
                                                            onChange={handleInputChange}
                                                            isInvalid={showError && !!errors.supportNumber}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.supportNumber}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} className="mt-3">
                                                    <Form.Group controlId="depositeNumber">
                                                        <Form.Label>Deposit Mobile Number</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter deposit number"
                                                            name="depositeNumber"
                                                            value={formData.depositeNumber}
                                                            onChange={handleInputChange}
                                                            isInvalid={showError && !!errors.depositeNumber}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.depositeNumber}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                                {/* <Col md={6} className="mt-3">
                                                    <Form.Group controlId="email">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="Enter email"
                                                            name="email"
                                                            value={formData.email}
                                                            onChange={handleInputChange}
                                                            isInvalid={showError && !!errors.email}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.email}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col> */}

                                                {/* <Col md={6} className="mt-3">
                                                    <Form.Group controlId="password">
                                                        <Form.Label>Password</Form.Label>
                                                        <div style={{ position: "relative" }}>
                                                            <Form.Control
                                                                type={showPassword.password ? "text" : "password"}
                                                                placeholder="Enter password"
                                                                name="password"
                                                                value={formData.password}
                                                                onChange={handleInputChange}
                                                                isInvalid={showError && !!errors.password}

                                                            />
                                                            <span
                                                                onClick={() => togglePasswordVisibility("password")}
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "18px",
                                                                    right: "10px",
                                                                    transform: "translateY(-50%)",
                                                                    cursor: "pointer",
                                                                    color: "#6c757d",
                                                                    zIndex: 100
                                                                }}
                                                            >
                                                                {showPassword.password ? <VscEyeClosed size={20} /> : <VscEye size={20} />}
                                                            </span>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.password}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} className="mt-3">
                                                    <Form.Group controlId="confirmPassword">
                                                        <Form.Label>Confirm Password</Form.Label>
                                                        <div style={{ position: "relative" }}>
                                                            <Form.Control
                                                                type={showPassword.confirmPassword ? "text" : "password"}
                                                                placeholder="Enter Confirm Password"
                                                                name="confirmPassword"
                                                                value={formData.confirmPassword}
                                                                onChange={handleInputChange}
                                                                isInvalid={showError && !!errors.confirmPassword}
                                                            />
                                                            <span
                                                                onClick={() => togglePasswordVisibility("confirmPassword")}
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "18px",
                                                                    right: "10px",
                                                                    transform: "translateY(-50%)",
                                                                    cursor: "pointer",
                                                                    color: "#6c757d",
                                                                    zIndex: 10000
                                                                }}
                                                            >
                                                                {showPassword.confirmPassword ? <VscEyeClosed size={20} /> : <VscEye size={20} />}
                                                            </span>

                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.confirmPassword}
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                </Col> */}
                                                <Col md={6} className="mt-3">
                                                    <Form.Group controlId="metaTitle">
                                                        <Form.Label>Meta Title</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter Meta Title"
                                                            name="metaTitle"
                                                            value={formData.metaTitle}
                                                            onChange={handleInputChange}
                                                            isInvalid={showError && !!errors.metaTitle}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.metaTitle}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6} className="mt-3">
                                                    <Form.Group controlId="metaKeywords">
                                                        <Form.Label>Meta Keywords</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter Meta Keywords"
                                                            name="metaKeywords"
                                                            value={formData.metaKeywords}
                                                            onChange={handleInputChange}
                                                            isInvalid={showError && !!errors.metaKeywords}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.metaKeywords}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6} className="mt-3">
                                                    <Form.Group controlId="metaDescription">
                                                        <Form.Label>Meta Description</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            as={'textarea'}
                                                            rows={4}
                                                            placeholder="Enter Description "
                                                            name="metaDescription"
                                                            value={formData.metaDescription}
                                                            onChange={handleInputChange}
                                                            isInvalid={showError && !!errors.metaDescription}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.metaDescription}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6} className="mt-3">
                                                    <Form.Group>
                                                        <Form.Label>Favicon</Form.Label>
                                                        <InfoComponent message={"Max file size should be 100kb."} />

                                                        <div className="image-upload-wrapper">
                                                            <Form.Control
                                                                type="file"
                                                                accept="image/jpeg, image/png"
                                                                onChange={handleFaviconChange}
                                                                isInvalid={!!errors.favicon}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.favicon}
                                                            </Form.Control.Feedback>
                                                            {faviconPreview ? (
                                                                <img
                                                                    src={faviconPreview}
                                                                    alt="Preview"
                                                                    className="img-thumbnail"
                                                                    style={{ width: "100px", height: "100px" }}
                                                                />
                                                            ) : (
                                                                <div className="image-placeholder">
                                                                    Favicon Not Available
                                                                    {/* <InfoComponent message={"Max file size should be 100kb."} /> */}
                                                                </div>
                                                            )}

                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} className="mt-3">
                                                    <Form.Group>
                                                        <Form.Label>OG Image</Form.Label>
                                                        <InfoComponent message={"Max file size should be 100kb."} />

                                                        <div className="image-upload-wrapper">
                                                            <Form.Control
                                                                type="file"
                                                                accept="image/jpeg, image/png"
                                                                onChange={handleOgImage}
                                                                isInvalid={!!errors.ogImage}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.ogImage}
                                                            </Form.Control.Feedback>
                                                            {ogImagePreview ? (
                                                                <img
                                                                    src={ogImagePreview}
                                                                    alt="Preview"
                                                                    className="img-thumbnail"
                                                                    style={{ width: "100px", height: "100px" }}
                                                                />
                                                            ) : (
                                                                <div className="image-placeholder">
                                                                    OG Image Not Available
                                                                    {/* <InfoComponent message={"Max file size should be 100kb."} /> */}
                                                                </div>
                                                            )}

                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={6} className="mt-3">
                                                    <Form.Group>
                                                        <Form.Label>Logo</Form.Label>
                                                        <InfoComponent message={"Max file size should be 100kb and dimensions must be 280x76 pixels."} />

                                                        <div className="image-upload-wrapper">
                                                            <Form.Control
                                                                type="file"
                                                                accept="image/jpeg, image/png"
                                                                onChange={handleImageChange}
                                                                isInvalid={!!errors.logo}
                                                            />

                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.logo}
                                                            </Form.Control.Feedback>
                                                            {imagePreview ? (
                                                                <img
                                                                    src={imagePreview}
                                                                    alt="Preview"
                                                                    className="img-thumbnail"
                                                                    style={{ width: "100px", height: "100px" }}
                                                                />

                                                            ) : (
                                                                <div className="image-placeholder">
                                                                    Logo Not Available


                                                                </div>
                                                            )}


                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                <Col sm="12" className="mb-3 mt-4">
                                                    <Form.Group>
                                                        <Form.Check
                                                            type="switch"
                                                            id="isUserSignUpAllowed"
                                                            name="isUserSignUpAllowed"
                                                            label="Allow New User Signup"
                                                            checked={formData.isUserSignUpAllowed}
                                                            onChange={handleInputChange}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <div className="text-end">
                                                <Link to="/" className="btn btn-secondary me-2">Cancel</Link>
                                                <Button
                                                    type="submit"
                                                    variant="primary"
                                                    disabled={saving}
                                                >
                                                    {saving ? 'Please Wait...' : 'Save Changes'}
                                                </Button>
                                            </div>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
}