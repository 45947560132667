import React, { Component, useCallback, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/img1.jpg";
import {
    dashboardMenu,
    applicationsMenu,
    pagesMenu,
    uiElementsMenu
} from "../data/Menu";
import Logo from "../common/logo";
import { useCheckAccess } from "./checkAccesss";
// import { checkAccesss } from "./checkAccesss";

export default class Sidebar extends Component {

    
    toggleFooterMenu = (e) => {
        e.preventDefault();
        let parent = e.target.closest(".sidebar");
        parent.classList.toggle("footer-menu-show");
    }

    render() {
        return (
            <div className="sidebar" style={{ opacity: 1 }}>
                <div className="sidebar-header">
                    <Logo />
                </div>
                <PerfectScrollbar className="sidebar-body" ref={ref => this._scrollBarRef = ref}>
                    <SidebarMenu onUpdateSize={() => this._scrollBarRef.updateScroll()} />
                </PerfectScrollbar>
                {/* <div className="sidebar-footer">
                    <div className="sidebar-footer-top">
                        <div className="sidebar-footer-thumb">
                            <img src={userAvatar} alt="" />
                        </div>
                        <div className="sidebar-footer-body">
                            <h6><Link to="../pages/profile.html">Shaira Diaz</Link></h6>
                            <p>Premium Member</p>
                        </div>
                        <Link onClick={this.toggleFooterMenu} to="" className="dropdown-link"><i className="ri-arrow-down-s-line"></i></Link>
                    </div>
                    <div className="sidebar-footer-menu">
                        <nav className="nav">
                            <Link to=""><i className="ri-edit-2-line"></i> Edit Profile</Link>
                            <Link to=""><i className="ri-profile-line"></i> View Profile</Link>
                        </nav>
                        <hr />
                        <nav className="nav">
                            <Link to=""><i className="ri-question-line"></i> Help Center</Link>
                            <Link to=""><i className="ri-lock-line"></i> Privacy Settings</Link>
                            <Link to=""><i className="ri-user-settings-line"></i> Account Settings</Link>
                            <Link to=""><i className="ri-logout-box-r-line"></i> Log Out</Link>
                        </nav>
                    </div>
                </div> */}
            </div>
        )
    }
}
const SidebarMenu = ({ onUpdateSize }) => {
    const [openSubmenu, setOpenSubmenu] = useState(() => {
        // Retrieve stored submenu state from localStorage
        const storedState = localStorage.getItem("openSubmenu");
        return storedState ? JSON.parse(storedState) : null;
    });
    const { checkAccess } = useCheckAccess(); // Use the custom hook to get access logic

    // Store submenu state in localStorage
    const storeSubmenuState = useCallback((key) => {
        localStorage.setItem("openSubmenu", JSON.stringify(key));
    }, []);

    // Toggle submenu
    const toggleSubMenu = (e, key) => {
        e.preventDefault();
        setOpenSubmenu((prevOpenSubmenu) => {
            const newOpenSubmenu = prevOpenSubmenu === key ? null : key;
            storeSubmenuState(newOpenSubmenu);
            return newOpenSubmenu;
        });
        if (onUpdateSize) onUpdateSize();
    };

    // Generate the menu dynamically
    const populateMenu = (item) => {
        return (
            <ul className="nav nav-sidebar">
                {item.map((m, key) => {
                    const hidden = checkAccess(m.moduleId, "list");
                    let submenuItems;

                    if (m.submenu) {
                        submenuItems = m.submenu.map((sm, subKey) => {
                            const isSubhidden = checkAccess(sm.moduleId);
                            // console.log(sm,"submenu item")
                            // const isSubhidden =false;

                            return (
                                isSubhidden && (
                                    <NavLink
                                        to={sm.link}
                                        className="nav-sub-link"
                                        key={subKey}
                                    >
                                        {sm.label}
                                    </NavLink>
                                )
                            )
                        } );
                    }

                    const isSubmenuOpen = openSubmenu === key;

                    return (
                        <li key={key} className={`nav-item ${isSubmenuOpen ? "show" : ""}`}>
                            {!submenuItems ? (
                                hidden && (
                                    <NavLink to={m.link} className="nav-link">
                                        <i className={m.icon}></i>{" "}
                                        <span>{m.label}</span>
                                    </NavLink>
                                )
                            ) : (
                                hidden && (
                                    <div
                                        onClick={(e) => toggleSubMenu(e, key)}
                                        className="nav-link has-sub"
                                    >
                                        <i className={m.icon}></i>{" "}
                                        <span>{m.label} </span>
                                    </div>
                                )
                            )}
                            {m.submenu && <nav className="nav nav-sub">{submenuItems}</nav>}
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <React.Fragment>
            <div className="nav-group show">
                {populateMenu(dashboardMenu)}
            </div>
        </React.Fragment>
    );
};

// class SidebarMenu extends Component {
    
//     constructor(props) {
//         super(props);
//         this.state = {
//             openSubmenu: this.getStoredSubmenuState(), // Retrieve stored state
//         };
//     }
    

//     getStoredSubmenuState = () => {
//         const storedState = localStorage.getItem("openSubmenu");
//         return storedState ? JSON.parse(storedState) : null;
//     }

//     storeSubmenuState = (key) => {
//         localStorage.setItem("openSubmenu", JSON.stringify(key));
//     }

//     toggleSubMenu = (e, key) => {
//         e.preventDefault();
//         this.setState(prevState => {
//             const newOpenSubmenu = prevState.openSubmenu === key ? null : key;
//             this.storeSubmenuState(newOpenSubmenu);
//             return { openSubmenu: newOpenSubmenu };
//         }, this.props.onUpdateSize);
//     }

//     populateMenu = (item) => {
//         const { checkAccess } = this.props;
//         // console.log(item,"hidddennnnnnnnnnnnn")

//         const menu = item.map((m, key) => {
//             const hidden = checkAccess(m.moduleId, 'list');
//             let sm;
//             // console.log(hidden,m.label,"hidddennnnnnnnnnnnn")
//             if (m.submenu) {
//                 sm = m.submenu.map((sm, subKey) => {
//                     return (
//                         hidden && <NavLink to={sm.link} className="nav-sub-link" key={subKey}>{sm.label}</NavLink>
//                     );
//                 });
//             }

//             const isSubmenuOpen = this.state.openSubmenu === key;

//             return (
//                 <li key={key} className={`nav-item ${isSubmenuOpen ? 'show' : ''}`}>
//                     {(!sm) ? (
//                         hidden && <NavLink to={m.link} className="nav-link"><i className={m.icon}></i> <span>{m.label}</span></NavLink>
//                     ) : (
//                         hidden && <div onClick={(e) => this.toggleSubMenu(e, key)} className="nav-link has-sub"><i className={m.icon}></i> <span>{m.label}</span></div>
//                     )}
//                     {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
//                 </li>
//             );
//         });

//         return (
//             <ul className="nav nav-sidebar">
//                 {menu}
//             </ul>
//         );
//     }

//     render() {
//         return (
//             <React.Fragment>
//                 <div className="nav-group show">
//                     {this.populateMenu(dashboardMenu)}
//                 </div>
               
//             </React.Fragment>
//         );
//     }
// }
// const SidebarMenu = ({ onUpdateSize }) => {
//     const { checkAccess } = useCheckAccess(); // Use the custom hook to get access logic
//     const [openSubmenu, setOpenSubmenu] = React.useState(null);
  
//     const toggleSubMenu = (e, key) => {
//       e.preventDefault();
//       setOpenSubmenu((prev) => (prev === key ? null : key));
//       onUpdateSize();
//     };
  
//     const populateMenu = (item) => {
//       return (
//         <ul className="nav nav-sidebar">
//           {item.map((m, key) => {
//             const hasAccess = checkAccess(m.moduleId); // Check access for each menu item
//             const isSubmenuOpen = openSubmenu === key;
  
//             if (!hasAccess) return null; // Hide menu item if no access
  
//             return (
//               <li key={key} className={`nav-item ${isSubmenuOpen ? "show" : ""}`}>
//                 {!m.submenu ? (
//                   <NavLink to={m.link} className="nav-link">
//                     <i className={m.icon}></i> <span>{m.label}</span>
//                   </NavLink>
//                 ) : (
//                   <div
//                     onClick={(e) => toggleSubMenu(e, key)}
//                     className="nav-link has-sub"
//                   >
//                     <i className={m.icon}></i> <span>{m.label}</span>
//                   </div>
//                 )}
//                 {m.submenu && (
//                   <nav className="nav nav-sub">
//                     {m.submenu.map((sm, subKey) => (
//                       <NavLink to={sm.link} className="nav-sub-link" key={subKey}>
//                         {sm.label}
//                       </NavLink>
//                     ))}
//                   </nav>
//                 )}
//               </li>
//             );
//           })}
//         </ul>
//       );
//     };
  
//     return (
//       <React.Fragment>
//         <div className="nav-group show">{populateMenu(dashboardMenu)}</div>
//       </React.Fragment>
//     );
//   };
window.addEventListener("click", function (e) {
    // Close sidebar footer menu when clicked outside of it
    let tar = e.target;
    let sidebar = document.querySelector(".sidebar");
    if (!tar.closest(".sidebar-footer") && sidebar) {
        sidebar.classList.remove("footer-menu-show");
    }

    // Hide sidebar offset when clicked outside of sidebar
    if (!tar.closest(".sidebar") && !tar.closest(".menu-link")) {
        document.querySelector("body").classList.remove("sidebar-show");
    }
});

window.addEventListener("load", function () {
    let skinMode = localStorage.getItem("sidebar-skin");

    let HTMLTag = document.querySelector("html");

    if (skinMode) {
        HTMLTag.setAttribute("data-sidebar", skinMode);
    }
});
