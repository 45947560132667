// import { BehaviorSubject } from "rxjs";
// import axios from "axios";
// import { environment } from "../../envirnoment/envirnoment.prod";
// import { map, catchError, tap, retry } from "rxjs/operators";

import createAxiosInstance from "../api.instance";
import { useDispatch } from "react-redux";
import { endApiRequest, startApiRequest } from "../../Redux/Action/loader.action";


const StaffService = () => {
  const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage
  const dispatch = useDispatch();
const axiosInstance = createAxiosInstance(bearerToken);
  const createStaff = async (requestData) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .post(`/admin/auth/subadmin`, requestData, { headers: {} })
      .then((response) => {
        dispatch(endApiRequest());
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const getStaff = async ({page, limit, searchBy}) => {
    return await axiosInstance
      .get(`/admin/auth/subadmin?page=${page}&limit=${limit}&searchBy=${searchBy}`,  { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const updateStaff = async (id, requestData) => {
    return await axiosInstance
      .patch(`/admin/auth/subadmin/${id}`, requestData, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const updateStaffStatus = async ( requestData) => {
    return await axiosInstance
      .post(`/admin/auth/activateDeactivateAdmins`, requestData, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  const deleteStaff = async (id) => {
    return await axiosInstance
      .delete(`/admin/auth/subadmin/${id}`,  { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const getStaffById = async (id) => {
    return await axiosInstance
      .get(`/admin/auth/subadmin/${id}`,  { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  return {
    createStaff,
    getStaff,
    updateStaff,
    getStaffById,
    updateStaffStatus,
    deleteStaff
  };
};

export default StaffService;
