// src/components/Logo.js
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useSkinSwitcher from "./skin";
import darkLogo from '../assets/logo/logo-white.png'; // path to light mode logo
import lightLogo from '../assets/logo/logo-blue.png'; // path to dark mode logo

const Logo = () => {
  // const { skin } = useSkinSwitcher();
  const user = useSelector(state => state.user.user);
  const skin = useSelector((state) => state.ui.skin);
  let userInfo = JSON.parse(localStorage.getItem('user')) || ""
  const [logo, setLogo] = useState('');
  useEffect(() => {
    if (user) {
      console.log(user,'usersssssssssss')
      document.title=userInfo?.name+" "+'Admin'
      setLogo(userInfo?.logo? `${process.env.REACT_APP_IMAGE_URL}/${userInfo?.logo}`:'')
    }
    else {
      document.title='Admin'
    }
  }, [user]);
  // setImagePreview(ruser.logo? `${process.env.REACT_APP_IMAGE_URL}/${ruser.logo}`:null);

  return (
    <img 
      src={skin === 'dark' ? logo : logo} 
      alt="Logo" 
      style={{ width: '100px' }} // Adjust the width as needed
    />
  );
};

export default Logo;
