// import { BehaviorSubject } from "rxjs";
// import axios from "axios";
// import { environment } from "../../envirnoment/envirnoment.prod";
// import { map, catchError, tap, retry } from "rxjs/operators";

import createAxiosInstance from "../api.instance";
import { useDispatch } from "react-redux";
import { endApiRequest, startApiRequest } from "../../Redux/Action/loader.action";


const GameService = () => {
  const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage
  const dispatch = useDispatch();
const axiosInstance = createAxiosInstance(bearerToken);
  const createGame = async (requestData) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .post(`/admin/game`, requestData, { headers: {} })
      .then((response) => {
        dispatch(endApiRequest());
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const getGame = async ({ page, limit, searchBy,category }) => {
    dispatch(startApiRequest());
    // &category=${category}
    return await axiosInstance
      .get(`/merchant/game?page=${page}&limit=${limit}&searchBy=${searchBy}`,  { headers: {} })
      .then((response) => {
        dispatch(endApiRequest());
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const updateUser = async (id, requestData) => {
    return await axiosInstance
      .patch(`/merchant/game/${id}`, requestData, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const updateGameStatus = async ( requestData) => {
    return await axiosInstance
      .patch(`/merchant/game`, requestData, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  const deleteGame = async (id) => {
    return await axiosInstance
      .delete(`/merchant/game/${id}`,  { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const getGameById = async (id) => {
    return await axiosInstance
      .get(`/merchant/game/${id}`,  { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  const getCategory = async ({page,limit,searchBy}) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .get(`/category?page=${page}&limit=${limit}&searchBy=${searchBy}`,  { headers: {} })
      .then((response) => {
        dispatch(endApiRequest());
        // console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Failed to fetch:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  // category/66e17081986ddc7f8487e2e3
  const getCategoryDetails = async (id) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .get(`/category/${id}`,  { headers: {} })
      .then((response) => {
        dispatch(endApiRequest());
        // console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Failed to fetch:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

   // category/66e17081986ddc7f8487e2e3
  const updateCategoryDetails = async (id, data) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .patch(`/category/${id}`, data)
      .then((response) => {
        dispatch(endApiRequest());
        // console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Failed to fetch:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  const updateCategoryStatus=async (id, data) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .put(`/category/${id}`, data)
      .then((response) => {
        dispatch(endApiRequest());
        // console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Failed to fetch:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  // category/list/dropdown

  const getCategoryList = async (id) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .get(`/category/list/dropdown`,  { headers: {} })
      .then((response) => {
        dispatch(endApiRequest());
        // console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Failed to fetch:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  return {
    updateCategoryStatus,
    updateCategoryDetails,
    getCategoryDetails,
    getCategoryList,
    getCategory,
    createGame,
    getGame,
    updateUser,
    getGameById,
    updateGameStatus,
    deleteGame
  };
};

export default GameService;
