import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function GrowSpinner() {
  return <Spinner animation="grow" />;
}

function BorderSpinner() {
  return <Spinner animation="border" variant="primary" />;
}
const Loader = ({ size = 'lg', variant = 'primary', text = 'Loading...' }) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <Spinner animation="border" variant={variant} size={size} />
      <span className="ms-2">{text}</span>
    </div>
  );
};
// Named exports
export { GrowSpinner, BorderSpinner,Loader };




