import { createSlice } from '@reduxjs/toolkit';

// Function to apply skin mode classes based on the skin mode
const applySkinMode = (skin) => {
  if (skin === 'dark') {
    const btnWhite = document.getElementsByClassName('btn-white');
    for (const btn of btnWhite) {
      btn.classList.add('btn-outline-primary');
      btn.classList.remove('btn-white');
    }
    document.body.classList.add('dark-mode');
  } else {
    const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');
    for (const btn of btnOutlinePrimary) {
      btn.classList.remove('btn-outline-primary');
      btn.classList.add('btn-white');
    }
    document.body.classList.remove('dark-mode');
  }
};

// Retrieve the initial skin mode from localStorage
const initialState = {
  skin: localStorage.getItem('skin-mode') || 'light',
};

// Apply the skin mode classes on initial load
applySkinMode(initialState.skin);

const skinSlice = createSlice({
  name: 'skin',
  initialState,
  reducers: {
    switchSkin(state, action) {
      const skin = action.payload;

      // Apply the skin mode classes when switching
      applySkinMode(skin);

      // Save skin mode to localStorage
      localStorage.setItem('skin-mode', skin);

      // Update the state
      state.skin = skin;
    },
  },
});

export const { switchSkin } = skinSlice.actions;
export default skinSlice.reducer;
