// ip/list/merchant

// import { BehaviorSubject } from "rxjs";
// import axios from "axios";
// import { environment } from "../../envirnoment/envirnoment.prod";
// import { map, catchError, tap, retry } from "rxjs/operators";

import createAxiosInstance from "../api.instance";
import { useDispatch } from "react-redux";
import { endApiRequest, startApiRequest } from "../../Redux/Action/loader.action";


const IPWhitelistingService = () => {
    const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage
    const dispatch = useDispatch();
    const BASIC_AUTH = 'Basic a3JpcGFiZXQ6a3JpcGFiZXRAMTIzNDU=';
    const axiosInstanceBasic = createAxiosInstance(BASIC_AUTH, "basic");
    const axiosInstance = createAxiosInstance(bearerToken);
    const AddIP = async (requestData) => {
        // dispatch(startApiRequest());
        return await axiosInstance
            .post(`/ip/merchant/add-ip`, requestData, { headers: {} })
            .then((response) => {
                // dispatch(endApiRequest());
                // console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                // dispatch(endApiRequest());
                console.error("failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };
    const getIPList = async ({ page, limit, searchBy }) => {
        // dispatch(startApiRequest());
        return await axiosInstance
            .get(`/ip/merchant-ips?page=${page}&limit=${limit}&searchBy=${searchBy}`, { headers: {} })
            .then((response) => {
                dispatch(endApiRequest());
                // console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                dispatch(endApiRequest());
                console.error("failed ip:", error);
                // return error.response.data;
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };


 
    const updateIP = async (id, requestData) => {
        return await axiosInstance
            .patch(`/ip/merchant-control/${id}`, requestData, { headers: {} })
            .then((response) => {
                console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("Registration failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };


    const deleteIP = async (id) => {
        return await axiosInstance
            .delete(`/ip/merchant-control/${id}`)
            .then((response) => {
                console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("Registration failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };
    const getIPById = async (id) => {
        return await axiosInstance
            .get(`/ip/merchant-control/${id}`, { headers: {} })
            .then((response) => {
                // console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };

    // /ip/merchant-toggle/:type
    const getStatusOfIPW = async () => {
        return await axiosInstance
            .get(`/ip/merchant-toggle/merchant`, { headers: {} })
            .then((response) => {
                // console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };
    return {
        getStatusOfIPW,
        AddIP,
        getIPList,
        getIPById,
        updateIP,
        deleteIP
    };
};

export default IPWhitelistingService;
