import { useSelector } from "react-redux";

export const useCheckAccess = () => {
  const { games } = useSelector((state) => state.games);

  const accessObject = {
    casino: [4, 7, 8],
    lottery: [17],
    sports: [11, 12],
  };
  const defaultModules = [16,10,6,3,9,15,19,20];
  const checkAccess = (moduleId) => {
   console.log("Checking access",moduleId)
    if ([18].includes(moduleId)) {
      return false;
    }
    // console.log(games,'gamessss')
    // return true if module is other than all modules
   
    if (defaultModules.includes(moduleId)) {
      return true;
    }
    // Iterate over accessObject keys
    console.log("checking accessObject keys", moduleId);
    for (const type in accessObject) {
      // Check if `games` has a slug matching the current type
      const matchingGame = games?.find((game) => game?.categoryId?.slug == type);

      // If a matching game is found and moduleId exists in accessObject[type], return true
      if (matchingGame && accessObject[type]?.includes(moduleId)) {
        return true;
      }
    }

    
    return false; // Default to no access
  };

  return { checkAccess };
};
