import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Main from './layouts/Main';
import NotFound from "./pages/NotFound";
import Signin2 from "./pages/Signin2";
import { ToastContainer } from 'react-toastify';
import SocketService from './service/api/socket.service'
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";

// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from "./service/api/auth.service";
import GameService from "./service/api/game.service";
import { setGames } from "./Redux/Action/games.action";
// import { Helmet, HelmetProvider } from "react-helmet-async";
import { setMetaData } from "./Redux/Action";

// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  const socketService = SocketService();
  const gameService = GameService();
  const authService = AuthService();
  const dispatch = useDispatch()
  // const metaData = useSelector(state => state.user.meta);
  
  // const isAuthenticated = localStorage.getItem("token");
  const user = useSelector((state) => state.user.user);
  console.log("==========logged in user=======", user);
  const PrivateRoute = ({ children }) => {
    const isAuthenticated = useSelector((state) => state.user.user);
    // const isAuthenticated = useSelector((state) => state.user.user); // Adjust this based on your state structure
    return isAuthenticated ? children : <Navigate to="/" />;
  };

  const AuthRoute = ({ children }) => {
    // const isAuthenticated = false;
    const isAuthenticated = useSelector((state) => state.user.user); // Adjust this based on your state structure
    return !isAuthenticated ? children : <Navigate to="/bet-statics" />;
  };

  const fetchGames = async () => {
    try {
       const res=await gameService.getGame({ page: 1, limit: 10, searchBy: "", category: "" });
      if (res.code === 200) {
        let game = [];
        game=res?.data?.docs[0]?.games;
        // console.log(game,"gamesssssssss-list")
        dispatch(setGames(game));
      }
      else {
        throw new Error(res.message);
      }
     } catch (error) {
      console.error(error);
     }
  };


  // const getMerchantStatus = useCallback(async () => {
  //   try {
  //     const res = await AuthService().getMetaData();
  //     // dispatch(setMerchantStatus(res?.status === 1 && res?.isDelete === 1));
  //     // dispatch(setMerchantLogo(res?.logo));
      
  //     dispatch(setMetaData({ title: res?.metaTitle, keyword: res?.metaKeywords, metaDescription: res?.metaDescription, logo: res?.logo }));
  //   } catch (error) {
  //     console.error('Error fetching merchant status:', error);
  //   }
  // }, [dispatch]);
  useEffect(() => {
    // getList();
    // getMerchantStatus();
    if (user) {
     
      fetchGames();
      authService.getLoginData()
    }
  }, [user]);
// console.log("redere")

  return (
    <React.Fragment>
      {/* <BrowserRouter> */}
        <Routes>
          <Route path="/" element={
            <AuthRoute>
              <Signin2 />
            </AuthRoute>
          } />
          {protectedRoutes?.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={
                  // isAuthenticated ? (
                  //   route.element
                  // )
                  //           : (
                  // <Navigate to="/signin" replace /> // Added 'replace' prop for navigation
                  // )
                  <PrivateRoute>
                    {route.element}
                  </PrivateRoute>
                }
                key={index}
              />
            )
          })}
          {publicRoutes?.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={
                  <AuthRoute>
                    {route.element}
                  </AuthRoute>
                }
                key={index}
              />
            )
          })}
          <Route path="*" element={<NotFound />} />
          {/* <Route path="/" element={<Signin2 />} /> */}

        </Routes>
      <ToastContainer />
      {/* <HelmetProvider>

          <Helmet>
            <meta name="description" content={metaData?.metaDescription || ""} />
            <meta name="keywords" content={metaData?.metaKeywords || ""} />
            <meta property="og:title" content={metaData?.title || ""} />
            <meta property="og:description" content={metaData?.metaDescription || ""} />
            <meta property="og:image" content={metaData?.logo || ""} />
            <meta property="og:url" content={window.location.origin || ""} />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={metaData?.title || ""} />
            <meta name="twitter:description" content={metaData?.metaDescription || ""} />
            <meta name="twitter:image" content={metaData?.logo || ""} />
            <meta name="twitter:site" content={window.location.origin || ""} />
          </Helmet>
        </HelmetProvider> */}
      {/* </BrowserRouter> */}
    </React.Fragment>

  );
}