import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
};

const loaderSlice = createSlice({
    name: 'loader',
    initialState,
    reducers: {
        startApiRequest(state, action) {
            state.loading = true;
        },
        endApiRequest(state, action) {
            state.loading = false;
        },
    },
});

export const { startApiRequest,endApiRequest } = loaderSlice.actions;
export default loaderSlice.reducer;
