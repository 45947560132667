import React, { useEffect, useRef, useState, useCallback } from "react";
import Header from "../../layouts/Header";
import TableWithPagination from "../../common/table";
import useSkinSwitcher from "../../common/skin";
import StaffService from "../../service/api/staff.service";
import TransactionService from "../../service/api/transaction.service";
import AuthService from "../../service/api/auth.service";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import PageBreadcrumb from "../../common/PageBreadcrum";
import formatDateTime from '../../helper/DateFormate'
// import "./staff.css";
// import { DateRange, DateRangePicker } from "react-date-range";
import { addDays, format } from "date-fns";

import capitalizeFirstLetter from "../../helper/Capitalise";
import Footer from "../../layouts/Footer";
import _ from "lodash";
// import { Link } from "react-router-dom";

import {
    Button,
    Card,
    Row,
    Col,
    Nav,
    ListGroup,
    Table,
    Badge,
} from "react-bootstrap";
import Sidebar from "../../layouts/Sidebar";
import { toast } from "react-toastify";
import UserService from "../../service/api/user.service ";
import GameBetHistoryService from "../../service/api/game-bet-history.service";
import { useDispatch, useSelector } from "react-redux";
import TableFilter from "../../common/filter";
import { startApiRequest } from "../../Redux/Action/loader.action";
import { useLocation, useSearchParams } from 'react-router-dom';
import { formatCurrency } from "../../helper/CurrencyFormate";
export default function Crash_Bet() {
    const location = useLocation();
    // const location = useLocation();
    const { id, status } = location.state || {};
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.state.loader);
    const theme = useSelector((state) => state.ui.skin);
    const staffService = StaffService();
    const userService = UserService();
    const transactionService = TransactionService();
    const betHistoryServie = GameBetHistoryService();
    const authService = AuthService();
    const navigate = useNavigate();
    const { skin, setSkin } = useSkinSwitcher();
    const [betHistoryList, setBetHistoryList] = useState([]);
    const [pagination, setPagination] = useState({
        searchBy: "",
        status: status || "",
        user: id || "",
        transactionType: "",
        page: 1,
        limit: 10,
        gameId: "",
    });
    const [isHidden, setIsHidden] = useState(false);
    const [userList, setUserList] = useState([]);
    const [state, setState] = useState([
        {
            startDate: null,
            // endDate: new Date(),
            endDate: null,
            key: "selection",
        },
    ]);


    const clearRange = () => {
        setState([{ startDate: null, endDate: null, key: "selection" }]);
        setShowPicker(false);
    };
    const [columns, setColumns] = useState([
        "gameId",
        "userId",
        "createdAt",
        "roundId",
        "_id",
        // "walletType",
        "openingBalance",
        "betAmount",
        "payoutMultiplier",
        "payoutAmount",
        "closingBalance",
        // "currencyId",
        "isWin",
    ]);

    const getWalletType = (type) => {
        // 0: Deposit, 1: Winning, 2: Bonus
        switch (type) {
            case 0: return 'Deposit';
            case 1: return 'Winning';
            case 2: return 'Bonus';
            default: return 'N/A';
        }
    }

    const getList = useCallback(async () => {
        const roundId = searchParams.get('round-id');
        console.log("roundId==>" + roundId)
        // dispatch(startApiRequest());
        const res = await betHistoryServie.getCrashBetHistory({
            page: pagination?.page,
            limit: pagination?.limit,
            searchBy: pagination?.searchBy?.trim(),
            status: pagination?.status ? pagination?.status : "",
            user: pagination?.user ? pagination?.user : '',
            fromDate: pagination?.fromDate ? pagination?.fromDate : '',
            toDate: pagination?.toDate ? pagination?.toDate : '',
            roundId: roundId ? roundId : '',
            gameId: pagination.gameId ? pagination.gameId : ""
        });
        if (res?.code === 200) {
            console.log("===>", res?.data?.docs);
            setBetHistoryList(res?.data?.docs);
            setPagination((prevPagination) => ({
                ...prevPagination,
                pages: res.data.pages,
                totalDocs: res.data.totalDocs,
                limit: res.data.limit,
            }));
        } else {
            console.error(res?.message);
            // toast.error(res?.message);
        }
    }, [
        pagination.page,
        pagination?.toDate,
        pagination?.status,
        pagination.user,
        pagination.searchBy,
        pagination.gameId,

        state,
        pagination.transactionType,
    ]);
    useEffect(() => {
        getList();
        // authService.getLoginData();

        // setPagination((preValue) => ({
        //     ...preValue,
        //     page: 1,
        // }));
    }, [
        pagination.page,
        pagination.toDate,
        pagination.status,
        pagination.user,
        pagination.searchBy,
        pagination.gameId,

        // getList,
        state,
        pagination.transactionType,
    ]);
    // Define HTML for status badges
    const getStatusBadge = (status) => {
        if (status) {
            return (
                <Badge bg={"success"} className="badge badge-success">
                    Won
                </Badge>
            );
        } else {
            return (
                <Badge bg={"pink"} className="badge badge-danger">
                    Lost
                </Badge>
            );
        }
    };

    // Map the columns to include HTML for status badges
    const columnsWithHTML = columns.map((column) => {
        if (column.toLowerCase() === "iswin") {
            return {
                header: "Status",
                accessor: column,
                Cell: ({ value }) => getStatusBadge(value), // Render status badge based on the value
            };
        } else if (column.toLowerCase() === "roundid") {
            return {
                header: "Round Id",
                accessor: column,
                Cell: ({ value }) => {
                    return value ? value : "--"
                }, // Render status badge based on the value
            };
        } else if (column.toLowerCase() === "_id") {
            return {
                header: "Bet Id",
                accessor: column,
                Cell: ({ value }) => {
                    return value ? value : "--"
                }, // Render status badge based on the value
            };
        }
        else if (column.toLowerCase() === "wallettype") {
            return {
                header: "Wallet Type",
                accessor: column,
                Cell: ({ value }) => {
                    return value ? getWalletType(value) : "--"
                }, // Render status badge based on the value
            };
        }

        else if (column.toLowerCase() === "userid") {
            return {
                header: "User",
                accessor: column,
                Cell: ({ value, row }) => {
                    // console.log("value", value, column);
                    return (
                        <p className="text-truncate m-0 mw-50">
                            <a className="cursor-pointer " onClick={() => {
                                const state = { id: row.userId.id };
                                navigate(`/user-management/view`, { state })
                            }}>{(value?.username)?.toUpperCase() || "N/A"}</a>
                        </p>
                    );
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "createdat") {
            return {
                header: "Date",
                accessor: column,
                Cell: ({ value }) => {
                    // console.log("value", value, column);
                    return (
                        <span className="text-nowrap">{formatDateTime(value) || "N/A"}</span>
                    )
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "gameid") {
            return {
                header: "Game",
                accessor: column,
                Cell: ({ value, row }) => {
                    // console.log("value-transaction", value, column);
                    // return value?.name || "N/A";
                    return (<p className="text-truncate m-0 mw-50">
                        <a className="cursor-pointer " onClick={() => {
                            const state = { id: row.gameId.id };
                            navigate(`/crash-history`, { state })
                        }}>{capitalizeFirstLetter(value?.name) || "N/A"}</a>
                    </p>)
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "betamount") {
            return {
                header: "Bet",
                accessor: column,
                Cell: ({ value, row }) => {
                    // console.log("row",row);
                    return (
                        <>
                                { <>{formatCurrency(value || '0', row?.currencyId?.currencyCodes)}</>}
                            
                            {/* {value ? <>{formatCurrency(value, row?.currencyId?.currencyCodes)}</> : '0.00'} */}
                        </>
                    )
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "openingbalance") {
            return {
                header: <span className="table-header-hover " title="Opening Balance">O.Balance</span>,
                accessor: column,
                Cell: ({ value, row }) => {
                    // console.log("value", value, column);
                    // return value?.toFixed(2) || "--";
                    return (
                        <>
                            <>
                                { <>{formatCurrency(value || '0', row?.currencyId?.currencyCodes)}</>}
                            </>
                        </>
                    )
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "closingbalance") {
            return {
                header: <span className="table-header-hover " title="Closing Balance">C.Balance</span>,
                accessor: column,
                Cell: ({ value, row }) => {
                    // console.log("value", value, column);
                    return (
                        <>
                            <>
                            { <>{formatCurrency(value || '0', row?.currencyId?.currencyCodes)}</>}
                                
                                {/* {value ? <>{formatCurrency(value, row?.currencyId?.currencyCodes)}</> : '0.00'} */}
                            </>
                        </>
                    )
                }, // Render action dropdown based on the value
            };
        }
        // else if (column.toLowerCase() === "roundid") {
        //     return {
        //         header: "Round Id",
        //         accessor: column,
        //         Cell: ({ value }) => {
        //             // console.log("value==Round", value, column);
        //             return value && value.length >0 ? value : "--";
        //         }, // Render action dropdown based on the value
        //     };
        // }
        else if (column.toLowerCase() === "payoutamount") {
            return {
                header: "Payout Amount",
                accessor: column,
                Cell: ({ value, row }) => {
                    console.log("value", value, column);
                    return (
                        <>
                                { <>{formatCurrency(value || '0', row?.currencyId?.currencyCodes)}</>}
                            
                            {/* {value ? <>{formatCurrency(value, row?.currencyId?.currencyCodes)}</> : '0.00'} */}
                        </>
                    )
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "payoutmultiplier") {
            return {
                header: "Cashout Multiplier",
                accessor: column,
                Cell: ({ value }) => {
                    console.log("value", value, column);
                    return Number(value)?.toFixed(2) || "0.00";
                }, // Render action dropdown based on the value
            };
        }
        // else if (column.toLowerCase() === "id") {
        //     return {
        //         header: "Actions",
        //         accessor: column,
        //         Cell: ({ value }) => getActionDropdown(value) // Render action dropdown based on the value
        //     };
        // }
        // else {
        //   return column;
        // }
    });

    const debouncedSearch = useCallback(
        _.debounce((value) => {
            setPagination((prevPagination) => ({
                ...prevPagination,
                searchBy: value,
            }));
        }, 300),
        []
    );

    const handleSearchChange = (event) => {
        const value = event.target.value.trim();
        debouncedSearch(value);
    };

    const [showPicker, setShowPicker] = useState(false);

    const handleRangeChange = (ranges) => {
        setState([ranges.selection]);
    };


    // useEffect(() => {
    //     const getUser = async () => {
    //         try {
    //             const res = await userService.getUserList();
    //             if (res?.code === 200) {
    //                 const formattedUserList = res.data.map((user) => ({
    //                     label: `${user.name} (${user.username})`,
    //                     value: user.id,
    //                 }));
    //                 setUserList(formattedUserList);
    //             }
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     };
    //     getUser();
    // }, []);

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <div className=" align-items-center">
                        <Card.Header>


                            <div className="d-flex justify-content-between align-items-center">

                                <PageBreadcrumb title="Game Bets" />
                                <div>
                                    <Button className="" onClick={() => setIsHidden(!isHidden)}>
                                        Filter
                                    </Button>
                                </div>
                            </div>
                            {/* <h1>Transactions</h1> */}



                            {/* </div> */}
                            <div className={` ${!isHidden ? 'd-none' : ''} `}>
                                {/* Search Input */}
                                <TableFilter
                                    handleRangeChange={handleRangeChange}
                                    handleSearchChange={handleSearchChange}
                                    setPagination={setPagination}
                                    setShowPicker={setShowPicker}
                                    // pagination={pagination}
                                    status={status}
                                    userId={id}
                                    state={state}
                                    tableType="betHistory"
                                    clearRange={clearRange}
                                    userList={userList}
                                    showPicker={showPicker}
                                />
                            </div>
                        </Card.Header>
                        <TableWithPagination
                            tableType={"transaction"}
                            data={[...betHistoryList]}
                            itemsPerPage={pagination?.limit}
                            columns={columnsWithHTML}
                            currentPage={pagination?.page}
                            totalPages1={pagination?.totalPages}
                            totalData={pagination?.totalDocs}
                            pagination={pagination}
                            setPagination={setPagination}
                            moduleId={7}


                        />
                    </div>
                </Card>
            </div>
        </React.Fragment>
    );
}
