import { combineReducers } from '@reduxjs/toolkit';
// import uiReducer from '../action/theme.action'; // Import the reducer from actions.js
import userReducer from '../Action/auth.action'
import { skinReducer,loaderReducer, gameReducer } from '../Action'


const rootReducer = combineReducers({
  ui: skinReducer,
  user: userReducer,
  state: loaderReducer,
  games:gameReducer
});

export default rootReducer;
