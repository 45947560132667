import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  meta:{},
  isLoading: false,
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setMetaData: (state, action) => {
      state.meta = action.payload;
      },
    //  user registration
    registerRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    registerSuccess: (state, action) => {
      console.log("actionnnnnn", action)
      state.isLoading = false;
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload))
    },
    registerFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
        },
    
    // user login
    loginRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload))
    },
      loginFailure: (state, action) => {
      console.log(action.payload,"redux saved error")
      state.isLoading = false;
      state.error = action.payload;
        },
    
    // user logout
    logoutRequest: (state) => {
      state.isLoading = true;
      localStorage.removeItem('authToken');
      state.error = null;
    },
    logoutSuccess: (state) => {
      state.isLoading = false;
      state.user = null;
      localStorage.removeItem('user');
    },
    logoutFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    updateUserData: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload))
    },
  },
});

export const {
  updateUserData,
  setMetaData,
  registerRequest,
  registerSuccess,
  registerFailure,
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutRequest,
  logoutSuccess,
  logoutFailure,
} = userSlice.actions;



export default userSlice.reducer;
