import React from "react";
import { Card, Col, Form, Row, Button, Badge } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import useSkinSwitcher from "../../common/skin";
import PageBreadcrumb from '../../common/PageBreadcrum';

const ViewUserWalletDetails = () => {
    // Destructure relevant properties from userDetails
    const { skin, setSkin } = useSkinSwitcher();
    const location = useLocation();
    const { id } = location.state;
    const userDetails = {
        "status": 3,
        "isDelete": 1,
        "userId": "665814fd29fe07fb6f6a9ab4",
        "currencyId": {
            "status": 1,
            "isDelete": 1,
            "name": "Indian rupee",
            "currencySymbols": "₨",
            "currencyCodes": "INR",
            "createdAt": "2024-05-27T16:41:24.914Z",
            "id": "6654b7b4d65398b3f2bc5ec6"
        },
        "amount": "123",
        "transactionType": "credit",
        "createdAt": "2024-06-03T06:58:49.468Z",
        "id": "665d69a927c3fc442b2ae23b"
    }

    const { status, isDelete, userId, currencyId, amount, transactionType, createdAt, } = userDetails;

    // Format the date for display
    const formattedDate = new Date(createdAt).toLocaleDateString();
    const getStatusBadge = (status) => {
        if (status === 1) {
          return (
            // <Badge bg={"success"} className="badge badge-success">
              "Sucess"
            // </Badge>
          );
        } else if (status === 0) {
          return (
            // <Badge bg={"pink"} className="badge badge-danger">
              "Failed"
            // </Badge>
          );
        } else if (status === 3) {
          return (
            // <Badge bg={"warning"} className="badge badge-warning">
              "Initiated"
            // </Badge>
          );
        }
    };
    
    const formatDateTime = (createdAt) => {
        const date = new Date(createdAt);

        const day = String(date.getDate()).padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        const dateString = `${day}-${month}-${year}`;

        const timeString = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true });

        // return { date: dateString, time: timeString };
        return `${dateString}/${timeString}`
    };
    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <div className=" align-items-center mb-4">
                    <Card>
                        <Card.Header>
                                <PageBreadcrumb title="User Wallet Details" />
                                {/* <h4 className="card-title w-100">User Wallet Details</h4> */}
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <Row>
                                    <Col md={6} className="mt-3">
                                        <Form.Group controlId="userId">
                                            <Form.Label>User ID</Form.Label>
                                            <Form.Control type="text" value={userId} disabled />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mt-3">
                                        <Form.Group controlId="transactionId">
                                            <Form.Label>Transaction ID</Form.Label>
                                            <Form.Control type="text" value={id} disabled />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="mt-3">
                                        <Form.Group controlId="status">
                                            <Form.Label>Status</Form.Label>
                                            <Form.Control type="text" value={getStatusBadge(status)} disabled />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mt-3">
                                        <Form.Group controlId="isDeleted">
                                            <Form.Label>Is Deleted</Form.Label>
                                            <Form.Control type="text" value={isDelete === 1 ? "Yes" : "No"} disabled />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6} className="mt-3">
                                        <Form.Group controlId="currency">
                                            <Form.Label>Currency</Form.Label>
                                            <Form.Control type="text" value={currencyId.name} disabled />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className="mt-3">
                                        <Form.Group controlId="amount">
                                            <Form.Label>Amount</Form.Label>
                                            <Form.Control type="text" value={amount} disabled />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mt-3">
                                        <Form.Group controlId="transactionType">
                                            <Form.Label>Transaction Type</Form.Label>
                                            <Form.Control type="text" value={transactionType} disabled />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="mt-3">
                                        <Form.Group controlId="createdAt">
                                            <Form.Label>Transaction Date</Form.Label>
                                            <Form.Control type="text" value={formatDateTime(createdAt)} disabled />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ViewUserWalletDetails;
