import { useEffect, useState } from 'react';

const useSkinSwitcher = () => {
  const currentSkin = localStorage.getItem('skin-mode') ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    console.log(skin,'hello mode')
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');
      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return { skin, setSkin };
};

export default useSkinSwitcher;
