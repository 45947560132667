import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import RoleService from "../../service/api/role.service";
import UserService from "../../service/api/user.service ";
import GameService from "../../service/api/game.service";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import MerchantService from "../../service/api/merchant.service";
import ReactSelect from "react-select";
import { darkModeStyles2, lightModeStyles2 } from "../../common/reactSelectStyles";
import { useSelector } from "react-redux";

const EditNewGame = () => {
  const theme = useSelector((state) => state.ui.skin);
  const { skin, setSkin } = useSkinSwitcher();
  const roleService = RoleService();
  const merchantService = MerchantService();
  const gameService = GameService();
  const location = useLocation();
  const [bannerImage,setBannerImage] = useState(null);

  const { id } = location.state;

  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [roleOption, setRoleOption] = useState([]);
  const [errors, setErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState({
    // role: "",
    merchant: [],
    name: "",
    // phone: "",
    // email: "",
    // password: "",
  });
  const [merchantList, setMerchantList] = useState([]);
  const [merchantOption, setMerchantOption] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedResult, setSelectedResult] = useState([]);
  const [selectedCategory,setSelectedCategory]=useState([]);
  const [underMaintainanceOptions, setunderMaintainanceOptions] = useState([{value: true, label: "Yes"}, {value: false, label: "No"}])
  const [underMaintainance, setunderMaintainance] = useState([])
  
  const getMerchantList = async () => {
    const res = await merchantService.getAllMerchants();
    if (res?.code === 200) {
      setMerchantList(res.data);
      let data = res.data.map((item) => ({ value: item.id, label: item.name }));
      setMerchantOption(data);
    }
    console.log(merchantList, " and ", merchantOption)
  }

  const getCategory = async () => {
    const res = await gameService.getCategoryList();
    if (res?.code === 200) {
      
      let data = res.data.map((item) => ({ value: item.id, label: item.name }));
      setCategoryList(data);
    }
    // console.log(merchantList, " and ", merchantOption)
  }

  useEffect(() => {
    getCategory();
    getMerchantList();
  }, []);

  useEffect(() => {
    getStaffDetail();
  }, [merchantOption]);


  const getStaffDetail = async () => {
    const res = await gameService.getGameById(id);
    if (res?.code === 200) {
      const result = merchantOption?.filter(option => res.data?.merchant?.includes(option.value));
      const category = categoryList?.filter(option => res.data.category?.id==option.value)

      console.log(category,"Helloooo======================",res.data)
      setFormData(res?.data);
      setSelectedResult(result);
      setSelectedCategory(category);
      setImagePreview(res?.data?.bannerImage? `${process.env.REACT_APP_IMAGE_URL}/${res?.data?.bannerImage}`:null);
      setBannerImage(res?.data?.bannerImage)

      // const maintainanceObj = {
      //   true:{ value: true, label: "True" },
      //   false:{value: false, label: "False"}
      // }
      const value = underMaintainanceOptions.filter((item) => item.value === res?.data?.underMaintenance);
      // console.log(value,"hellllllllllllllllllllllll",res?.data?.underMaintenance)
      setunderMaintainance(value);
      // let data = res.data.map((item) => ({ value: item.id, label: item.name }));
      // setRoleOption(data);
    }
  };


  

  const handleSelectChange = (items) => {
    console.log(items, 'form item');
    const valuesArray = items ? items?.map(item => item.value) : [];
    // setSelectedValues(valuesArray);
    setFormData((preValue) => ({
      ...preValue,
      merchant: valuesArray
    }));
    setShowError(false);
    setSelectedResult(items);
   
  };
  const handleMaintainanceSelectChange = (items) => {
    console.log(items, 'form item');
    // const valuesArray = items ? items?.map(item => item.value) : [];
    // setSelectedValues(valuesArray);
    setFormData((preValue) => ({
      ...preValue,
      underMaintenance: items.value
    }));
    setShowError(false);
    setunderMaintainance(items);
    
  };
  


  const handleChangeCategory=(items) => {
    // console.log(items, 'form item');
    // const valuesArray = items ? items?.map(item => item.value) : [];
    // setSelectedValues(valuesArray);
    // setFormData((preValue) => ({
    //   ...preValue,
    //   category: items.value
    // }));
    setShowError(false);
    setSelectedCategory([items]);
    console.log(items,"item==============")

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("{ ...errors, [name]: errorMessage }", name, value);

    let errorMessage = "";

    // Perform validation based on the field name
    switch (name) {
      case "name":
        if (!value.trim()) {
          errorMessage = "Name is required.";
        } else if (!/^[a-zA-Z0-9\s]*$/.test(value)) {
          errorMessage = "Name is required";
        } else {
          errorMessage = "";
        }
        break;
      case "merchant":
        if (!value.length) {
          errorMessage = "Merchant is required.";
        } else {
          errorMessage = "";
        }
        break;
      // Add validation for other fields as needed
      default:
        break;
    }

    setFormData({ ...formData, [name]: value });
    // showError(false)
    setErrors({ ...errors, [name]: errorMessage });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add your form submission logic here
    // Perform validations
    console.log("formData", formData);
    const validationErrors = {};

    if (formData.name.trim() == "") {
      validationErrors.name = "Name is required";
    } else if (!/^[a-zA-Z0-9\s]*$/.test(formData.name)) {
      validationErrors.name = "Name should be alphanumeric";
    } else {
      validationErrors.name = "";
    }
    // if (formData.merchant?.length === 0) {
    //   validationErrors.merchant = "Merchant is required";
    // } else {
    //   validationErrors.merchant = "";
    // }
    // Add more password validation logic as needed
    console.log("validationErrors", Object.values(validationErrors));

    if (Object.values(validationErrors).every((item) => item === "")) {
      // No errors, proceed with form submission
      console.log("resss===");
      setShowError(false);
      console.log("formData", formData);
       // Create a new FormData object
       const formDataToSend = new FormData();
        
       // Append form fields to FormData
      formDataToSend.append("name", formData.name);
      // if (selectedCategory[0]?.value) {
        formDataToSend.append("category", selectedCategory[0]?.value || "");
      // }
      
      //  formDataToSend.append("merchant", JSON.stringify(formData.merchant));
       formDataToSend.append("underMaintenance", JSON.stringify(formData.underMaintenance));
       formDataToSend.append("gameDescription", formData.gameDescription);
       if (formData.merchant.length !== 0) {
        formDataToSend.append("merchant", JSON.stringify(formData.merchant));
       }
       // Append file if it exists
       if (formData.image) {
           formDataToSend.append("image", formData.image);
       }
      const res = await gameService.updateUser(id, formDataToSend);
      console.log("resss===", res);
      if (res?.code == 200) {
        toast.success(res?.message);
        navigate("/game-management");
      } else {
        toast.error(res?.message);
      }
    } else {
      // Set validation errors
      setShowError(true);
      setErrors(validationErrors);
    }
  };

  const handleRoleChange = (selectedOption) => {
    let validationErrors = "";
    if (selectedOption.value == "") {
      validationErrors = "Role is required";
    } else {
      validationErrors = "";
    }
    setShowError(true);
    setErrors({ ...errors, role: validationErrors });
    setFormData({ ...formData, role: selectedOption.value });
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#192030", // Background color
      color: "#A8A2A2", // Text color
      borderColor: state.isFocused ? "#1F283D" : "#1F283D", // Border color
      boxShadow: state.isFocused ? "0 0 0 1px #fff" : "#1E273A", // Box shadow
      "&:hover": {
        borderColor: "#1E273A", // Border color on hover
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#192030"
        : state.isFocused
        ? "#1F283D"
        : "#141926", // Option background color
      color: "#A8A2A2", // Option text color
      "&:hover": {
        backgroundColor: "#1E273A", // Option background color on hover
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#333", // Menu background color
      color: "#A8A2A2", // Menu text color
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#A8A2A2", // Single value text color
    }),
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      formData.image = file;
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Set the preview image URL
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <div className=" align-items-center mb-4">
          <Card>
            <Card.Header>
              <h4 className="card-title w-100">Edit Game</h4>
            </Card.Header>
            <Card.Body>
              <div className="d-flex justify-content-center"> 

              <Col md={6}>
              <img src={imagePreview?imagePreview:'../images/card-image-placeholder.png'} style={{ maxWidth: '100%' }}  />
              </Col>
              </div>
              <Form className="row" onSubmit={handleSubmit}>
              <Col md={4} className="mt-3">
                  <Form.Group controlId="name">
                    <Form.Label>Banner Image</Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="Enter name"
                      name="image"
                      onChange={handleImageChange}
                      // value={formData.name}
                      // onChange={handleChange}
                      // isInvalid={showError && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      isInvalid={showError && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                {/* <Col md={4} className="mt-3">
                  <Form.Group controlId="name">
                    <Form.Label>Merchant</Form.Label>
                   
                    <ReactSelect
                      styles={
                        theme === "dark" ? darkModeStyles2 : lightModeStyles2
                      }
                      value={selectedResult}
                      name="merchant"
                      options={merchantOption}
                      isMulti
                      onChange={handleSelectChange}
                    />
                    
                  </Form.Group>
                </Col> */}

                <Col md={4} className="mt-3">
                  <Form.Group>
                    <Form.Label>Game Id</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter gameUnique"
                      name="gameUnique"
                      value={formData.gameUnique || ""}
                      disabled={true}
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group>
                    <Form.Label>Under Maintenance</Form.Label>
                    <ReactSelect
                      styles={
                        theme === "dark" ? darkModeStyles2 : lightModeStyles2
                      }
                      value={underMaintainance}
                      name="underMaintainance"
                      options={underMaintainanceOptions}
                      onChange={handleMaintainanceSelectChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="category">
                    <Form.Label>Category</Form.Label>

                    <ReactSelect
                       value={selectedCategory}
                      styles={theme === 'dark' ? darkModeStyles2 : lightModeStyles2}
                      name="category"
                      options={categoryList}
                      onChange={handleChangeCategory}

                    />
                    {/* <Form.Text className="text-danger small">
                    
                      {errors.merchant !== "" && showError && "Merchant is required"}
                    </Form.Text> */}

                    {/* <Form.Control.Feedback type="invalid">
                  {errors.merchant}
                </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
                <Col md={4} className="mt-3">
                  <Form.Group controlId="gameDescription">
                  <Form.Label>Game Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="gameDescription"
                    placeholder="Enter game description"
                    value={formData.gameDescription}
                    onChange={handleChange}
                    rows={5} // Adjust the number of rows to control the height of the textarea
                    isInvalid={errors.gameDescription !== "" && showError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.gameDescription !== "" && showError && "Game description is required"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
                <div className="d-flex justify-content-center mt-5">
                  <Button variant="primary" type="submit" className="mx-4">
                    Submit
                  </Button>
                  <Button
                    variant="danger"
                    type="button"
                    onClick={() => navigate("/game-management")}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditNewGame;
