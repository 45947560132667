
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import useSkinSwitcher from "../../common/skin";
import LotteryService from "../../service/api/lottery.service";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CurrencyService from "../../service/api/currency.service";
import { format } from "date-fns";
import { BorderSpinner } from "../../common/Spinner";
import InfoComponent from "./Info";
import Swal from "sweetalert2";

const EditLottery = () => {
    const { skin, setSkin } = useSkinSwitcher();
    const lotteryService = LotteryService();
    const currencyService = CurrencyService();
    const [currencyList, setCurrencyList] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const location = useLocation();
    const { id } = location.state;
    const navigate = useNavigate();
    const [showError, setShowError] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        name: "",
        type: "",
        frequency: "",
        nextDraw: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
        cutOffTime: "",
        startDate: "",
        endDate: "",
        ticketPrice: "",
        currencyId: "",
        adminProfitPercent: "",
        prizePool: 100,

        payoutMethod: "instant",
        isActive: 1,
        prizeDistribution: {
            jackpotPercent: "",
            fiveMatchPercent: "",
            fourMatchPercent: "",
            threeMatchPercent: "",
            twoMatchPercent: "",
            oneMatchPercent: "",
        },
        defaultPrizes: [{ prizeType: "cash", prizeValue: "", prizeDescription: "" }],
    });


    const getCurrencyList = async () => {
        try {
            const res = await currencyService.getCurrencyList({
                page: 1,
                limit: 20,
                searchBy: ''
            });
            if (res?.code === 200) {
                setCurrencyList(res?.data?.docs);
                console.log(res, "hey");
            } else {
                console.error(res?.message);
                // toast.error(res?.message || "Failed to fetch user list.");
            }
        } catch (error) {
            console.error("Error fetching user list:", error);
            // toast.error("An unexpected error occurred while fetching user list.");
        }
    }

    const getLotteryInfo = async () => {
        try {
            const res = await lotteryService.getLotteryById(id);
            if (res?.code === 200) {

                const lotteryData = res.data;

                // Format the cutOffTime to "yyyy-MM-dd'T'HH:mm"
                const formattedCutOffTime = format(new Date(lotteryData.cutOffTime), "yyyy-MM-dd'T'HH:mm");
                const formattedNextDrawTime = format(new Date(lotteryData.nextDraw), "yyyy-MM-dd'T'HH:mm");
                const formattedEndTime = format(new Date(lotteryData.endDate), "yyyy-MM-dd'T'HH:mm");
                const formattedStartDate = format(new Date(lotteryData.startDate), "yyyy-MM-dd'T'HH:mm");

                const id = res.data?.currencyId?.id

                // Set the form data with the formatted cutOffTime
                setFormData({
                    ...lotteryData,
                    currencyId: id,
                    cutOffTime: formattedCutOffTime,
                    nextDraw: formattedNextDrawTime,
                    endDate: formattedEndTime,
                    startDate: formattedStartDate,


                });
            }
        } catch (error) {
            console.error("Error fetching lottery details:", error);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getLotteryInfo();
        getCurrencyList();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        // setFormData({ ...formData, [name]: value });
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
            // Automatically set frequency to match the type when type is changed
            ...(name === 'type' ? { frequency: value } : {})
        }));
        setErrors({ ...errors, [name]: "" });
    };

    const handlePrizeDistributionChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            prizeDistribution: { ...formData.prizeDistribution, [name]: value },
        });
        // Remove error only if it exists for the specific field
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            console.log(`prizeDistribution`, name, updatedErrors)

            // If prizeDistribution errors exist, check and remove the specific error
            if (updatedErrors && updatedErrors[name]) {
                delete updatedErrors[name]; // Simply delete the error for the updated field
            }

            // Return the updated error state
            return updatedErrors;
        });

    };

    const handleDefaultPrizeChange = (index, e) => {
        const { name, value } = e.target;
        const updatedPrizes = formData.defaultPrizes.map((prize, i) =>
            i === index ? { ...prize, [name]: value } : prize
        );
        setFormData({ ...formData, defaultPrizes: updatedPrizes });
        // Ensure that the error structure aligns with your needs
        // Clear the corresponding error if present
        const errorKey = `defaultPrize-${index}-${name}`;
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[errorKey]; // Remove the specific error for this field
            return updatedErrors;
        });
    };

    const addDefaultPrize = () => {
        setFormData({
            ...formData,
            defaultPrizes: [...formData.defaultPrizes, { prizeType: "cash", prizeValue: "", prizeDescription: "" }],
        });
    };

    const removeDefaultPrize = (index) => {
        const updatedPrizes = formData.defaultPrizes.filter((_, i) => i !== index);
        setFormData({ ...formData, defaultPrizes: updatedPrizes });
    };

    const validateNextDraw = (type, nextDraw) => {
        const now = new Date();
        const oneDay = 24 * 60 * 60 * 1000;
        const nextDrawDate = new Date(nextDraw);
        if (type === 'daily') {
            if ((nextDrawDate - now) > oneDay || nextDrawDate <= now) {
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid Next Draw Date',
                    text: 'For a daily lottery, the Next Draw should be within the next 24 hours.'
                });
                return false;
            }
        }

        if (type === 'weekly') {
            if ((nextDrawDate - now) > (7 * oneDay) || nextDrawDate <= now) {
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid Next Draw Date',
                    text: 'For a weekly lottery, the Next Draw should be within the upcoming week.'
                });
                return false;
            }
        }

        if (type === 'monthly') {
            const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
            if (nextDrawDate <= now || nextDrawDate > nextMonth) {
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid Next Draw Date',
                    text: 'For a monthly lottery, the Next Draw should be within the next month.'
                });
                return false;
            }
        }

        return true;
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            setIsSubmitting(true);
            const validationErrors = {};
            console.log(formData, "form data here")
            // Validation for required fields
            if (!formData.name.trim()) {
                validationErrors.name = "Name is required";
            }
            if (!formData.type.trim()) {
                validationErrors.type = "Type is required";
            }
            if (!formData.frequency.trim()) {
                validationErrors.frequency = "Frequency is required";
            }
            if (!formData.nextDraw.trim()) {
                validationErrors.nextDraw = "Next draw date is required";
            }
            if (!formData.cutOffTime.trim()) {
                validationErrors.cutOffTime = "Cut-off time is required";
            }
            if (!formData.startDate.trim()) {
                validationErrors.startDate = "Start date is required";
            }
            if (!formData.endDate.trim()) {
                validationErrors.endDate = "End date is required";
            }
            if (!formData.payoutMethod) {
                validationErrors.payoutMethod = "Payout method is required";
            }
            if (!formData.ticketPrice) {
                validationErrors.ticketPrice = "Ticket price is required";
            }
            else if (isNaN(formData.ticketPrice) || formData.ticketPrice <= 0) {
                validationErrors.ticketPrice = "Ticket price must be a positive number.";
            }
            if (!formData.currencyId) {
                validationErrors.currencyId = "Currency is required";
            }
            if (!formData.adminProfitPercent) {
                validationErrors.adminProfitPercent = "Admin profit percentage is required";
            }
            else if (formData.adminProfitPercent <= 0 || formData.adminProfitPercent > 100) {
                validationErrors.adminProfitPercent = "Admin profit percentage must be between 1 and 100.";
            }


            if (!formData.prizePool) {
                validationErrors.prizePool = "Prize pool is required";
            } else if (isNaN(formData.prizePool) || formData.prizePool <= 0) {
                validationErrors.prizePool = "Prize pool must be a positive number.";
            }

            const nextDrawDate = new Date(formData.nextDraw);
            const cutOffTimeDate = new Date(formData.cutOffTime);
            if (cutOffTimeDate >= nextDrawDate) {
                validationErrors.cutOffTime = "Cut-off time must be before the next draw date.";
            }



            formData.defaultPrizes.forEach((prize, index) => {
                if (!prize.prizeType) {
                    validationErrors[`defaultPrize-${index}-prizeType`] = "Prize type is required";
                }
                if (!prize.prizeValue || prize.prizeValue <= 0) {
                    validationErrors[`defaultPrize-${index}-prizeValue`] = "Prize value must be greater than 0";
                }
                if (!prize.prizeDescription.trim()) {
                    validationErrors[`defaultPrize-${index}-prizeDescription`] = "Prize description is required";
                }
                if (prize?.prizeType==='other' && !prize?.customPrizeType?.trim()) {
                    validationErrors[`defaultPrize-${index}-customPrizeType`] = "Prize type is required";
                }
            });

            let prizeTotal = 0;
            ["jackpotPercent", "fiveMatchPercent", "fourMatchPercent", "threeMatchPercent", "twoMatchPercent", "oneMatchPercent"].forEach(category => {
                const percent = formData.prizeDistribution[category];
                if (percent === '') {
                    validationErrors[category] = `${category
                        .replace("Percent", " ")

                        .replace("Match", " Match")

                        .trim()

                        .charAt(0).toUpperCase() + category

                            .replace("Percent", " %")

                            .replace("Match", " Match")

                            .trim()

                            .slice(1)} is required`;
                }
                else if (percent < 0 || percent > 100) {

                    validationErrors[category] = `${category
                        .replace("Percent", " ")

                        .replace("Match", " Match")

                        .trim()

                        .charAt(0).toUpperCase() + category

                            .replace("Percent", " %")

                            .replace("Match", " Match")

                            .trim()

                            .slice(1)} must be in between 0 to 100`;

                }
                prizeTotal += Number(percent);
            });

            console.log(validationErrors, "validation errors")
            
            // Check for errors before submission
            if (Object.keys(validationErrors).length > 0) {
                setErrors(validationErrors);
                setShowError(true);
                return;
            }

            if (!validateNextDraw(formData.type, formData.nextDraw)) {
                return;
            }
            if (prizeTotal !== 100) {
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid Prize Distribution',
                    text: 'Prize distribution must add up to 100%.'
                });
                // toast.warn('Prize distribution must add up to 100%');
                // validationErrors.prizeDistributionTotal = "Prize distribution must add up to 100%";
                return;
            }
         
            delete formData['id'];
            // Submit form data
            const res = await lotteryService.updateLottery(id, formData);
            console.log(res, "response");
            if (res?.code === 200) {
                toast.success(res?.message);
                navigate("/lottery-list");
            } else if (res?.code === 400) {
                toast.error(res?.message);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const nextDrawDate = new Date(formData.nextDraw);
    const minDate = new Date(nextDrawDate);
    minDate.setDate(minDate.getDate() - 1);

    // Format the date to 'YYYY-MM-DDTHH:MM'
    const minDateString = minDate?.toISOString().slice(0, 16); // This gives you the format 'YYYY-MM-DDTHH:MM'
    if (isLoading) {
        return <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4  ">
                <div className="d-flex mt-5 justify-content-center align-content-center">

                    <BorderSpinner />
                </div>

            </div>
        </React.Fragment>
    }
    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <div className="align-items-center mb-4">
                    <Card>
                        <Card.Header>
                            <h4 className="card-title w-100">Edit Lottery Details</h4>
                        </Card.Header>
                        <Card.Body>
                            <Form className="row" onSubmit={handleSubmit}>
                                {/* Basic Information */}
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="name">
                                        <Form.Label>Name</Form.Label>
                                        <InfoComponent message={`Provide a unique name for this lottery (e.g., 'Weekly Super Jackpot' or 'Monthly Mega Win'). This helps in identifying the lottery type for users.`} />

                                        <Form.Control
                                            type="text"
                                            placeholder="Enter name"
                                            name="name"
                                            maxLength={100}
                                            value={formData.name}
                                            onChange={handleChange}
                                            isInvalid={showError && !!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="type">
                                        <Form.Label>Type</Form.Label>
                                        <InfoComponent message={`Select the type of lottery, such as 'Daily', 'Weekly', or 'Monthly'. Each type may have different rules or prize structures based on your settings.`} />

                                        <Form.Select
                                            name="type"
                                            value={formData.type}
                                            onChange={handleChange}
                                            isInvalid={showError && !!errors.type}
                                        >
                                            <option value="">Select Type</option>
                                            <option value="daily">Daily</option>
                                            <option value="weekly">Weekly</option>
                                            <option value="monthly">Monthly</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.type}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="frequency">
                                        <Form.Label>Frequency</Form.Label>
                                        <InfoComponent message={`Select how often this lottery will occur. Options could include 'Daily', 'Weekly' or 'Monthly'. The frequency determines how often a draw is held.`} />

                                        <Form.Select
                                            name="frequency"
                                            value={formData.frequency}
                                            onChange={handleChange}
                                            isInvalid={showError && !!errors.frequency}
                                            disabled
                                        >
                                            <option value="">Select Frequency</option>
                                            <option value="daily">Daily</option>
                                            <option value="weekly">Weekly</option>
                                            <option value="monthly">Monthly</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.frequency}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                {/* Draw and Cutoff Times */}
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="nextDraw">
                                        <Form.Label>Next Draw</Form.Label>
                                        <InfoComponent message={`Set the date and time for the next draw. This is the scheduled moment when the winning numbers are announced.`} />

                                        <Form.Control
                                            type="datetime-local"
                                            name="nextDraw"
                                            value={formData.nextDraw}
                                            onChange={handleChange}
                                            isInvalid={showError && !!errors.nextDraw}
                                            min={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.nextDraw}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="cutOffTime">
                                        <Form.Label>Cut-Off Time</Form.Label>
                                        <InfoComponent message={`Specify the cut-off time for ticket sales, usually a few hours before the draw to allow time for preparation. Ensure this is clear to users, as tickets will be sold up to this time. For example, if the draw is at 9:00 PM, the cut-off time might be 8:00 PM.`} />

                                        <Form.Control
                                            type="datetime-local"
                                            name="cutOffTime"
                                            value={formData.cutOffTime}
                                            onChange={handleChange}
                                            isInvalid={showError && !!errors.cutOffTime}
                                            min={minDateString}
                                            max={formData.nextDraw}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.cutOffTime}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="startDate">
                                        <Form.Label>Start Date</Form.Label>
                                        <InfoComponent message={`Enter the date when this lottery officially begins. This is when users can start purchasing tickets.`} />

                                        <Form.Control
                                            type="datetime-local"
                                            name="startDate"
                                            value={formData.startDate}
                                            onChange={handleChange}
                                            isInvalid={showError && !!errors.startDate}
                                            min={format(new Date(), "yyyy-MM-dd'T'HH:mm")}
                                            max={formData.cutOffTime}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.startDate}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="endDate">
                                        <Form.Label>End Date</Form.Label>
                                        <InfoComponent message={`Specify the date when this lottery ends.`} />

                                        <Form.Control
                                            type="datetime-local"
                                            name="endDate"
                                            value={formData.endDate}
                                            onChange={handleChange}
                                            isInvalid={showError && !!errors.endDate}
                                            min={formData.nextDraw}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.endDate}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                {/* Ticket Price and Currency */}
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="ticketPrice">
                                        <Form.Label>Ticket Price</Form.Label>
                                        <InfoComponent message={`Enter the cost of a single ticket in the selected currency. This is the price users will pay per entry. Be sure to set this based on your target audience and the expected prize pool.`} />

                                        <Form.Control
                                            type="number"
                                            placeholder="Enter ticket price"
                                            name="ticketPrice"
                                            value={formData.ticketPrice}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if ((value >= 0 && value<=999999) || value === '') {
                                                    handleChange(e);
                                                }
                                            }}
                                            isInvalid={showError && !!errors.ticketPrice}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ticketPrice}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="currencyId">
                                        <Form.Label>Currency</Form.Label>
                                        <Form.Select
                                            name="currencyId"
                                            value={formData.currencyId}
                                            // defaultValue={{value}}
                                            onChange={handleChange}
                                            isInvalid={showError && !!errors.currencyId}
                                        >
                                            <option value="">Select Currency</option>
                                            {currencyList?.map((item, ind) => (
                                                <option key={ind} value={item.id}>{item.currencyCodes}</option>
                                            ))}
                                            {/* <option value="USD">USD</option>
                                            <option value="EUR">EUR</option> */}
                                            {/* Add other currency options as needed */}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.currencyId}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                {/* Admin Profit Percentage */}
                                <Col md={4} className="mt-3">
                                    <Form.Group controlId="adminProfitPercent">
                                        <Form.Label>Admin Profit %</Form.Label>
                                        <InfoComponent message={`Enter the percentage of each ticket sale allocated as profit for the admin. For instance, a 5% admin profit means 5% of the ticket sales revenue goes to the admin, and the rest contributes to the prize pool.`} />

                                        <Form.Control
                                            type="number"
                                            placeholder="Enter profit percentage"
                                            name="adminProfitPercent"
                                            value={formData.adminProfitPercent}
                                            min={0}
                                            max={100}
                                            // onChange={handleChange}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if ((value >= 0 && value<=100) || value === '' ) {
                                                    handleChange(e);
                                                }
                                            }}
                                            isInvalid={showError && !!errors.adminProfitPercent}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.adminProfitPercent}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                                {/* Prize Pool */}
                                {/* <Col md={4} className="mt-3">
                                    <Form.Group controlId="prizePool">
                                        <Form.Label>Prize Pool</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter prize pool amount"
                                            name="prizePool"
                                            value={formData.prizePool}
                                            onChange={handleChange}
                                            isInvalid={showError && !!errors.prizePool}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.prizePool}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col> */}
                                {/* <Col md={4} className="mt-3">
                                    <Form.Group controlId="payoutMethod">
                                        <Form.Label>Payout Method</Form.Label>
                                        <Form.Select
                                            name="payoutMethod"
                                            value={formData.payoutMethod}
                                            onChange={handleChange}
                                            isInvalid={showError && !!errors.payoutMethod}
                                        >
                                            <option value="">Select Payout Method</option>
                                            <option value="instant">Instant</option>
                                            <option value="manual">Manual</option>
                                            
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.payoutMethod}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col> */}

                                {/* Prize Distribution */}
                                <Col md={12} className="mt-3">
                                    <h5>Prize Distribution</h5>
                                </Col>
                                {["jackpotPercent", "fiveMatchPercent", "fourMatchPercent", "threeMatchPercent", "twoMatchPercent", "oneMatchPercent"].map((category, index) => (
                                    <Col md={4} className="mt-3" key={index}>
                                        <Form.Group controlId={category}>
                                            <Form.Label>
                                                {
                                                    category
                                                        .replace("Percent", " ")

                                                        .replace("Match", " Match")

                                                        .trim()

                                                        .charAt(0).toUpperCase() + category

                                                            .replace("Percent", " ")

                                                            .replace("Match", " Match")

                                                            .trim()

                                                            .slice(1)} %
                                            </Form.Label>
                                            {index == 0 && <InfoComponent message={`Enter the percentage of the prize pool that will be awarded to the jackpot winner (the player who matches all numbers). For example, 50% means half of the prize pool is given as the jackpot.`} />}

                                            {/* <Form.Label>{category.replace("Percent", " ").replace("Match"," Match")} %</Form.Label> */}
                                            <Form.Control
                                                type="number"
                                                placeholder={`Enter ${category
                                                    .replace("Percent", " ")

                                                    .replace("Match", " match")
                                                    } %`}
                                                name={category}
                                                value={formData.prizeDistribution[category]}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if ((value >= 0 && value<=100) || value === '') {
                                                        handlePrizeDistributionChange(e);
                                                    }
                                                }}
                                                isInvalid={showError && !!errors[category]}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors[category]}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                ))}

                                {/* Default Prizes */}
                                <Col md={12} className="mt-3">
                                    <h5>Default Prizes</h5>
                                    {formData.defaultPrizes.map((prize, index) => (
                                        <div key={index} className={`row ${formData.defaultPrizes.length > 1 && index != formData.defaultPrizes.length - 1 && 'border-bottom'}  p-2 mb-3`}>
                                            <Col md={3}>
                                                <Form.Group controlId={`defaultPrize-${index}-prizeType`}>
                                                    <Form.Label>Prize Type</Form.Label>
                                                    <Form.Select
                                                        name="prizeType"
                                                        value={prize.prizeType}
                                                        onChange={(e) => handleDefaultPrizeChange(index, e)}
                                                        isInvalid={showError && !!errors[`defaultPrize-${index}-prizeType`]}
                                                    >
                                                        <option value="cash">Cash</option>
                                                        <option value="car">Car</option>
                                                        <option value="mobile">Mobile</option>
                                                        <option value="other">Other</option>
                                                    </Form.Select>

                                                    <Form.Control.Feedback type="invalid">
                                                        {errors[`defaultPrize-${index}-prizeType`]}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            {/* Custom Prize Type Input if 'Other' is selected */}
                                            {prize.prizeType === 'other' && (
                                                <Col md={3}>
                                                    <Form.Group controlId={`defaultPrize-${index}-customPrizeType`}>
                                                        <Form.Label>Other Prize Type</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter other prize type"
                                                            name="customPrizeType"
                                                            value={prize.customPrizeType || ''}
                                                            onChange={(e) => handleDefaultPrizeChange(index, e)}
                                                            isInvalid={showError && !!errors[`defaultPrize-${index}-customPrizeType`]}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors[`defaultPrize-${index}-customPrizeType`]}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            )}
                                            <Col md={3}>
                                                <Form.Group controlId={`defaultPrize-${index}-prizeValue`}>
                                                    <Form.Label>Prize Value</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Enter prize value"
                                                        name="prizeValue"
                                                        value={prize.prizeValue}
                                                        min={0}
                                                        max={9999999999}
                                                        
                                                        onChange={(e) => {
                                                            const value = e.target.value;
                                                            if ((value >= 0 && value<=9999999999) || value === '') {
                                                                handleDefaultPrizeChange(index, e);
                                                            }
                                                        }}
                                                        isInvalid={showError && !!errors[`defaultPrize-${index}-prizeValue`]}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors[`defaultPrize-${index}-prizeValue`]}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                            </Col>
                                            <Col md={4}>
                                                {/* `defaultPrize-${index}-prizeDescription` */}
                                                <Form.Group controlId={`defaultPrize-${index}-prizeDescription`}>
                                                    <Form.Label>Prize Description</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        maxLength={1000}
                                                        placeholder="Enter prize description"
                                                        name="prizeDescription"
                                                        value={prize.prizeDescription}
                                                        onChange={(e) => handleDefaultPrizeChange(index, e)}
                                                        isInvalid={showError && !!errors[`defaultPrize-${index}-prizeDescription`]}

                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors[`defaultPrize-${index}-prizeDescription`]}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            {/* <Col md={2} className="d-flex align-items-center mt-4">
                                                {index >= 0 && (
                                                    // <Button variant="danger" onClick={() => removeDefaultPrize(index)}>
                                                    //     Remove
                                                    // </Button>
                                                    <Button className="ms-md-2" variant="danger" onClick={() => removeDefaultPrize(index)}>
                                                        <i class="fa-solid fa-minus"></i>
                                                    </Button>
                                                )}
                                            </Col> */}
                                        </div>
                                    ))}
                                    {/* <Button className="mt-1" variant="success" onClick={addDefaultPrize}>
                                        <i class="fa-solid fa-plus"></i>
                                    </Button> */}
                                    {/* <Button variant="primary" onClick={addDefaultPrize}>
                                        Add Another Prize
                                    </Button> */}
                                </Col>

                                {/* Submit Button */}
                                <Col md={12} className="mt-5">
                                    <Button disabled={isSubmitting} variant="primary" type="submit">
                                        {isSubmitting ? 'Please wait...' : 'Submit'}
                                    </Button>
                                    <Link to="/lottery-list" className="btn btn-danger ms-2">
                                        Cancel
                                    </Link>
                                </Col>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    );
};

export default EditLottery;
