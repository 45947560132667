// import { BehaviorSubject } from "rxjs";
// import axios from "axios";
// import { environment } from "../../envirnoment/envirnoment.prod";
// import { map, catchError, tap, retry } from "rxjs/operators";

import createAxiosInstance from "../api.instance";


const UserService = () => {
  const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage

const axiosInstance = createAxiosInstance(bearerToken);
  const createStaff = async (requestData) => {
    return await axiosInstance
      .post(`/admin/auth/subadmin`, requestData, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  // const getUser = async ({page, limit, searchBy}) => {
  //   return await axiosInstance
  //     .get(`/admin/user?page=${page}&limit=${limit}&searchBy=${searchBy}`,  { headers: {} })
  //     .then((response) => {
  //       console.log("Registration =============", response);
  //       return response.data; // Return response data
  //     })
  //     .catch((error) => {
  //       console.error("Registration failed:", error);
  //       //  return error.response.data; // Throw error to be caught by the caller
  //     });
  // };
  // merchant/user

  const getUser = async ({page, limit, searchBy}) => {
      return await axiosInstance
        .get(`/merchant/user?page=${page}&limit=${limit}&searchBy=${searchBy}`,  { headers: {} })
        .then((response) => {
          console.log("Registration =============", response);
          return response.data; // Return response data
        })
        .catch((error) => {
          console.error("Registration failed:", error);
          //  return error.response.data; // Throw error to be caught by the caller
        });
    };
  const updateUser = async (id, requestData) => {
    return await axiosInstance
      .patch(`/merchant/user/${id}`, requestData, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const updateStaffStatus = async ( requestData) => {
    return await axiosInstance
      .post(`/merchant/user`, requestData, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  const deleteStaff = async (id) => {
    return await axiosInstance
      .delete(`/merchant/user/${id}`,  { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const getUserById = async (id) => {
    return await axiosInstance
      .get(`/merchant/user/${id}`,  { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  // users/wallet-details/id/fromDate=23-05-2024&toDate=23-05-2024
  const getAdminUserWalletDetails = async (filter, id) => {
    console.log(filter,"=======from date")
    return await axiosInstance
      .get(`users/merchant/wallet-details/${id}?page=${filter?.page}&limit=${filter?.limit}&userId=${filter?.user}&status=${filter?.status}&transactionType=${filter?.transactionType}&searchBy=${filter?.searchBy}&fromDate=${filter?.fromDate}&toDate=${filter?.toDate}`,  { headers: {} })
      .then((response) => {
        console.log("walletDetails =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Data fetch failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  // bank/admin/6637d17aef6b18e960785cc7?page=1&limit=10&searchBy=

  const getAdminUserBankDetails = async (filter, id) => {
    console.log("bank filters",filter)
    return await axiosInstance
      .get(`bank/merchant/${id}?searchBy=${filter?.searchBy}`,  { headers: {} })
      .then((response) => {
        console.log("BankDetails =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Bank Data fetch failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  // admin/user/list/dropdown

  const getUserList = async () => {
    // console.log("bank filters",filter)
    return await axiosInstance
      .get(`admin/user/list/dropdown`,  { headers: {} })
      .then((response) => {
        console.log("user list =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("user list fetch failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  // create user

  // /admin/user/create
  const createUser = async (payload) => {
    // console.log("bank filters",filter)
    return await axiosInstance
      .post(`admin/user/create`, payload)
      .then((response) => {
        console.log("user  =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("user list fetch failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  const resetUserPassword= async (payload) => {
    // console.log("bank filters",filter)
    return await axiosInstance
      .post(`merchant/user/reset-password`, payload)
      .then((response) => {
        console.log("user  =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("user list fetch failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  return {
    resetUserPassword,
    createUser,
    getUserList,
    getAdminUserBankDetails,
    getAdminUserWalletDetails,
    createStaff,
    getUser,
    updateUser,
    getUserById,
    updateStaffStatus,
    deleteStaff
  };
};

export default UserService;
