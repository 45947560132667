// transaction/admin?page=1&limit=10&searchBy=&transactionType=credit

import { useDispatch } from "react-redux";
import createAxiosInstance from "../api.instance";
import {
  endApiRequest,
  startApiRequest,
} from "../../Redux/Action/loader.action";

const TransactionService = () => {
  const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage

  const axiosInstance = createAxiosInstance(bearerToken);
  const dispatch = useDispatch();
  const getTransaction = async ({
    page,
    limit,
    searchBy,
    transactionType,
    fromDate,
    toDate,
    status,
    type,
    user,
  }) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .get(
        `/transaction/merchant?type=${type}&transactionType=${transactionType}&page=${page}&limit=${limit}&status=${status}&userId=${user}&searchBy=${searchBy}&fromDate=${fromDate}&toDate=${toDate}`,
        { headers: {} }
      )
      .then((response) => {
        dispatch(endApiRequest());

        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        dispatch(endApiRequest());
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  // transaction/admin/665496422b91287a90cb3560

  const updateTransaction = async (data, id) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .patch(`/transaction/admin/${id}`, data, { headers: {} })
      .then((response) => {
        dispatch(endApiRequest());
        console.log("Update transaction =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Update transaction failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  // transaction/manual-transaction

  const manualAddAmount = async (data) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .post(
        `/transaction/manual-transaction`,data,
        { headers: {} }
      )
      .then((response) => {
        dispatch(endApiRequest());
        console.log("added amount =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Transaction failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  const manualWithdrawAmount = async (data) => {
    dispatch(startApiRequest());
    return await axiosInstance
      .post(
        `/transaction/manual-payout`,data,
        { headers: {} }
      )
      .then((response) => {
        dispatch(endApiRequest());
        console.log("added amount =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        dispatch(endApiRequest());
        console.error("Transaction failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  return {
    manualWithdrawAmount,
    manualAddAmount,
    updateTransaction,
    getTransaction,
  };
};
export default TransactionService;

// export
