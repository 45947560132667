import React, { useState, useEffect } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import { format, startOfMonth, endOfMonth } from "date-fns";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './dateFilter.css';
import { useSelector } from "react-redux";

export default function DateFilter({ onDateChange,type }) {
    const theme = useSelector((state) => state.ui.skin);
    const [showDropdown, setShowDropdown] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const today = new Date();
    const initialStartDate = startOfMonth(today);
    const initialEndDate = endOfMonth(today);
    const [state, setState] = useState([
        {
            startDate: today,
            endDate: today,
            key: 'selection'
        }
    ]);

    useEffect(() => {
        if (type && type === 'lottery') {
       
            setState([ {
                startDate: initialStartDate,
                endDate: initialEndDate,
                key: 'selection'
            }])
        }
    }, [type]);

   

    useEffect(() => {
        const handleResize = () =>
        {
            setWindowWidth(window.innerWidth);
            console.log(window.innerWidth,"inner width");
        }
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleDateChange = () => {
        onDateChange(formattedDates);
        console.log(formattedDates);
        setShowDropdown(false);
    };

    const formattedDates = state[0].endDate ? {
        startDate: format(state[0]?.startDate, 'yyyy-MM-dd'),
        endDate: format(state[0]?.endDate, 'yyyy-MM-dd')
    } : {};

    const preventClose = (e) => {
        e.stopPropagation();
    };

    return (
        <Dropdown show={showDropdown} onToggle={() => setShowDropdown(!showDropdown)} className="date-filter-dropdown">
            <Dropdown.Toggle variant="primary" id="dropdown-basic" onClick={() => setShowDropdown(!showDropdown)}>
                {state[0]?.endDate !== null ? `${formattedDates?.startDate + ' To ' + formattedDates?.endDate}` : "Select Date"}
            </Dropdown.Toggle>

            <Dropdown.Menu align='end' className="shadow-lg date-filter-menu" style={{ zIndex: '100', minWidth: 'fit-content' }}>

                <Dropdown.Item  onClick={preventClose} className="date-picker-item">

                    <DateRangePicker
                        onChange={item => setState([item.selection])}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        months={windowWidth < 768 ? 1 : 2} 
                        ranges={state}
                        maxDate={new Date()}
                        className={`${theme === 'dark' ? 'date-range-picker-dark-mode' : ''}`}
                        direction={windowWidth < 1450 ? "vertical" : "horizontal"} 
                    />

                    <hr />
                    <div className="apply-button-wrapper">
                        <Button onClick={handleDateChange}>Apply</Button>
                    </div>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}
