// import { BehaviorSubject } from "rxjs";
// import axios from "axios";
// import { environment } from "../../envirnoment/envirnoment.prod";
// import { map, catchError, tap, retry } from "rxjs/operators";

import createAxiosInstance from "../api.instance";
import { useDispatch } from "react-redux";
import { endApiRequest, startApiRequest } from "../../Redux/Action/loader.action";


const LotteryService = () => {
    const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage
    const dispatch = useDispatch();
    const BASIC_AUTH = 'Basic a3JpcGFiZXQ6a3JpcGFiZXRAMTIzNDU=';
    const axiosInstanceBasic = createAxiosInstance(BASIC_AUTH, "basic");
    const axiosInstance = createAxiosInstance(bearerToken);
    const createLottery = async (requestData) => {
        dispatch(startApiRequest());
        return await axiosInstance
            .post(`/lottery/create-lottery`, requestData, { headers: {} })
            .then((response) => {
                dispatch(endApiRequest());
                // console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                dispatch(endApiRequest());
                console.error("failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };
    const getLotteryList = async ({ page, limit, searchBy }) => {
        dispatch(startApiRequest());
        return await axiosInstanceBasic
            .get(`/lottery/admin/list?page=${page}&limit=${limit}&searchBy=${searchBy}`, { headers: {} })
            .then((response) => {
                dispatch(endApiRequest());
                // console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                dispatch(endApiRequest());
                console.error("Registration failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };

    // lottery/get-admin-ticket?page=1&limit=10

    const getTicketList = async ({ page,searchBy,limit,status,fromDate,toDate,user }) => {
        dispatch(startApiRequest());
        return await axiosInstance
            .get(`/lottery/merchant/get-ticket?page=${page}&limit=${limit}&hasWon=${status}&searchBy=${searchBy}&fromDate=${fromDate}&toDate=${toDate}&user=${user}`, { headers: {} })
            .then((response) => {
                dispatch(endApiRequest());
                // console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                dispatch(endApiRequest());
                console.error("Registration failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };
    const updateLottery = async (id, requestData) => {
        return await axiosInstance
            .patch(`/lottery/${id}`, requestData, { headers: {} })
            .then((response) => {
                console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("Registration failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };


    const deleteLottery = async (id) => {
        return await axiosInstance
            .delete(`/lottery/${id}`)
            .then((response) => {
                console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("Registration failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };
    const getLotteryById = async (id) => {
        return await axiosInstance
            .get(`/lottery/merchant/${id}`, { headers: {} })
            .then((response) => {
                // console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };

    const makeManualLotteryWinning = async (id,data) => {
        return await axiosInstance
            .post(`/lottery/manual/winning/${id}`, data, { headers: {} })
            .then((response) => {
                console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("Registration failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    }
   
    // lottery/get-number-frequency/670918aae8e47d4f6f3af188

    const getLotteryWinningNumbers = async (id,data) => {
        return await axiosInstance
            .get(`/lottery/get-number-frequency/${id}`)
            .then((response) => {
                console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("Registration failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    }
    return {
        getLotteryWinningNumbers,
        makeManualLotteryWinning,
        createLottery,
        getLotteryList,
        getTicketList,
        getLotteryById,
        updateLottery,
        deleteLottery
    };
};

export default LotteryService;
