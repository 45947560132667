import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/Header";
import TableWithPagination from "../../common/table";
import useSkinSwitcher from "../../common/skin";
import AuthService from "../../service/api/auth.service";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Badge, Modal, Form } from "react-bootstrap";
import PageBreadcrumb from "../../common/PageBreadcrum";

import "cryptofont/css/cryptofont.css";
import Sidebar from "../../layouts/Sidebar";
import { toast } from "react-toastify";

import globalSwal from "../../common/swalService";
import formatDateTime from "../../helper/DateFormate";

import { BorderSpinner } from "../../common/Spinner";
import ExposureService from "../../service/api/exposure.service";
import TableFilter from "../../common/filter";

export default function ExposureSettingList() {

  const exposureService = ExposureService();
  const authService = AuthService();

  const navigate = useNavigate();
  const { skin, setSkin } = useSkinSwitcher();
  const [ipwStatus, setIpwStatus] = useState(false);
  const [isIpwStatusLoaded, setIsIpwStatusLoaded] = useState(false); // Loading state

  const [exposureList, setExposureList] = useState([]);
  const [addTo, setAddTo] = useState('admin');
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isHidden, setIsHidden] = useState(false);
  const [showPicker, setShowPicker] = useState(false);

  const [modalTypeAndId, setModalTypeAndId] = useState({
    type: "",
    id: "",
  });


  const [pagination, setPagination] = useState({
    page: 1,
    searchBy: "",
    limit: 10,
    gameId: "",
  });


  const [columns] = useState([
    "createdAt",
    "gameId",
    "individualBetLimit",
    "dailyLimit",
    "monthlyLimit",
    "id",
  ]);

  useEffect(() => {
    authService.getLoginData();
  }, []);

  useEffect(() => {
    getList();
  }, [pagination.page, pagination.searchBy,pagination.gameId]);










  const getList = async () => {
    try {
      const res = await exposureService.getExposureList({
        page: pagination?.page,
        limit: pagination?.limit,
        searchBy: pagination?.searchBy,
        gameId: pagination?.gameId ||'',
      });
      if (res?.code === 200) {
        setExposureList(res?.data?.docs);
        setPagination((prevPagination) => ({
          ...prevPagination,
          pages: res.data.pages,
          totalDocs: res.data.totalDocs,
          limit: res.data.limit,
        }));
      } else {
        // console.error(res?.message);
        throw new Error(res?.message || "Failed to fetch IP whitelist.");
      }
    } catch (error) {
      console.error("Error fetching IP whitelist:", error);
      // toast.error("An unexpected error occurred while fetching IP whitelist.");
    } finally {
      setLoading(false);
    }
  };




  const getActionDropdown = (id) => {
    // console.log(row,"action");
    return (
      <Dropdown>
        <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
          <i className="ri-more-2-fill"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleView(id)}>View</Dropdown.Item>
          <Dropdown.Item onClick={() => handleUpdate(id)}>Edit</Dropdown.Item>
          <Dropdown.Item onClick={() => handleDelete(id)}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleView = (id) => {
    const state = { id: id };
    navigate(`/exposure-management/view`, { state });
  };

  const handleUpdate = (id) => {
    const state = { id: id };
    navigate("/exposure-management/edit", { state });
  };



  const handleDelete = async (id) => {
    try {
      globalSwal.confirm({
        title: "Are you sure?",
        text: "Do you really want to delete this exposure?",
        onConfirm: async () => {
          const res = await exposureService.deleteExposure(id);
          if (res?.code === 200) {
            globalSwal.success({
              title: "Success!",
              text: "Exposure has been deleted successfully.",
            });
            getList();
            toast.success(res?.message);


            setPagination({ ...pagination, page: 1 });
          } else {
            throw new Error(res?.message || "Failed to delete exposure.");
          }
        },
      });
    } catch (error) {
      console.error("Error deleting exposure:", error);
      // toast.error("An unexpected error occurred while deleting IP.");
    }
  };

  const useDebounce = (func, delay) => {
    const debounceRef = useRef(null);

    return (...args) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };



  const handleSearchChange = (event) => {
    const value = event.target.value;
    setPagination((prev) => ({ ...prev, searchBy: value, page: 1 }));

  };

  const debouncedMerchantSearchChange = useDebounce(handleSearchChange, 300);

  const columnsWithHTML = columns.map((column) => {
    if (column.toLowerCase() === "createdat") {
      return {
        header: "Date",
        accessor: column,
        Cell: ({ value }) => (
          <span className="text-nowrap">{formatDateTime(value) || "N/A"}</span>
        ),
      };
    } else if (column.toLowerCase() === "gameid") {
      return {
        header: "Game",
        accessor: column,
        Cell: ({ value }) => (
          <span className="text-nowrap">{value?.name || "N/A"}</span>
        ),
      };
    }
    else if (column.toLowerCase() === "dailylimit") {
      return {
        header: "Daily Payout Limit",
        accessor: column,
        Cell: ({ value }) => (
          <span className="text-nowrap">{value || "N/A"}</span>
        ),
      };
    } else if (column.toLowerCase() === "monthlylimit") {
      return {
        header: "Monthly Payout Limit",
        accessor: column,
        Cell: ({ value }) => (
          <span className="text-nowrap">{value || "N/A"}</span>
        ),
      };
    } else if (column.toLowerCase() === "individualbetlimit") {
      return {
        header: "Individual Payout Limit",
        accessor: column,
        Cell: ({ value }) => (
          <span className="text-nowrap">{value || "N/A"}</span>
        ),
      };
    } else if (column.toLowerCase() === "id") {
      return {
        header: "Actions",
        accessor: column,
        Cell: ({ value, row }) => getActionDropdown(value),
      };
    } else {
      return {
        header: column.charAt(0).toUpperCase() + column.slice(1),
        accessor: column,
        Cell: ({ value }) => (
          <span className="text-nowrap">{value || "N/A"}</span>
        ),
      };
    }
  });

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        {/* IP Whitelist Status Flag */}
        {/* IP Whitelist Status Flag */}
        {/* {isIpwStatusLoaded ? (
        ipwStatus ? (
          <div className="alert alert-success">
            IP Whitelisting is currently <strong>Enabled</strong>. Only whitelisted IPs will have access.
          </div>
        ) : (
          <div className="alert alert-warning">
            IP Whitelisting is currently <strong>Disabled</strong>. All IPs have unrestricted access.
          </div>
        )
      ) : (
        <div className="alert alert-info">
          Loading IP Whitelist Status...
        </div>
      )} */}
        {/* {ipwStatus ? (
          <div className="alert alert-success">
            IP Whitelisting is currently <strong>Enabled</strong>. Only whitelisted IPs will have access.
          </div>
        ) : (
          <div className="alert alert-warning">
            IP Whitelisting is currently <strong>Disabled</strong>. All IPs have unrestricted access.
          </div>
        )} */}
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between align-items-center">
              <PageBreadcrumb title="Exposure Management" />
              <div>
                <Button className="" onClick={() => setIsHidden(!isHidden)}>
                  Filter
                </Button>
              </div>
            </div>
            <div className={`${!isHidden ? "d-none" : ""} `}>
              {/* Search Input */}
              <TableFilter
                // handleRangeChange={handleRangeChange}
                handleSearchChange={handleSearchChange}
                setPagination={setPagination}
                setShowPicker={setShowPicker}
                // state={state}
                tableType={"exposure-list"}
                // transactionType={transactionType}
                // clearRange={clearRange}
                // userList={userList}
                userId={''}
                status={''}
                showPicker={showPicker}
              />
            </div>
          </Card.Header>
          <Card.Body>

            {(loading ? <div className="d-flex mt-5 justify-content-center align-content-center">

              <BorderSpinner />
            </div> : (<TableWithPagination
              data={exposureList}
              itemsPerPage={pagination.limit}
              columns={columnsWithHTML}
              currentPage={pagination.page}
              totalData={pagination.totalDocs}
              pagination={pagination}
              setPagination={setPagination}
              addType=''
              redirectUrl={'/exposure-management/add'}
              handleSearchChange={debouncedMerchantSearchChange}
              moduleId={20}
            />))}

          </Card.Body>
        </Card>
      </div>


    </React.Fragment>
  );
}
