// transaction/admin?page=1&limit=10&searchBy=&transactionType=credit

import { useDispatch } from "react-redux";
import createAxiosInstance from "../api.instance";
import { endApiRequest, startApiRequest } from "../../Redux/Action/loader.action";

const StaticPagesService = () => {
    const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage
    const BASIC_AUTH = 'Basic a3JpcGFiZXQ6a3JpcGFiZXRAMTIzNDU=';
    const axiosInstanceBasic = createAxiosInstance(BASIC_AUTH, "basic");
    const axiosInstance = createAxiosInstance(bearerToken);
    
    const dispatch = useDispatch();
    const getStaticPage = async ( endPoints ) => {
        dispatch(startApiRequest());
        return await axiosInstanceBasic
            .get(
                `/${endPoints}`,
                { headers: {} }
            )
            .then((response) => {
                dispatch(endApiRequest());

                // console.log("Static Data =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("failed:", error);
                dispatch(endApiRequest());
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };

    // transaction/admin/665496422b91287a90cb3560

    const updateStaticPage = async (data,endPoints) => {
        dispatch(startApiRequest());
        return await axiosInstance
            .patch(
                `/${endPoints}`, data,
                { headers: {} }
            )
            .then((response) => {
                dispatch(endApiRequest());
                // console.log("Update pages =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                dispatch(endApiRequest());
                console.error("Update failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };

    // const getFaqList = (payload) => getStaticPage(`admin/faq?page=1&limit=10`);
    const getFaqList = async (payload) => {
        
        dispatch(startApiRequest());
        return await axiosInstance
            .get(
                `admin/faq?page=${payload?.page}&limit=${payload?.limit}`,
                { headers: {} }
            )
            .then((response) => {
                dispatch(endApiRequest());

                // console.log("Static Data =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("failed:", error);
                dispatch(endApiRequest());
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };
    const addFaq = async ( payload ) => {
        dispatch(startApiRequest());
        return await axiosInstance
            .post(
                `admin/faq`,payload,
                { headers: {} }
            )
            .then((response) => {
                dispatch(endApiRequest());

                // console.log("Static Data =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("failed:", error);
                dispatch(endApiRequest());
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };

    // admin/faq/66c73553f4d00e9c3312edad

    const getFaqDetails = async ( id ) => {
        dispatch(startApiRequest());
        return await axiosInstance
            .get(
                `admin/faq/${id}`,
                { headers: {} }
            )
            .then((response) => {
                dispatch(endApiRequest());

                // console.log("Static Data =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("failed:", error);
                dispatch(endApiRequest());
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };

    // admin/faq/66c73553f4d00e9c3312edad
    const updateFaqDetails = async ( id,payload ) => {
        dispatch(startApiRequest());
        return await axiosInstance
            .patch(
                `admin/faq/${id}`,payload,
                { headers: {} }
            )
            .then((response) => {
                dispatch(endApiRequest());

                // console.log("Static Data =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("failed:", error);
                dispatch(endApiRequest());
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };
    return {
        getStaticPage,
        updateStaticPage,
        getFaqList,
        addFaq,
        getFaqDetails,
        updateFaqDetails
    };
};
export default StaticPagesService;

