// reactSelectStyles.js

export const darkModeStyles = {
    container: (provided) => ({
        ...provided,
        minWidth: '200px',
        width: '100%',
    }),
    control: (provided) => ({
        ...provided,
        minWidth: '200px',
        backgroundColor: '#192030',
        borderColor: 'transparent',
        color: '#41505f',
        boxShadow: 'none',
        '&:hover': {
            borderColor: 'transparent',
        },
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#192030',
        color: '#41505f',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'gray',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'rgba(255, 255, 255, 0.6)',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#283046' : '#192030',
        color: state.isSelected ? 'rgba(255, 255, 255, 0.6)' : 'rgba(255, 255, 255, 0.6)',
        '&:hover': {
            backgroundColor: '#283046',
        },
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: 'rgba(255, 255, 255, 0.6)',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        backgroundColor: 'transparent',
    }),
    clearIndicator: (provided) => ({
        ...provided,
        color: '#41505f',
        '&:hover': {
            color: '#f00',
        },
    }),
    // no comments
};

export const darkModeStyles2 = {
    container: (provided) => ({
        ...provided,
        minWidth: '200px',
        width: '100%',
    }),
    control: (provided) => ({
        ...provided,
        minWidth: '200px',
        backgroundColor: '#192030',
        // borderColor: '#41505f',
        borderColor: '#27314b',
        color: 'rgba(255, 255, 255, 0.65)',
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#41505f',
        },
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#192030',
        color: 'rgba(255, 255, 255, 0.65)',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'inherit',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: 'rgba(255, 255, 255, 0.65)',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#283046' : '#192030',
        color: state.isSelected ? 'rgba(255, 255, 255, 0.65)' : 'rgba(255, 255, 255, 0.65)',
        '&:hover': {
            backgroundColor: '#283046',
        },
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#41505f',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        backgroundColor: 'transparent',
    }),
    clearIndicator: (provided) => ({
        ...provided,
        color: '#41505f',
        '&:hover': {
            color: '#f00',
        },
    }),
    // no comments
};

export const lightModeStyles = {
    container: (provided) => ({
        ...provided,
        minWidth: '200px',
        width: '100%',
    }),
    control: (provided) => ({
        ...provided,
        minWidth: '200px',
        backgroundColor: 'white',
        borderColor: 'transparent',
        color: 'black',
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#aaa',
        },
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: 'white',
        color: 'black',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'black',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#888',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#f0f0f0' : 'white',
        color: state.isSelected ? 'black' : 'black',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#888',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        backgroundColor: '#ccc',
    }),
    clearIndicator: (provided) => ({
        ...provided,
        color: '#888',
        '&:hover': {
            color: '#f00',
        },
    }),
};


export const lightModeStyles2 = {
    container: (provided) => ({
        ...provided,
        minWidth: '200px',
        width: '100%',
    }),
    control: (provided) => ({
        ...provided,
        minWidth: '200px',
        backgroundColor: 'white',
        borderColor: '#e2e5ec',
        color: 'black',
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#aaa',
        },
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: 'white',
        color: 'black',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'black',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#888',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#f0f0f0' : 'white',
        color: state.isSelected ? 'black' : 'black',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#888',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        backgroundColor: '#ccc',
    }),
    clearIndicator: (provided) => ({
        ...provided,
        color: '#888',
        '&:hover': {
            color: '#f00',
        },
    }),
};
