import React from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import CommonPageEditor from "./Editor";

export default function PrivacyPolicy() {
  let domain = JSON.parse(localStorage.getItem('user'))?.domain || ""

  return (
    <React.Fragment>
      <Header />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        <CommonPageEditor
          apiEndpoint={`static-content/page/privacy?domain=${domain}`}
          pageTitle="Privacy Policy"
        />
      </div>
    </React.Fragment>
  );
}




