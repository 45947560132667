import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/Header";
import TableWithPagination from "../../common/table";
import useSkinSwitcher from "../../common/skin";
import AuthService from "../../service/api/auth.service";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Badge, Modal, Form } from "react-bootstrap";
import PageBreadcrumb from "../../common/PageBreadcrum";

import "cryptofont/css/cryptofont.css";
import Sidebar from "../../layouts/Sidebar";
import { toast } from "react-toastify";

import globalSwal from "../../common/swalService";
import PlatformService from "../../service/api/platform-settings.service";
import formatDateTime from "../../helper/DateFormate";
import IPWhitelistingService from "../../service/api/ip-whitelisting.service";
import AddIpWhitelistModal from "./add";
import { BorderSpinner } from "../../common/Spinner";

export default function IPWhitelistList() {
  const platFormService = PlatformService();
  const whitelistService = IPWhitelistingService();
  const authService = AuthService();

  const navigate = useNavigate();
  const { skin, setSkin } = useSkinSwitcher();
  const [ipwStatus, setIpwStatus] = useState(false);
  const [isIpwStatusLoaded, setIsIpwStatusLoaded] = useState(false); // Loading state

  const [ipWhitelist, setIpWhitelist] = useState({
    admin: [],
    merchant: []
  });
  const [addTo, setAddTo] = useState('admin');
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState({ admin: true, merchant: true });
  const [modalTypeAndId, setModalTypeAndId] = useState({
    type: "",
    id: "",
  });


  const [pagination, setPagination] = useState({
    page: 1,
    searchBy: "",
    limit: 10,
  });


  const [columns] = useState([
    "createdAt",
    // "addedBy",
    "ip",
    "identifier",
    "id",
  ]);

  useEffect(() => {
    authService.getLoginData();
    getStatus();
  }, []);

  useEffect(() => {
    getList();
  }, [pagination.page, pagination.searchBy]);





  const updateIpWhitelist = (type, data) => {
    setIpWhitelist((prevState) => ({
      ...prevState,
      [type]: data,
    }));
    setLoading((prevLoading) => ({
      ...prevLoading,
      [type]: false,
    }))
  };

  const getStatus = async () => {
    try {
      const res = await whitelistService.getStatusOfIPW();
      if (res?.code === 200) {
        setIpwStatus(res?.data?.isIpWhitelistCheckEnabled);
      } else {
        // console.error(res?.message);
        throw new Error(res?.message || "Failed to fetch IP whitelist.");
      }
    } catch (error) {
      console.error("Error fetching IP whitelist:", error);
      // toast.error("An unexpected error occurred while fetching IP whitelist.");
    } finally {
      setIsIpwStatusLoaded(true);
    }
  };



  const getList = async () => {
    try {
      const res = await whitelistService.getIPList({
        page: pagination?.page,
        limit: pagination?.limit,
        searchBy: pagination?.searchBy,
      });
      if (res?.code === 200) {
        updateIpWhitelist("merchant", res?.data?.docs);
        setPagination((prevPagination) => ({
          ...prevPagination,
          pages: res.data.pages,
          totalDocs: res.data.totalDocs,
          limit: res.data.limit,
        }));
      } else {
        // console.error(res?.message);
        throw new Error(res?.message || "Failed to fetch IP whitelist.");
      }
    } catch (error) {
      console.error("Error fetching IP whitelist:", error);
      // toast.error("An unexpected error occurred while fetching IP whitelist.");
    }
  };




  const getActionDropdown = (id, type) => {
    // console.log(row,"action");
    return (
      <Dropdown>
        <Dropdown.Toggle as="span" className="custom-dropdown-toggle">
          <i className="ri-more-2-fill"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleView(id, type)}>View</Dropdown.Item>
          <Dropdown.Item onClick={() => handleUpdate(id, type)}>Edit</Dropdown.Item>
          <Dropdown.Item onClick={() => handleDelete(id, type)}>Delete</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const handleUpdate = (id, tableType) => {
    setModalTypeAndId((prevValue) => ({
      ...prevValue,
      type: "edit",
      id: id,
    }));
    setShow(true);
    setAddTo(tableType);

  };

  const handleView = (id, tableType) => {
    setModalTypeAndId((prevValue) => ({
      ...prevValue,
      type: "view",
      id: id,
    }));
    setShow(true);
    setAddTo(tableType);
  };

  const handleDelete = async (id, type) => {
    try {
      globalSwal.confirm({
        title: "Are you sure?",
        text: "Do you really want to delete this IP?",
        onConfirm: async () => {
          const res = await whitelistService.deleteIP(id);
          if (res?.code === 200) {
            globalSwal.success({
              title: "Success!",
              text: "IP has been deleted successfully.",
            });
            getList();
            toast.success(res?.message);


            setPagination({ ...pagination, page: 1 });
          } else {
            throw new Error(res?.message || "Failed to delete IP.");
          }
        },
      });
    } catch (error) {
      console.error("Error deleting IP:", error);
      // toast.error("An unexpected error occurred while deleting IP.");
    }
  };

  const useDebounce = (func, delay) => {
    const debounceRef = useRef(null);

    return (...args) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };



  const handleMerchantSearchChange = (event) => {
    const value = event.target.value;
    setPagination((prev) => ({ ...prev, searchBy: value, page: 1 }));

  };

  const debouncedMerchantSearchChange = useDebounce(handleMerchantSearchChange, 300);

  const columnsWithHTML = columns.map((column) => {
    if (column.toLowerCase() === "createdat") {
      return {
        header: "Date",
        accessor: column,
        Cell: ({ value }) => (
          <span className="text-nowrap">{formatDateTime(value) || "N/A"}</span>
        ),
      };
    } else if (column.toLowerCase() === "addedby") {
      return {
        header: "Added By",
        accessor: column,
        Cell: ({ value }) => (
          <span className="text-nowrap">{value?.name || "N/A"}</span>
        ),
      };
    } else if (column.toLowerCase() === "ip") {
      return {
        header: "IP Address",
        accessor: column,
        Cell: ({ value }) => (
          <span className="text-nowrap">{value || "N/A"}</span>
        ),
      };
    } else if (column.toLowerCase() === "identifier") {
      return {
        header: "Identifier",
        accessor: column,
        Cell: ({ value }) => (
          <span className="text-nowrap">{value || "N/A"}</span>
        ),
      };
    } else if (column.toLowerCase() === "id") {
      return {
        header: "Actions",
        accessor: column,
        Cell: ({ value, row }) => getActionDropdown(value, row.type),
      };
    } else {
      return {
        header: column.charAt(0).toUpperCase() + column.slice(1),
        accessor: column,
        Cell: ({ value }) => (
          <span className="text-nowrap">{value || "N/A"}</span>
        ),
      };
    }
  });

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <Sidebar />
      <div className="main main-app p-3 p-lg-4">
        {/* IP Whitelist Status Flag */}
         {/* IP Whitelist Status Flag */}
      {isIpwStatusLoaded ? (
        ipwStatus ? (
          <div className="alert alert-success">
            IP Whitelisting is currently <strong>Enabled</strong>. Only whitelisted IPs will have access.
          </div>
        ) : (
          <div className="alert alert-warning">
            IP Whitelisting is currently <strong>Disabled</strong>. All IPs have unrestricted access.
          </div>
        )
      ) : (
        <div className="alert alert-info">
          Loading IP Whitelist Status...
        </div>
      )}
        {/* {ipwStatus ? (
          <div className="alert alert-success">
            IP Whitelisting is currently <strong>Enabled</strong>. Only whitelisted IPs will have access.
          </div>
        ) : (
          <div className="alert alert-warning">
            IP Whitelisting is currently <strong>Disabled</strong>. All IPs have unrestricted access.
          </div>
        )} */}
        <Card>
          <Card.Header>
            <PageBreadcrumb title="IP Whitelist List" />
          </Card.Header>
          <Card.Body>

            {(loading.merchant ? <div className="d-flex mt-5 justify-content-center align-content-center">

              <BorderSpinner />
            </div> : (<TableWithPagination
              data={ipWhitelist.merchant}
              itemsPerPage={pagination.limit}
              columns={columnsWithHTML}
              currentPage={pagination.page}
              totalData={pagination.totalDocs}
              pagination={pagination}
              setPagination={setPagination}
              addType='modal'
              redirectUrl={() => {
                setShow(true);
                setAddTo('merchant');
              }}
              handleSearchChange={debouncedMerchantSearchChange}
              moduleId={19}
            />))}

          </Card.Body>
        </Card>
      </div>

      <AddIpWhitelistModal setModalTypeAndId={setModalTypeAndId} modalTypeAndId={modalTypeAndId} show={show} addTo={addTo} onClose={() => {
        setShow(false);
        getList();
        setModalTypeAndId({ type: '', id: '' });

      }} />
    </React.Fragment>
  );
}
