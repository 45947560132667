// ip/list/merchant

// import { BehaviorSubject } from "rxjs";
// import axios from "axios";
// import { environment } from "../../envirnoment/envirnoment.prod";
// import { map, catchError, tap, retry } from "rxjs/operators";

import createAxiosInstance from "../api.instance";
import { useDispatch } from "react-redux";
import { endApiRequest, startApiRequest } from "../../Redux/Action/loader.action";


const ExposureService = () => {
    const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage
    const dispatch = useDispatch();
    const BASIC_AUTH = 'Basic a3JpcGFiZXQ6a3JpcGFiZXRAMTIzNDU=';
    const axiosInstanceBasic = createAxiosInstance(BASIC_AUTH, "basic");
    const axiosInstance = createAxiosInstance(bearerToken);
    const AddExposure = async (requestData) => {
        // dispatch(startApiRequest());
        return await axiosInstance
            .post(`/payout-limit`, requestData, { headers: {} })
            .then((response) => {
                // dispatch(endApiRequest());
                // console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                // dispatch(endApiRequest());
                console.error("failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };
    const getExposureList = async ({ page, limit, searchBy,gameId }) => {
        // dispatch(startApiRequest());
        return await axiosInstance
            .get(`/payout-limit?page=${page}&limit=${limit}&searchBy=${searchBy}&gameId=${gameId}`, { headers: {} })
            .then((response) => {
                dispatch(endApiRequest());
                // console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                dispatch(endApiRequest());
                console.error("failed ip:", error);
                // return error.response.data;
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };


 
    const updateExposure = async (id, requestData) => {
        return await axiosInstance
            .patch(`/payout-limit/${id}`, requestData, { headers: {} })
            .then((response) => {
                console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("Registration failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };


    const deleteExposure = async (id) => {
        return await axiosInstance
            .delete(`/payout-limit/${id}`)
            .then((response) => {
                console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("Registration failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };
    const getExposure = async (id) => {
        return await axiosInstance
            .get(`/payout-limit/${id}`, { headers: {} })
            .then((response) => {
                // console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };

    // /ip/merchant-toggle/:type
    const getStatusOfIPW = async () => {
        return await axiosInstance
            .get(`/ip/merchant-toggle/merchant`, { headers: {} })
            .then((response) => {
                // console.log("Registration =============", response);
                return response.data; // Return response data
            })
            .catch((error) => {
                console.error("failed:", error);
                //  return error.response.data; // Throw error to be caught by the caller
            });
    };
    return {
        getStatusOfIPW,
        AddExposure,
        getExposureList,
        getExposure,
        updateExposure,
        deleteExposure
    };
};

export default ExposureService;
