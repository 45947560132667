import React, { useEffect, useRef, useState, useCallback } from "react";
import Header from "../../layouts/Header";
import TableWithPagination from "../../common/table";
import useSkinSwitcher from "../../common/skin";
import StaffService from "../../service/api/staff.service";
import TransactionService from "../../service/api/transaction.service";
import AuthService from "../../service/api/auth.service";
import SocketService from "../../service/api/socket.service";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import PageBreadcrumb from "../../common/PageBreadcrum";
// import "./staff.css";
// import { DateRange, DateRangePicker } from "react-date-range";
import { addDays, format } from "date-fns";

import capitalizeFirstLetter from "../../helper/Capitalise";
import Footer from "../../layouts/Footer";
import _ from "lodash";
// import { Link } from "react-router-dom";

import {
    Button,
    Card,
    Row,
    Col,
    Nav,
    ListGroup,
    Table,
    Badge,
} from "react-bootstrap";
import Sidebar from "../../layouts/Sidebar";
import { toast } from "react-toastify";
import UserService from "../../service/api/user.service ";
import GameBetHistoryService from "../../service/api/game-bet-history.service";
import { useDispatch, useSelector } from "react-redux";
import TableFilter from "../../common/filter";
import formatDateTime from '../../helper/DateFormate'

export default function CrashHistory() {
    const dispatch = useDispatch();
    const socketService = SocketService()
    const isLoading = useSelector((state) => state.state.loading);
    const theme = useSelector((state) => state.ui.skin);
    const staffService = StaffService();
    const userService = UserService();
    const transactionService = TransactionService();
    const betHistoryServie = GameBetHistoryService();
    const authService = AuthService();
    const navigate = useNavigate();
    const { skin, setSkin } = useSkinSwitcher();
    const [betHistoryList, setBetHistoryList] = useState([]);
    const [pagination, setPagination] = useState({
        searchBy: "",
        status: "",
        user: "",
        transactionType: "",
        page: 1,
        limit: 10,
    });
    const [state, setState] = useState([
        {
            startDate: null,
            // endDate: new Date(),
            endDate: null,
            key: "selection",
        },
    ]);
    const [isHidden, setIsHidden] = useState(false);
    const [userList, setUserList] = useState([]);
    const clearRange = () => {
        setState([{ startDate: null, endDate: null, key: "selection" }]);
        setShowPicker(false);
    };

//     totalBetAmount
// : 
// 0
// totalPayoutAmount
// : 
// 0
    const [columns, setColumns] = useState([
        "gameId",
        "createdAt",
        "roundId",
        "crashNumber",
        "betRecords",
        "totalBetAmount",
        "totalPayoutAmount",
        "net amount"
    ]);

    const getList = useCallback(async () => {
        // dispatch(startApiRequest());
        const res = await betHistoryServie.getCrashHistory({
            page: pagination?.page,
            limit: pagination?.limit,
            searchBy: pagination?.searchBy?.trim(),
            status: pagination?.status ? pagination?.status : "",
            user: pagination?.user?pagination?.user:'',
            fromDate:pagination?.fromDate?pagination?.fromDate:'',
            toDate:pagination?.toDate?pagination?.toDate:'',
        });
        if (res?.code === 200) {
            console.log("===>", res?.data?.docs);
            setBetHistoryList(res?.data?.docs);
            setPagination((prevPagination) => ({
                ...prevPagination,
                pages: res.data.pages,
                totalDocs: res.data.totalDocs,
                limit: res.data.limit,
            }));
        } else {
            console.error(res?.message);
            // toast.error(res?.message);
        }
    }, [
        pagination.page,
        pagination?.toDate,
        pagination?.status,
        pagination.user,
        pagination.searchBy,
        state,
        pagination.transactionType,
    ]);
    useEffect(() => {
        getList();
        // authService.getLoginData();

        // setPagination((preValue) => ({
        //     ...preValue,
        //     page: 1,
        // }));
    }, [
        pagination.page,
        pagination.toDate,
        pagination.status,
        pagination.user,
        pagination.searchBy,
        // getList,
        state,
        pagination.transactionType,
    ]);
    useEffect(() => {
        const userDetail = JSON.parse(localStorage.getItem('user'))
            console.log("===>",userDetail);
            // socketService.emitInitEvent();
        // socketService.emit('get-admin-id', {id: userDetail.id})
        // socketService.on('crash-history', (data) => {
        //     console.log("Crashhhhhhhhhhhhhhhhhh", data)
        //     setBetHistoryList(data?.docs);
        //     setPagination((prevPagination) => ({
        //         ...prevPagination,
        //         pages: data.pages,
        //         totalDocs: data.totalDocs,
        //         limit: data.limit,
        //     }));
        // })
        // const getUser = async () => {
        //     try {
        //         const res = await userService.getUserList();
        //         console.log(res, "use list==========");
        //         if (res?.code === 200) {
        //             const formattedUserList = res.data.map((user) => ({
        //                 label: `${user.name} (${user.username})`,
        //                 value: user.id,
        //             }));
        //             setUserList(formattedUserList);
        //         }
        //     } catch (error) {
        //         console.log(error);
        //     }
        // };
        // getUser();
    }, []);
    // Define HTML for status badges
    const getStatusBadge = (status) => {
        if (status) {
            return (
                <Badge bg={"success"} className="badge badge-success">
                    Yes
                </Badge>
            );
        } else {
            return (
                <Badge bg={"pink"} className="badge badge-danger">
                    No
                </Badge>
            );
        }
    };

    

   

    

  
    // Map the columns to include HTML for status badges
    const columnsWithHTML = columns.map((column) => {
        if (column.toLowerCase() === "iswin") {
            return {
                header: "Win",
                accessor: column,
                Cell: ({ value }) => getStatusBadge(value), // Render status badge based on the value
            };
        } else if (column.toLowerCase() === "userid") {
            return {
                header: "User",
                accessor: column,
                Cell: ({ value }) => {
                    // console.log("value", value, column);
                    return (value?.username)?.toUpperCase() || "N/A";
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "createdat") {
            return {
                header: "Date",
                accessor: column,
                Cell: ({ value }) => {
                    // console.log("value", value, column);
                    return formatDateTime(value,"crashHistory") || "N/A";
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "gameid") {
            return {
                header: "Game",
                accessor: column,
                Cell: ({ value }) => {
                    // console.log("value-transaction", value, column);
                    return value.name || "N/A";
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "roundid") {
            return {
                header: "Round Id",
                accessor: column,
                Cell: ({ value }) => {
                    return value || "N/A";
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "currencyid") {
            return {
                header: "Currency",
                accessor: column,
                Cell: ({ value }) => {
                    // console.log("value", value, column);
                    return value?.currencyCodes || "N/A";
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "crashnumber") {
            return {
                header: "Crash Multiplier",
                accessor: column,
                Cell: ({ value }) => {
                    // console.log("value", value, column);
                    return value || "N/A";
                }, // Render action dropdown based on the value
            };
        } else if (column.toLowerCase() === "betrecords") {
            return {
                header: "Active User",
                accessor: column,
                Cell: ({value, row}) => {
                    // console.log("value", data);
                    return <a href={"/bet-history?round-id="+row.roundId}>{value.length || "0"}</a>;
                }, // Render action dropdown based on the value
            };
        }
        else if (column.toLowerCase() === "totalbetamount") {
            return {
                header: "Amount Bet",
                accessor: column,
                Cell: ({ value }) => {
                    return value || "0";
                } // Render action dropdown based on the value
            };
        }
        else if (column.toLowerCase() === "totalpayoutamount") {
            return {
                header: "Amount Won",
                accessor: column,
                Cell: ({ value }) => {
                    return value || "0";
                } // Render action dropdown based on the value
            };
        }
        else if (column.toLowerCase() === "net amount") {
            return {
                header: "Net Amount",
                accessor: column,
                Cell: ({ row }) => {
                    return (Number(row.totalBetAmount)-Number(row.totalPayoutAmount)).toFixed(2) || "0";
                } // Render action dropdown based on the value
            };
        }
        // else {
        //   return column;
        // }
    });
    console.log("columnsWithHTML", columnsWithHTML);

    
    const useDebounce = (func, delay) => {
        const debounceRef = useRef(null);

        return (...args) => {
            if (debounceRef.current) {
                clearTimeout(debounceRef.current);
            }
            debounceRef.current = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };
    const debouncedSearch = useCallback(
        _.debounce((value) => {
            setPagination((prevPagination) => ({
                ...prevPagination,
                searchBy: value,
            }));
        }, 300),
        []
    );
    const handleSearchChange = (event) => {
        console.log("searchBy==");
        const value = event.target.value.trim();
        // if (value.length > 3 || value.length === 0) {
        debouncedSearch(value);
        // }
    };

    // const debouncedHandleSearchChange = useDebounce(handleSearchChange, 300);
    const [showPicker, setShowPicker] = useState(false);

    const handleRangeChange = (ranges) => {
        setState([ranges.selection]);
        // setShowPicker(false); // Hide the picker after selecting the date range
    };
    const formatDate = (date) => {
        if (date === null) return;
        return format(date, "dd-MM-yyyy");
    };
    
   

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <Sidebar />
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <div className=" align-items-center">
                        <Card.Header>


                            <div className="d-flex justify-content-between align-items-center">

                                <PageBreadcrumb title="Game History" />
                                <div>
                                    <Button className="" onClick={() => setIsHidden(!isHidden)}>
                                        Filter
                                    </Button>
                                </div>
                            </div>
                            {/* <h1>Transactions</h1> */}



                            {/* </div> */}
                            <div className={` ${!isHidden ? 'd-none' : ''} `}>
                                {/* Search Input */}
                                <TableFilter
                                    handleRangeChange={handleRangeChange}
                                    handleSearchChange={handleSearchChange}
                                    setPagination={setPagination}
                                    setShowPicker={setShowPicker}
                                    state={state}
                                    tableType="crashHistory"
                                    clearRange={clearRange}
                                    userList={userList}
                                    showPicker={showPicker}
                                />
                            </div>
                        </Card.Header>
                        <TableWithPagination
                            tableType={"crashHistory"}
                            data={[...betHistoryList]}
                            itemsPerPage={pagination?.limit}
                            columns={columnsWithHTML}
                            currentPage={pagination?.page}
                            totalPages1={pagination?.totalPages}
                            totalData={pagination?.totalDocs}
                            pagination={pagination}
                            setPagination={setPagination}
                            moduleId={7}
                        

                        />
                    </div>
                </Card>
            </div>
        </React.Fragment>
    );
}
