// import { BehaviorSubject } from "rxjs";
// import axios from "axios";
// import { environment } from "../../envirnoment/envirnoment.prod";
// import { map, catchError, tap, retry } from "rxjs/operators";

import createAxiosInstance from "../api.instance";
import { useSelector } from "react-redux";

const RoleService = () => {
const user = useSelector((state) => state.user.user);
const bearerToken = localStorage.getItem("token"); // Retrieve the bearer token from localStorage
// console.log("call from role user" , user)
const axiosInstance = createAxiosInstance(bearerToken);
  const createRole = async (requestData) => {
    return await axiosInstance
      .post(`/admin/role`, requestData, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const getRole = async ({page, limit, searchBy}) => {
    return await axiosInstance
      .get(`/admin/role?page=${page}&limit=${limit}&searchBy=${searchBy}`,  { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response?.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const updateRole = async (id, requestData) => {
    return await axiosInstance
      .patch(`/admin/role/${id}`, requestData, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const updateRoleStatus = async (id, requestData) => {
    return await axiosInstance
      .put(`/admin/role/${id}`, requestData, { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  const deleteRole = async (id) => {
    return await axiosInstance
      .delete(`/admin/role/${id}`,  { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  const getRoleById = async (id) => {
    return await axiosInstance
      .get(`/admin/role/${id}`,  { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };

  const getRolelistDropdown = async (id) => {
    return await axiosInstance
      .get(`/admin/role/list/dropdown`,  { headers: {} })
      .then((response) => {
        console.log("Registration =============", response);
        return response.data; // Return response data
      })
      .catch((error) => {
        console.error("Registration failed:", error);
        //  return error.response.data; // Throw error to be caught by the caller
      });
  };
  return {
    createRole,
    getRole,
    updateRole,
    getRoleById,
    updateRoleStatus,
    deleteRole,
    getRolelistDropdown
  };
};

export default RoleService;
