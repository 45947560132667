import numeral from "numeral";
import React from "react";
import { formatCurrency } from "../../helper/CurrencyFormate";

const BetDetails = ({ userData }) => {
    return (
        <>
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="sub-service-title">
                        <h3><span>Game Details</span></h3>
                    </div>
                </div>
            </div>

            {/* Game Details */}
            <div className="row">
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Number of Bets:</span>
                        <span className="service-view">{userData?.betDetails?.numberOfBets || 0}</span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Average Bet Amount:</span>
                        <span className="service-view">
                            {formatCurrency(userData?.betDetails?.averageBetAmount || 0, "INR")}
                        </span>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="subservice-row">
                        <span className="service-title">Net GGR:</span>
                        <span className="service-view">
                            {formatCurrency(userData?.betDetails?.netGGR || 0, "INR")}
                        </span>
                    </div>
                </div>
            </div>

            {/* Favourite Game */}
            <div className="row mt-4">
                <span className="sub-heading-title">Favourite Game</span>
            </div>
            <div>
                <table className="wallet-table">
                    <thead>
                        <tr>
                            <th>Game Name</th>
                            <th>Games Played</th>
                            <th>Currency</th>
                            <th>Amount Won</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(userData?.betDetails?.favouriteGameByCurrency || {}).length > 0 ? (
                            Object.values(userData?.betDetails?.favouriteGameByCurrency).map((item, ind) => (
                                <tr key={ind}>
                                    <td>{item?.gameName}</td>
                                    <td>{item?.gamesPlayed}</td>
                                    <td>
                                        {item.currencyName}({item.currencySymbols})
                                    </td>
                                    <td>{formatCurrency(item.amountWon, item.currencyCodes)}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" className="text-center">
                                    No Records Found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {/* Most Played Game */}
            <div className="row mt-4">
                <span className="sub-heading-title">Most Played Game</span>
            </div>
            <div>
                <table className="wallet-table">
                    <thead>
                        <tr>
                            <th>Game Name</th>
                            <th>Games Played</th>
                            <th>Currency</th>
                            <th>Amount Won</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(userData?.betDetails?.mostPlayedGameByCurrency || {}).length > 0 ? (
                            Object.values(userData?.betDetails?.mostPlayedGameByCurrency).map((item, ind) => (
                                <tr key={ind}>
                                    <td>{item?.gameName}</td>
                                    <td>{item?.gamesPlayed}</td>
                                    <td>
                                        {item.currencyName}({item.currencySymbols})
                                    </td>
                                    <td>{formatCurrency(item.amountWon, item.currencyCodes)}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" className="text-center">
                                    No Records Found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default BetDetails;
